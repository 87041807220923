import { useState, useEffect, useMemo, Fragment } from "react";
import { useParams } from "react-router-dom";
import { MentionsInput, Mention } from "react-mentions";
import {
  ArrowFatLineLeft,
  ArrowFatLineRight,
  BookmarkSimple,
} from "phosphor-react";
import { useCreateAlgorithmBookmark } from "../../../hooks/sessions";
import * as mathjs from "mathjs";
import style from "src/components/common/mentions.module.css";
import { useGetAlgorithms } from "src/hooks/algorithms";
import { ExpressionFormat } from "src/utils/types";
import { ExprParser } from "src/utils/ExpressionParser";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";

const ExpressisonVisualizer = ({
  treeItemsByModel,
  individual,
  setParsedExpTree,
  parsingError,
  setParsingError,
  onChangeSelectedTree,
  incrementExpressionIndex,
  decrementExpressionIndex,
}) => {
  let params = useParams();
  const { notify } = useNotifier();
  const { session, configurations } = useMultiTreeSession();

  const [editedExpression, setEditedExpression] = useState({
    original: individual?.model,
    plain: individual?.model,
  });

  const { data: algorithmsData } = useGetAlgorithms();
  const { mutateAsync: createAlgortihmBookmark } = useCreateAlgorithmBookmark();

  const editorVariablesAndOperators = useMemo(() => {
    if (!session || !algorithmsData) {
      return null;
    }

    const sessionType = session.properties.commandType;
    const algoId = session.algorithm.id;
    const selectedAlgorithm = algorithmsData?.data.find(
      (item) => item.id === algoId
    );
    const algoProperties = selectedAlgorithm?.commands.find(
      (c) => c.commandType === sessionType
    )?.schema.properties;

    return {
      headers: session.headers.map((variable) => ({
        id: variable,
        display: variable,
      })),
      functions: algoProperties
        ? algoProperties.functions_set?.items.enum.map((item) => ({
            id: item,
            display: item,
          }))
        : [],
      terminals: algoProperties
        ? algoProperties.terminals_set?.items.enum.map((item) => ({
            id: item,
            display: item,
          }))
        : [],
    };
  }, [session, algorithmsData]);

  async function bookmark() {
    const customExpression = editedExpression.plain;
    const modelExpressionWithoutEdited = treeItemsByModel
      .filter((item) => item.treeIndex !== individual.treeIndex)
      .map((item) => item.model);

    try {
      await createAlgortihmBookmark({
        projectId: params.id,
        algorithmId: session.algorithm.id,
        exprs: [customExpression, ...modelExpressionWithoutEdited],
      });

      notify(NotificationType.SUCCESS, `${customExpression} bookmarked.`);
    } catch (error) {
      notify(
        NotificationType.ERROR,
        `${customExpression} could not bookmarked! Please try later.`
      );
    }
  }

  const updateExpressionTree = (exp) => {
    setParsingError(null);

    let tempExp = exp;
    const sessionConfID = session.configuration.id;
    const confDetails = configurations.find((c) => c.id === sessionConfID);

    if (
      session.algorithm.exprFormat === ExpressionFormat.PREFIX &&
      confDetails
    ) {
      const exprParser = new ExprParser();
      tempExp = exprParser.prefix2infix(exp);
      tempExp = exprParser.sanitizeGPGomeaModel(tempExp);
    }

    try {
      const parsedExpTree = mathjs.parse(tempExp);
      setParsedExpTree(parsedExpTree);
    } catch (error) {
      setParsingError(error);
    }
  };

  useEffect(() => {
    if (individual?.model) {
      const originalExpression = individual.model;
      setEditedExpression({
        original: originalExpression,
        plain: originalExpression,
      });
      updateExpressionTree(originalExpression);
    }
  }, [individual]);

  return (
    <>
      <div id="expression-container">
        <span id="bookmark" onClick={() => bookmark()}>
          <BookmarkSimple weight="fill" size={20} />
        </span>
        <section
          id="tree-expression-form"
          className={parsingError ? "with-error" : ""}
        >
          <MentionsInput
            classNames={style}
            value={editedExpression.original}
            placeholder="Mention variables using '$' and operatores using '@'"
            onChange={(e, newValue, newPlainTextValue, mentions) => {
              setEditedExpression({
                original: e.target.value,
                plain: newPlainTextValue,
              });
            }}
          >
            <Mention
              className={style.mentions__mention}
              trigger="$"
              data={editorVariablesAndOperators?.headers || []}
            />

            <Mention
              className={style.mentions__mention}
              trigger="@"
              data={[
                ...(editorVariablesAndOperators?.functions || []),
                ...(editorVariablesAndOperators?.terminals || []),
              ]}
            />
          </MentionsInput>
          <Box
            sx={{
              position: "absolute",
              left: "0",
              bottom: "0",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
              padding: ".5rem",
            }}
          >
            <Stack direction={"column"} gap={".5rem"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"1.5rem"}
              >
                <Typography id={parsingError ? "expression-error" : ""}>
                  {parsingError ? (
                    <Fragment>
                      <b>Parsing Error</b> <br /> {parsingError.message}
                    </Fragment>
                  ) : (
                    ""
                  )}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={() => updateExpressionTree(editedExpression.plain)}
                    sx={{
                      height: "37px",
                      marginLeft: "1rem",
                    }}
                  >
                    Update Graph
                  </Button>
              </Stack>
              
              <Stack direction={'row'} alignItems={'center'} gap={'1.5rem'}>
                <Stack>
                  <Typography variant="caption">
                    <b>Change Model:</b>
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"}>
                    <IconButton onClick={decrementExpressionIndex}>
                      <ArrowFatLineLeft />
                    </IconButton>
                    <IconButton onClick={incrementExpressionIndex}>
                      <ArrowFatLineRight />
                    </IconButton>
                  </Stack>
                </Stack>
                {
                  treeItemsByModel.length > 0 && (
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel id="tree-select">Change Tree</InputLabel>
                      <Select
                        id="tree-select"
                        label="Change Tree"
                        size="small"
                        value={individual.treeIndex}
                        renderValue={(selected) => treeItemsByModel[selected]?.model || '-'}
                        onChange={(e) => {
                          const selected = treeItemsByModel[e.target.value];
                          onChangeSelectedTree(selected);
                          updateExpressionTree(selected.model);
                        }}
                      >
                        {treeItemsByModel.map((item) => (
                          <MenuItem value={item.treeIndex}>
                            <Stack
                              width={"100%"}
                              direction={"row"}
                              justifyContent={"space-between"}
                              gap={".25rem"}
                            >
                              <Typography sx={{ flex: "1", whiteSpace: "wrap" }}>
                                {item.model}
                              </Typography>
                              <Stack>
                                <Typography variant="caption">
                                  <b>Fitness:</b> {item.fitness}
                                </Typography>
                                <Typography variant="caption">
                                  <b>Size:</b> {item.size}
                                </Typography>
                              </Stack>
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )
                }
              </Stack>
            </Stack>
            <Alert severity="info">
              <Typography variant="caption">
                1- This will be saved to algorithm bookmarks. Can be seen at
                bookmarkeds tab
              </Typography>
              <br />
              <Typography variant="caption">
                2- Type <b>$</b> for headers, <b>@</b> for functions and
                terminals. Give 1 character empty space before <b>$ or @</b> to
                see options,
              </Typography>
            </Alert>
          </Box>
        </section>
      </div>
    </>
  );
};

export default ExpressisonVisualizer;
