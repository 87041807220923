import { Fragment, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import {
  ArrowBendDownRight,
  Download,
  MagnifyingGlassPlus,
} from "phosphor-react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import Status from "src/components/common/Status";
import {
  StyledTableCell,
  StyledTableRow,
} from "src/components/common/TableItems";
import { useGetChildShapleyImg } from "src/hooks/feature-importance";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { getErrorMsg } from "src/utils/Utils";
import RunChildShapleyDialog from "./RunChildShapleyDialog";

const ChildShapleyDetails = ({
  session,
  parentExplanation,
  childExplanation,
  childExplanationFetched,
  childExplanationLoading,
  childExplanationErr,
  onChangeExplanationStatus,
}) => {
  const { notify } = useNotifier();
  const [childShapleyImages, setChildShapleyimages] = useState([]);
  const [lightboxOpen, setLightBoxOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [runChildShapleyDialogOpen, setRunChildShapleyDialogOpen] =
    useState(false);

  const { mutateAsync: getChildShapleyImg, isLoading: childShapleyImgLoading } =
    useGetChildShapleyImg();

  const handleGetChildShapleyImg = (imgId, dataIndex) => {
    getChildShapleyImg({ expId: childExplanation.id, imgId })
      .then((img) => {
        setChildShapleyimages((prev) => [
          ...prev,
          { dataIndex: dataIndex, url: img },
        ]);
      })
      .catch(async (err) => {
        notify(NotificationType.ERROR, await getErrorMsg(err, true));
      });
  };

  const handleOpenLightboxImg = (image) => {
    setSelectedImg(image)
    setLightBoxOpen(true)
  }

  return (
    <Stack>
      <Stack direction={"row"} alignItems={"center"} gap={"2rem"}>
        <Stack>
          <Typography
            variant="h6"
            sx={{ fontSize: "1.125rem", fontWeight: "600" }}
          >
            Instance Explanations
          </Typography>
          <Typography>Runned with specific dataset instances</Typography>
        </Stack>
        {childExplanationFetched && <Status status={childExplanation.state} />}
        <Button
          size="small"
          variant="contained"
          startIcon={<ArrowBendDownRight />}
          sx={{ width: "fit-content", paddingX: ".75rem" }}
          onClick={() => setRunChildShapleyDialogOpen(true)}
        >
          Create New
        </Button>
      </Stack>
      <Stack mt={"1rem"}>
        {childExplanationLoading ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={"1rem"}
            width={"100%"}
          >
            <CircularProgress size={22} />
            <Typography>Getting child explanations results...</Typography>
          </Stack>
        ) : childExplanationErr ? (
          <Alert severity="error">{getErrorMsg(childExplanationErr)}</Alert>
        ) : childExplanationFetched &&
          childExplanation.explanations.length > 0 ? (
          <Stack gap={".5rem"}>
            {childExplanation.explanations
              .sort((a, b) => a.instanceIdx - b.instanceIdx)
              .map((item) => {
                const image = childShapleyImages.find(
                  (image) => image.dataIndex === item.instanceIdx
                );
                return (
                  <Stack
                    sx={{
                      padding: "1rem",
                      border: "1px solid",
                      borderColor: "neutral.200",
                      borderRadius: ".25rem",
                      gap: ".5rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "1.075rem",
                        fontWeight: "600",
                      }}
                    >
                      Instance: #{item.instanceIdx}
                    </Typography>
                    <Stack direction={"row"} gap={"1.5rem"}>
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          maxWidth: "30rem",
                          maxHeight: "25rem",
                          borderRadius: ".25rem",
                          backgroundColor: "neutral.100",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          ":hover > button": {
                            display: image ? "block" : 'inline-flex',
                          },
                        }}
                      >
                        {image ? (
                          <Fragment>
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                              }}
                              src={image.url}
                            />
                            <IconButton
                              onClick={() => handleOpenLightboxImg(image)}
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                display: "none",
                              }}
                            >
                              <MagnifyingGlassPlus size={26} />
                            </IconButton>
                          </Fragment>
                        ) : (
                          <Button
                            onClick={() =>
                              handleGetChildShapleyImg(
                                item.imageId,
                                item.instanceIdx
                              )
                            }
                            size="small"
                            startIcon={<Download />}
                          >
                            Get Image
                          </Button>
                        )}
                      </Box>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell align="left" key={"index"}>
                                #
                              </StyledTableCell>
                              {session.headers.map((header) => (
                                <StyledTableCell align="left" key={header}>
                                  {header}
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell align="left" key={"index"}>
                                <b>Actual Data: </b>
                              </StyledTableCell>
                              {(item.instance || []).map((instance) => (
                                <StyledTableCell align="left" key={instance}>
                                  {Number(instance).toFixed(4)}
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell align="left" key={"shapValues"}>
                                <b>Shap Values: </b>
                              </StyledTableCell>
                              {(item.shapValues || []).map((val, index) => (
                                <StyledTableCell align="left" key={index}>
                                  {Number(val).toFixed(4)}
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </Stack>
                );
              })}
          </Stack>
        ) : (
          <Alert severity="info">No child explanations found.</Alert>
        )}
      </Stack>

      <Lightbox
        open={lightboxOpen && selectedImg}
        close={() => setLightBoxOpen(false)}  
        slides={[
          { src: selectedImg?.url, title: `Instance-${selectedImg?.dataIndex}` },
        ]}
        carousel={{
          finite: true,
        }}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null
        }}
        plugins={[Captions]}
      />

      <RunChildShapleyDialog
        open={runChildShapleyDialogOpen}
        parentExplanation={parentExplanation}
        existedExplanations={childExplanation?.explanations || []}
        session={session}
        onChangeExplanationStatus={onChangeExplanationStatus}
        onClose={() => setRunChildShapleyDialogOpen(false)}
      />
    </Stack>
  );
};

export default ChildShapleyDetails;
