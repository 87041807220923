import { Checkbox, TableSortLabel } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import { Fragment } from "react";
import { SortDirection } from "src/utils/types";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "../../common/TableItems";

const AllPopulationsTable = ({
  allPopulations,
  selecteds,
  sortField,
  sortDirection,
  removeFromSelecteds,
  addToSelecteds,
  onChangeSortField,
}) => {
  return (
    <TableVirtuoso
      style={{ width: "100%", height: "400px" }}
      data={allPopulations}
      components={VirtualizedTableComponents}
      fixedHeaderContent={() => (
        <StyledTableRow>
          <StyledTableCell
            padding="checkbox"
            key={"empty-checkbox-area"}
          ></StyledTableCell>
          <StyledTableCell align="left" key={"session"}>
            Session
          </StyledTableCell>
          <StyledTableCell align="left" key={"expression"}>
            Expression
          </StyledTableCell>
          <StyledTableCell
            align="left"
            key={"fitness"}
            sortDirection={sortField === "fitness" ? sortDirection : false}
          >
            <TableSortLabel
              active={sortField === "fitness"}
              direction={
                sortField === "fitness" ? sortDirection : SortDirection.ASC
              }
              onClick={() => onChangeSortField("fitness")}
            >
              Fitness
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell
            align="left"
            key={"size"}
            sortDirection={sortField === "size" ? sortDirection : false}
          >
            <TableSortLabel
              active={sortField === "size"}
              direction={
                sortField === "size" ? sortDirection : SortDirection.ASC
              }
              onClick={() => onChangeSortField("size")}
            >
              Size
            </TableSortLabel>
          </StyledTableCell>
        </StyledTableRow>
      )}
      itemContent={(index, row) => {
        const idSet = new Set(selecteds.map((item) => item.id));
        const isChecked = idSet.has(row.id);

        return (
          <Fragment key={row.id}>
            <StyledTableCell padding="checkbox" key={row.id}>
              <Checkbox
                color="primary"
                checked={isChecked}
                onChange={() =>
                  isChecked ? removeFromSelecteds(row.id) : addToSelecteds(row)
                }
              />
            </StyledTableCell>
            <StyledTableCell align="left" key={"session"}>
              <b> {row.session?.name || "-"} </b>
            </StyledTableCell>
            <StyledTableCell align="left" key={"expression"}>
              {row.model}
            </StyledTableCell>
            <StyledTableCell align="left" key={"fitness"}>
              {row.fitness || "-"}
            </StyledTableCell>
            <StyledTableCell align="left" key={"size"}>
              {row.size || "-"}
            </StyledTableCell>
          </Fragment>
        );
      }}
    />
  );
};

export default AllPopulationsTable;
