import { useQuery, useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import { GET_CONFIGS_QUERY_KEY } from "src/utils/types";

export const useGetConfigs = (projectId) =>
  useQuery([GET_CONFIGS_QUERY_KEY, projectId], () => {
    return ApiClient.get(`/api/projects/${projectId}/configurations`);
  });

export const useGetConfigData = ({ projectId, configId }) =>
  useQuery([GET_CONFIGS_QUERY_KEY, projectId, configId], async () => {
    const data = await ApiClient.get(
      `/api/projects/${projectId}/configurations/${configId}`,
    );
    return data;
  });

export const useCreateConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, name, description, properties, algorithm, commandType }) =>
      ApiClient.post(`/api/projects/${projectId}/configurations`, {
        name,
        description,
        algorithm,
        properties,
        type: commandType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_CONFIGS_QUERY_KEY]);
      },
    },
  );
};

export const useUpdateConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, configId, properties, expressions }) =>
      ApiClient.put(`/api/projects/${projectId}/configurations/${configId}`, {
        properties,
        expressions,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_CONFIGS_QUERY_KEY]);
      },
    },
  );
};

export const useDeleteConfig = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, configId }) =>
      ApiClient.delete(`/api/projects/${projectId}/configurations/${configId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_CONFIGS_QUERY_KEY]);
      },
    },
  );
};
