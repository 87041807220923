import { useEffect } from "react";
import { useAuth } from "../hooks/use-auth";
import ApiClient from "../axios";

const AxiosErrorHandler = ({ children }) => {
  const auth = useAuth();

  useEffect(() => {
    const responseInterceptor = ApiClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.data?.message === "jwt expired"
        ) {
          auth.signout();
        }
        return Promise.reject(error);
      },
    );

    return () => {
      ApiClient.interceptors.response.eject(responseInterceptor);
    };
  }, [auth.loading, auth.user, auth.initialCurrentUserLoad]);

  return children;
};

export default AxiosErrorHandler;
