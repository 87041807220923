import { useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  useTheme,
  Typography,
  Alert,
  Box,
  AlertTitle,
} from "@mui/material";
import { MentionsInput, Mention } from "react-mentions";
import style from "src/components/common/mentions.module.css";
import useNotifier from "src/hooks/use-notify";

export default function CreateTerminalDialog({
  open,
  algorithm,
  onCreate,
  onClose,
}) {
  const { notify } = useNotifier();
  const theme = useTheme();
  const [text, setText] = useState({
    original: "",
    plain: "",
  });

  const terminalAndFunctions = useMemo(() => {
    const algoProperties = algorithm?.schema.properties;

    return {
      functions: algoProperties?.functions_set
        ? algoProperties.functions_set.items.enum.map((item) => ({
            id: item,
            display: item,
          }))
        : [],
      terminals: algoProperties?.terminals_set
        ? algoProperties.terminals_set.items.enum.map((item) => ({
            id: item,
            display: item,
          }))
        : [],
    };
  }, [algorithm]);

  const handleChangeText = (e, newValue, newPlainTextValue, mentions) => {
    setText({
      original: e.target.value,
      plain: newPlainTextValue,
    });
  };

  const handleSubmit = () => {
    onCreate(text.plain);
    notify(
      "success",
      "Terminal added to terminal_sets. You can add to configuration.",
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        Create new Terminal
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {terminalAndFunctions.terminals.length === 0 ? (
            <Grid item xs={12}>
              <Alert severity="info">
                This algorithm does not use terminals.
              </Alert>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  minHeight: "300px",
                }}
              >
                <Alert severity="info">
                  <AlertTitle>
                    <i> How to use existing functions and terminals?</i>
                  </AlertTitle>
                  Mention funtions using <b>`$`</b> and terminals using{" "}
                  <b>`@`</b>
                </Alert>
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: ".25rem",
                    zIndex: "9999",
                    flex: "1",
                  }}
                >
                  <MentionsInput
                    className="mentions"
                    classNames={style}
                    value={text.original}
                    onChange={handleChangeText}
                    placeholder="Type your terminal here..."
                  >
                    <Mention
                      className={style.mentions__mention}
                      trigger="$"
                      data={terminalAndFunctions?.functions || []}
                    />

                    <Mention
                      className={style.mentions__mention}
                      trigger="@"
                      data={terminalAndFunctions?.terminals || []}
                    />
                  </MentionsInput>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingX: "1.25rem", paddingY: ".75rem" }}>
        <Button
          color={"error"}
          onClick={onClose}
          sx={{ marginRight: ".25rem" }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          disabled={!text || terminalAndFunctions.terminals.length === 0}
          onClick={handleSubmit}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
