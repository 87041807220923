import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  DialogMode,
  GET_ALGORITHMS_QUERY_KEY,
  GET_CUSTOM_FUNCTIONS_QUERY_KEY,
} from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";
import { useState } from "react";
import { UploadSimple } from "phosphor-react";
import Dropzone from "react-dropzone";

const CreateAlgorithmDialog = ({ open, onClose, mode, algorithm }) => {
  const { notify } = useNotifier();
  const queryClient = useQueryClient();
  const [configFile, setConfigFile] = useState(null);
  const [customFuncFile, setCustomFuncFile] = useState(null);

  const { mutate: createAlgorithmMutation, isLoading: uploadLoading } =
    useMutation({
      mutationFn: (formData) => ApiClient.post(`/api/algorithms`, formData),
      onSuccess: () => {
        queryClient.invalidateQueries(GET_ALGORITHMS_QUERY_KEY);
        notify(NotificationType.SUCCESS, "Algorithm uploaded!");
        onClose();
      },
      onError: (err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      },
    });

  const { mutate: updateAlgorithmMutation, isLoading: updateLoading } =
    useMutation({
      mutationFn: (formData) =>
        ApiClient.post(`/api/algorithms/${algorithm.id}`, formData),
      onSuccess: (data, variables) => {
        // only revalidate if custom functions updated
        if (variables.has("functions")) {
          queryClient.invalidateQueries([
            GET_CUSTOM_FUNCTIONS_QUERY_KEY,
            algorithm.id,
          ]);
        }
        queryClient.invalidateQueries(GET_ALGORITHMS_QUERY_KEY);
        notify(NotificationType.SUCCESS, "Algorithm updated!");
        onClose();
      },
      onError: (err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      },
    });

  const onDropConfigFile = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((item) => {
        item.errors.forEach((err) => {
          notify(NotificationType.ERROR, `${err.message}`);
        });
      });
      return;
    }

    setConfigFile(acceptedFiles[0]);
  };

  const onDropCustomFuncFile = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((item) => {
        item.errors.forEach((err) => {
          notify(NotificationType.ERROR, `${err.message}`);
        });
      });
      return;
    }

    setCustomFuncFile(acceptedFiles[0]);
  };

  const handleSave = () => {
    const formData = new FormData();
    if (configFile) {
      formData.append("algorithm", configFile);
    }
    if (customFuncFile) {
      formData.append("functions", customFuncFile);
    }

    if (mode === DialogMode.CREATE) {
      createAlgorithmMutation(formData);
    } else {
      updateAlgorithmMutation(formData);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        {`${mode === DialogMode.CREATE ? "Create new" : "Update"} Algorithm`}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            mt: "1rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: ".25rem",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontSize: ".875rem", fontWeight: "600" }}
            >
              Config File (*optional)
            </Typography>
            <Dropzone onDrop={onDropConfigFile} accept=".json">
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps({
                      className: configFile ? "dropzone with-file" : "dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    {configFile ? (
                      <p id="file-name">{configFile.path}</p>
                    ) : (
                      <p>
                        Drag and drop{" "}
                        <b>
                          config <i>json</i>
                        </b>{" "}
                        here, or click to select files
                      </p>
                    )}
                    <UploadSimple
                      style={{ marginTop: ".5rem" }}
                      size={18}
                      color="#00e088"
                      weight="bold"
                    />
                  </div>
                </section>
              )}
            </Dropzone>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: ".25rem",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontSize: ".875rem", fontWeight: "600" }}
            >
              Custom Functions File (*optional)
            </Typography>
            <Dropzone onDrop={onDropCustomFuncFile} accept=".py">
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps({
                      className: customFuncFile
                        ? "dropzone with-file"
                        : "dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    {customFuncFile ? (
                      <p id="file-name">{customFuncFile.path}</p>
                    ) : (
                      <p>
                        Drag and drop{" "}
                        <b>
                          custom function <i>py</i>
                        </b>{" "}
                        here, or click to select files
                      </p>
                    )}
                    <UploadSimple
                      style={{ marginTop: ".5rem" }}
                      size={18}
                      color="#00e088"
                      weight="bold"
                    />
                  </div>
                </section>
              )}
            </Dropzone>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ height: "34px" }}
          color="error"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          autoFocus
          variant="contained"
          size="small"
          sx={{ height: "34px" }}
          disabled={!configFile && !customFuncFile}
          startIcon={
            uploadLoading || updateLoading ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : undefined
          }
          onClick={
            uploadLoading || updateLoading ? undefined : () => handleSave()
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAlgorithmDialog;
