import { Box, Typography } from "@mui/material";

const ShapleyLogs = ({ content }) => {
  return (
    <Box
      sx={{
        background: "#F5F5F5",
        borderRadius: ".5rem",
        padding: ".5rem 1rem",
        height: "100%",
        overflowY: 'auto'
      }}
    >
      <Typography style={{ display: "block", whiteSpace: "pre-wrap" }}>
        {content || "No logs found..."}
      </Typography>
    </Box>
  );
};

export default ShapleyLogs;
