import { useMutation, useQuery } from "react-query";
import ApiClient from "src/axios";
import JSZip from "jszip";
import { SESSION_REQ_INTERVAL } from "src/utils/types";

export const SHAPLEY_EXPLANATIONS_KEY = "get-all-shapely-explanations";
export const SINGLE_SHAPLEY_EXPLANATION_KEY = "single-shapely-explanation";
export const SHAPLEY_EXPLANATION_LOGS_KEY = "shapely-explanation-logs";
export const SHAPLEY_EXPLANATION_RESULT_KEY =
  "single-shapely-explanation-result";
const SHAPLEY_LOGS_INTERVAL = 3000;

const getAllExplanations = async (sessionId) => {
  const { data } = await ApiClient.get(
    `/api/${sessionId}/explanations?type=shapley`
  );
  return data;
};
export const useGetShapelyExplanations = (sessionId) => {
  return useQuery({
    queryKey: [SHAPLEY_EXPLANATIONS_KEY, sessionId],
    queryFn: () => getAllExplanations(sessionId),
    enabled: Boolean(sessionId),
    retry: false,
    refetchInterval: SESSION_REQ_INTERVAL,
  });
};

const getShapleyExplanationById = async (expId) => {
  const { data } = await ApiClient.get(`/api/explanation/${expId}`);
  return data;
};
export const useGetShapelyExplanationById = (expId, options) => {
  return useQuery({
    queryKey: [SINGLE_SHAPLEY_EXPLANATION_KEY, expId],
    queryFn: () => getShapleyExplanationById(expId),
    refetchInterval: SESSION_REQ_INTERVAL,
    ...options,
  });
};

const getChildShapleyExplanation = async (expId) => {
  const { data } = await ApiClient.get(`/api/explanation/${expId}`);
  return data;
};
export const useGetChildShapelyExplanation = (expId, options) => {
  return useQuery({
    queryKey: [SINGLE_SHAPLEY_EXPLANATION_KEY, expId],
    queryFn: () => getChildShapleyExplanation(expId),
    refetchInterval: SESSION_REQ_INTERVAL,
    ...options,
  });
};

const getShapleyExplanationLogs = async (expId) => {
  const { data } = await ApiClient.get(
    `/api/explanations/${expId}/logs/data?type=shapley`
  );
  return data;
};
export const useGetShapelyExplanationLogs = (expId, options) => {
  return useQuery({
    queryKey: [SHAPLEY_EXPLANATION_LOGS_KEY, expId],
    queryFn: () => getShapleyExplanationLogs(expId),
    refetchInterval: SESSION_REQ_INTERVAL,
    ...options,
  });
};

const getShapleyResult = async (expId) => {
  const res = await ApiClient.get(`/api/explanations/${expId}/shapley`, {
    responseType: "blob",
  });
  const blob = res.data;

  const zip = await JSZip.loadAsync(blob);
  const files = zip.files;
  const imageFiles = [];

  for (const fileName in files) {
    if (files[fileName].name.endsWith(".png")) {
      const fileData = await files[fileName].async("blob");
      const imageUrl = URL.createObjectURL(fileData);
      imageFiles.push({ name: files[fileName].name, url: imageUrl });
    }
  }
  return imageFiles;
};
export const useGetShapelyResult = (expId, options) => {
  return useQuery({
    queryKey: [SHAPLEY_EXPLANATION_RESULT_KEY, expId],
    queryFn: () => getShapleyResult(expId),
    ...options,
  });
};

const getChildShapleyImg = async (expId, imgId) => {
  const res = await ApiClient.get(
    `/api/explanations/${expId}/files/${imgId}/data`,
    { responseType: "blob" }
  );
  const blob = res.data;
  const url = URL.createObjectURL(blob);
  return url;
};
export const useGetChildShapleyImg = (options) => {
  return useMutation({
    mutationFn: ({ expId, imgId }) => getChildShapleyImg(expId, imgId),
    ...options,
  });
};

const runShapley = async (body) => {
  const { data } = await ApiClient.post(`/api/shapley`, body);
  return data;
};
export const useRunShapley = (options) => {
  return useMutation({
    mutationFn: ({ body }) => runShapley(body),
    ...options,
  });
};

const runChildShapley = async (expId, body) => {
  const { data } = await ApiClient.post(
    `/api/explanations/${expId}/shapley/plot`,
    body
  );
  return data;
};
export const useRunChildShapley = (options) => {
  return useMutation({
    mutationFn: ({ expId, body }) => runChildShapley(expId, body),
    ...options,
  });
};
