import { useEffect, useRef } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import { PrismLanguage } from "../overview/utils";

const PrettyCode = ({ code, language }) => {
  const codeRef = useRef();
  const algorithmChangeId = `${code?.id}-${code.version}`;

  useEffect(() => {
    if (codeRef?.current) {
      Prism.highlightElement(codeRef.current);
    }
  }, [codeRef?.current, algorithmChangeId]);

  return (
    <pre
      aria-hidden="true"
      style={{ borderRadius: ".75rem", padding: "1rem", margin: 0 }}
    >
      <code
        ref={codeRef}
        className={`language-${language}`}
        id="highlighting-content"
        style={{
          fontSize: ".875rem",
        }}
      >
        {language === PrismLanguage.JS
          ? JSON.stringify(code, null, 2)
          : language === PrismLanguage.PYTHON
            ? code
            : ""}
      </code>
    </pre>
  );
};

export default PrettyCode;
