import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import { Fragment, useState } from "react";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "../TableItems";

const ChangeExpressionDialog = ({ allPopulations, onSelect, onClose }) => {
  const [selected, setSelected] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const selectInstance = (row, index) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
      setSelected(null);
      return;
    }

    setSelectedIndex(index);
    setSelected(row);
  };

  const handleSubmit = () => {
    const selectedPopulation = {
      ...selected,
      index: selectedIndex,
    };

    onSelect(selectedPopulation);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg" fullScreen>
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        Change Expression
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ width: "100%", padding: "1.5rem 0" }}>
          {allPopulations.length === 0 ? (
            <Alert severity="info">There is no expression.</Alert>
          ) : (
            <TableVirtuoso
              style={{ width: "100%", height: "400px" }}
              data={allPopulations}
              components={VirtualizedTableComponents}
              fixedHeaderContent={() => (
                <StyledTableRow>
                  <StyledTableCell
                    padding="checkbox"
                    key={"empty-checkbox-area"}
                  ></StyledTableCell>
                  <StyledTableCell align="left" key={"index"}>
                    #
                  </StyledTableCell>
                  <StyledTableCell align="left" key={"expression"}>
                    Selected Expression
                  </StyledTableCell>
                  <StyledTableCell align="left" key={"fitness"}>
                    Fitness
                  </StyledTableCell>
                  <StyledTableCell align="left" key={"size"}>
                    Size
                  </StyledTableCell>
                </StyledTableRow>
              )}
              itemContent={(index, row) => (
                <Fragment key={row.id}>
                  <StyledTableCell padding="checkbox" key={row.id}>
                    <Checkbox
                      color="primary"
                      checked={selectedIndex === index}
                      onChange={() => selectInstance(row, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left" key={"index"}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left" key={"expression"}>
                    {row.model}
                  </StyledTableCell>
                  <StyledTableCell align="left" key={"fitness"}>
                    {row.fitness}
                  </StyledTableCell>
                  <StyledTableCell align="left" key={"size"}>
                    {row.size}
                  </StyledTableCell>
                </Fragment>
              )}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color={"error"}
          onClick={onClose}
          sx={{ marginRight: ".25rem" }}
        >
          Close
        </Button>
        <Button disabled={!selected} variant="contained" onClick={handleSubmit}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeExpressionDialog;
