import React from "react";
import { useNavigate } from "react-router-dom";
import { CaretRight } from "phosphor-react";

function Breadcrumbs({ ancestors, current }) {
  let navigate = useNavigate();

  return (
    <div id="breadcrumbs">
      {ancestors.map(({ name, url }) => {
        return (
          <span key={name}>
            <span onClick={() => navigate(url)}>{name}</span>
            <CaretRight size={14} weight="bold" />
          </span>
        );
      })}
      <h4 id="current">{current}</h4>
    </div>
  );
}

export default Breadcrumbs;
