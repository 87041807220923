import React from "react";
import Select from "react-select";

function Dropdown({
  value,
  onChange,
  options,
  isDisabled = false,
  isSearchable = true,
  multiSelect = false,
  menuPlacement = "bottom",
}) {
  const dropdownStyle = {
    control: (provided, state) => ({
      ...provided,
      filter: "drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.25))",
      outline: "none",
      border: "none",
      borderRadius: "3px",
      margin: "8px 0 22px 0",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#c4c4c4",
      fontWeight: "400",
      fontSize: "14px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: "#555555",
      fontWeight: "400",
      fontSize: "14px",
      padding: "0 16px",
    }),
    input: (provided, state) => ({
      ...provided,
      height: "43px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#555555",
      fontWeight: "400",
      fontSize: "14px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: "400",
      fontSize: "14px",
    }),
  };

  return (
    <Select
      styles={dropdownStyle}
      className="dropdown-container"
      value={value}
      defaultValue={value}
      onChange={onChange}
      options={options}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      isMulti={multiSelect}
      menuPlacement={menuPlacement}
    />
  );
}

export default Dropdown;
