import { Fragment, useEffect, useMemo, useState } from "react";
import { Alert, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import useNotifier, { NotificationType } from "../../hooks/use-notify";
import ConfigurationForm from "./ConfigurationForm";
import { GET_CONFIGS_QUERY_KEY } from "src/utils/types";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ApiClient from "src/axios";
import { getErrorMsg } from "src/utils/Utils";
import {
  checkAndConvertPropertiesByFieldTypes,
  getPropertiesErrorList,
} from "./utils";

export default function ConfigDetails({ config, algorithm }) {
  const { notify } = useNotifier();
  const { id: projectId } = useParams();
  const queryClient = useQueryClient();

  const [configContent, setConfigContent] = useState();
  const [invalidKeys, setInvalidKeys] = useState([]);
  const [expressions, setExpressions] = useState([]);

  const { mutate: saveConfigMutation, isLoading } = useMutation({
    mutationFn: ({ configId, properties, expressions }) =>
      ApiClient.put(`/api/projects/${projectId}/configurations/${configId}`, {
        properties,
        expressions,
      }),
    onSuccess: (res) => {
      const newConfigContent = {
        ...res.data,
        algorithm: res.data.algorithm.id,
      };
      handleChangeConfContent(newConfigContent);
      queryClient.invalidateQueries([GET_CONFIGS_QUERY_KEY]);
      notify(NotificationType.SUCCESS, "Config saved");
    },
    onError: (err) => {
      notify(NotificationType.ERROR, getErrorMsg(err));
    },
  });

  const algorithmHasProperties = useMemo(() => {
    return Object.keys(algorithm?.schema.properties || {}).length > 0;
  }, [algorithm?.schema.properties]);

  useEffect(() => {
    const {
      id,
      name,
      description,
      algorithm,
      project,
      properties,
      expressions,
    } = config;

    const newConfigContent = {
      id,
      name,
      description,
      algorithm: algorithm.id,
      project,
      properties,
    };

    setExpressions(expressions || []);
    handleChangeConfContent(newConfigContent);
  }, [config]);

  const handleChangeConfContent = (configContent) => {
    setConfigContent(() => configContent);
    setInvalidKeys([]);
  };

  const handleSave = () => {
    const { id, properties } = configContent;
    const convertedProperties = checkAndConvertPropertiesByFieldTypes(
      properties,
      algorithm,
    );

    const propertyErrors = getPropertiesErrorList(
      convertedProperties,
      algorithm,
    );
    setInvalidKeys(propertyErrors);

    if (propertyErrors.length > 0) {
      notify(
        NotificationType.ERROR,
        "Invalid values exists. Check all fields!",
      );
      return;
    }

    const editedConf = {
      configId: id,
      properties: convertedProperties,
      expressions,
    };

    Object.entries(editedConf.properties).forEach(([key, value]) => {
      if (typeof value === "object" && value.length === 1) {
        if (value[0] === "none") {
          delete editedConf.properties[key];
        } else if (value[0] === "true") {
          editedConf.properties[key] = true;
        } else if (value[0] === "false") {
          editedConf.properties[key] = false;
        }
      }
    });

    saveConfigMutation(editedConf);
  };

  const handleChangeProperties = (changedProperties) => {
    setConfigContent({
      ...configContent,
      properties: changedProperties,
    });
  };

  return (
    <Fragment>
      {algorithmHasProperties ? (
        <>
          {invalidKeys.length > 0 && (
            <Grid container direction="column" spacing={1} mt={0}>
              {invalidKeys.map((item) => (
                <Grid item>
                  <Alert severity="error">{`${item.message}`}</Alert>
                </Grid>
              ))}
            </Grid>
          )}
          <div id="config-properties">
            <Grid
              style={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
                padding: "20px 0",
                position: "relative",
              }}
            >
              {isLoading || !Boolean(configContent) ? (
                <div className="tile-loader"></div>
              ) : (
                <ConfigurationForm
                  algorithm={algorithm}
                  configContent={configContent}
                  onChange={handleChangeProperties}
                />
              )}
            </Grid>
            <Grid
              container
              justifyContent="center"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                paddingTop: "4px",
                width: "100%",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{
                  borderRadius: "0px",
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </div>
        </>
      ) : (
        <Grid item xs={12} mt={2}>
          <Alert severity="info">Algorithm has no properties.</Alert>
        </Grid>
      )}
    </Fragment>
  );
}
