import { useState } from "react";
import { Alert, Button, Grid, Stack, Typography } from "@mui/material";
import { Play, PencilSimple, CaretDoubleLeft, Stop } from "phosphor-react";
import Status from "../../common/Status";
import useNotifier, { NotificationType } from "../../../hooks/use-notify";
import {
  SessionTypes,
  SESSION_REQ_INTERVAL,
  SessionStatus,
} from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import MosaicPanelEmptyMsg from "src/components/common/empty-message/MosaicPanelEmptyMsg";
import MosaicPanel from "src/components/common/MosaicPanel";
import TrainingLogs from "./TrainingLogs";
import { usePauseSession, useRunSession } from "src/hooks/sessions";
import GenerationsTable from "./GenerationsTable";
import MetricsEvolution from "./MetricsEvolution";

const Training = () => {
  const { notify } = useNotifier();
  const {
    sessionId,
    session,
    sessionStatus,
    setSessionStatus,
    editing,
    running,
    setRunning,
    setIntervalMs,
    isTestSession,
    isTrainSession,
    trainGenerationsData,
    testGenerationsData,
    logs,
  } = useMultiTreeSession();

//   console.log('isTestSession', isTestSession)
//   console.log('trainGenerationsData', trainGenerationsData)
//   console.log('testGenerationsData', testGenerationsData)

  const { mutateAsync: runSessionMutation } = useRunSession();
  const { mutateAsync: pauseSessionMutation } = usePauseSession();

  const [expanded, setExpanded] = useState(false);
  const [tilesLayout, setTilesLayout] = useState({
    currentNode: {
      direction: "column",
      first: Boolean(session?.properties?.commandType === SessionTypes.TEST)
        ? "test_results"
        : "generations",
      second: {
        direction: "row",
        first: "metrics",
        second: "logs",
      },
      splitPercentage: 50,
    },
  });

  const [expressionsDialogOpen, setExpressionsDialogOpen] = useState(false);
  const [updateSessionDialogOpen, setUpdateSessionDialogOpen] = useState(false);

  const runSession = () => {
    runSessionMutation({ sessionId: sessionId })
      .then(() => {
        setRunning(true);
        setSessionStatus("RUNNING");
        setIntervalMs(SESSION_REQ_INTERVAL);
      })
      .catch((error) => {
        notify(NotificationType.ERROR, getErrorMsg(error));
      });
  };

  const pauseSession = () => {
    pauseSessionMutation({ sessionId: sessionId })
      .then(() => {
        setRunning(false);
        setSessionStatus("KILLED");
        setIntervalMs(SESSION_REQ_INTERVAL);
      })
      .catch((error) => {
        notify(NotificationType.ERROR, getErrorMsg(error));
      });
  };

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  const isFailedOrFinished =
    sessionStatus === SessionStatus.FAILED ||
    sessionStatus === SessionStatus.FINISHED;

  const elementMap = {
    generations:
      !isTestSession && trainGenerationsData?.length > 0 ? (
        <GenerationsTable type="train" />
      ) : (
        isFailedOrFinished && (
          <MosaicPanelEmptyMsg
            title={"No generations found"}
            desc={"make sure your algorithm outputs generations.csv"}
          />
        )
      ),
    test_results: isTestSession && testGenerationsData?.length > 0 && (
        <GenerationsTable type="test" />
    ),
    metrics: trainGenerationsData?.length > 0 && (
        <MetricsEvolution type={isTestSession ? "test" : "train"} />
    ),
    logs: logs && <TrainingLogs logs={logs} />,
  };

  return (
    <div className="model-tab" id="model-training-section">
      <div id="training-left" className={expanded ? "minimized" : ""}>
        <div id="top-buttons">
          <button
            id="session-edit-button"
            className="icon-button"
            disabled={editing || running}
            type="button"
            onClick={() => setUpdateSessionDialogOpen(true)}
          >
            <PencilSimple size={18} weight="bold" />
            <span>Edit</span>
          </button>
          {running ? (
            <button
              id="session-stop-button"
              className="icon-button"
              type="button"
              onClick={() => pauseSession()}
            >
              <Stop size={18} color="#ffffff" weight="bold" />
              <span>Stop</span>
            </button>
          ) : (
            <button
              id="session-run-button"
              className="icon-button"
              disabled={editing || running}
              type="button"
              onClick={() => runSession()}
            >
              <Play size={18} color="#ffffff" weight="bold" />
              <span>Start</span>
            </button>
          )}
        </div>

        <form className="session-form">
          <div className="input-container">
            <Stack gap={2} py={2}>
              <Stack>
                <Typography variant="body2" fontWeight={700}>
                  Dataset
                </Typography>
                <Typography>{session?.dataset?.name || "-"}</Typography>
              </Stack>
              <Stack>
                <Typography variant="body2" fontWeight={700}>
                  Algorithm
                </Typography>
                <Typography>{session?.algorithm?.name || "-"}</Typography>
              </Stack>
              {isTrainSession && (
                <Stack>
                  <Typography variant="body2" fontWeight={700}>
                    Configuration
                  </Typography>
                  <Typography>{session?.configuration?.name || "-"}</Typography>
                </Stack>
              )}
              {isTestSession && (
                <Stack>
                  <Typography variant="body2" fontWeight={700}>
                    Expressions
                  </Typography>
                  <Alert sx={{ marginTop: ".25rem" }} severity="info">
                    <Typography>
                      <b>
                        <i>
                          {(session?.customData || []).length} expression
                          exists.
                        </i>
                      </b>
                    </Typography>
                    <Typography variant="body2">
                      In order to list expressions, click details button.
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ height: "35px", marginTop: ".5rem" }}
                      onClick={() => setExpressionsDialogOpen(true)}
                    >
                      Details
                    </Button>
                  </Alert>
                </Stack>
              )}
            </Stack>
          </div>
        </form>

        {sessionStatus && (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Status status={sessionStatus} />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={1} mt={2}>
          {(session?.errorMsgs || []).map((errMsg) => (
            <Grid item xs={12}>
              <Alert severity="error">{errMsg}</Alert>
            </Grid>
          ))}
        </Grid>
      </div>

      <div id="training-right">
        <MosaicPanel
          tilesLayout={tilesLayout}
          onChangeLayout={handleChangeMosaicLayout}
          mosaicId="training"
          elementMap={elementMap}
        />
        <div id="expand-button-container">
          <button
            id="expand-button"
            onClick={() => setExpanded(!expanded)}
            className={expanded ? "expanded" : ""}
          >
            <CaretDoubleLeft size={16} weight="bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Training;
