import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {
  CircularProgress,
  DialogContent,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { GET_CONFIGS_QUERY_KEY } from "src/utils/types";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import ApiClient from "src/axios";
import { getErrorMsg } from "src/utils/Utils";
import { useState } from "react";

export default function CloneConfigDialog({ conf, open, onClose }) {
  const theme = useTheme();
  const { notify } = useNotifier();
  const { id: projectId } = useParams();
  const queryClient = useQueryClient();

  const [name, setName] = useState(`clone_${conf.name}`);
  const [desc, setDesc] = useState(`clone_${conf.description}`);

  const { mutate: cloneConfigMutation, isLoading } = useMutation({
    mutationFn: () =>
      ApiClient.post(`/api/projects/${projectId}/configurations`, {
        name: name.trim(),
        description: desc.trim(),
        projectId,
        algorithm: conf.algorithm.id,
        properties: conf.properties,
        type: conf.commandType,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CONFIGS_QUERY_KEY]);
      notify(NotificationType.SUCCESS, "Config cloned");
      onClose();
    },
    onError: (err) => {
      notify(NotificationType.ERROR, getErrorMsg(err));
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        Clone Config
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <label htmlFor="config-name" className="form-item-label">
              Name
            </label>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="config-desc" className="form-item-label">
              Description
            </label>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button
          startIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                sx={{ color: theme.palette.primary.contrastText }}
              />
            ) : undefined
          }
          onClick={isLoading ? undefined : () => cloneConfigMutation()}
          variant="contained"
        >
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
}
