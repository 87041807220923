import {
  CheckCircle,
  WarningCircle,
  CircleDashed,
  Spinner,
  ArrowsInCardinal,
} from "phosphor-react";
import { SessionStatus } from "src/utils/types";

const Status = ({ status }) => {
  switch (status) {
    case SessionStatus.RUNNING:
    case SessionStatus.QUEUED:
      return (
        <div id="status" className="running">
          <div className="loader"></div>
          <span>Running</span>
        </div>
      );
    case SessionStatus.FINISHED:
      return (
        <div id="status" className="finished">
          <CheckCircle size={21} weight="regular" />
          <span>Finished</span>
        </div>
      );
    case SessionStatus.COMPLETED:
      return (
        <div id="status" className="finished">
          <CheckCircle size={21} weight="regular" />
          <span>Completed</span>
        </div>
      );
    case SessionStatus.FAILED:
    case SessionStatus.UNKNOWN:
      return (
        <div id="status" className="failed">
          <WarningCircle size={21} weight="regular" />
          <span>Failed</span>
        </div>
      );
    case SessionStatus.CREATED:
    case SessionStatus.CONFIGURED:
      return (
        <div id="status" className="created">
          <CircleDashed size={21} weight="regular" />
          <span>Created</span>
        </div>
      );
    case SessionStatus.PENDING:
      return (
        <div id="status" className="running">
          <Spinner size={21} weight="regular" />
          <span>Pending</span>
        </div>
      );
    case SessionStatus.KILLED:
      return (
        <div id="status" className="failed">
          <ArrowsInCardinal size={21} weight="regular" />
          <span>Killed</span>
        </div>
      );
    default:
      return (
        <div id="status" className="created">
          Unknown Status
        </div>
      );
  }
};

export default Status;
