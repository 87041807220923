import { Box, Grid, Typography } from "@mui/material";
import { forwardRef } from "react";

const CounterfactualLogs = forwardRef(function CounterfactualLogs(
  { logs, errorLogs },
  ref,
) {
  return (
    <Box
      sx={{
        background: "#F5F5F5",
        borderRadius: "6px",
        padding: "8px 16px",
      }}
    >
      <p
        ref={ref}
        style={{
          width: "100%",
          whiteSpace: "pre",
          height: "300px",
          overflowY: "auto",
        }}
      >
        {logs
          ? logs
          : errorLogs
            ? errorLogs.map((item) => <Typography>{item.message}</Typography>)
            : "No logs found."}
      </p>
    </Box>
  );
});

export default CounterfactualLogs;
