import { ConfigFieldItemTypes, ConfigFieldTypes } from "src/utils/types";

export const TERMINAL_SET_KEY = "terminals_set";

export const isSelectType = (items) => {
  return "enum" in (items || {});
};

export const getPropertiesErrorList = (items, algorithm) => {
  let hasError = {};
  let errorMsg = {};

  Object.entries(items).map(([key, val]) => {
    const schema = algorithm.schema.properties[key];

    switch (schema.type) {
      case ConfigFieldTypes.ARRAY:
        hasError[key] =
          (schema.minItems !== undefined && val.length < schema.minItems) ||
          (schema.maxItems !== undefined && val.length > schema.maxItems);
        errorMsg[key] =
          schema.minItems !== undefined && val.length < schema.minItems
            ? `at least ${schema.minItems} items required at ${key}`
            : schema.maxItems !== undefined && val.length > schema.maxItems
              ? `up to ${schema.maxItems} items can be added to ${key}`
              : "";
        break;
      case ConfigFieldTypes.INTEGER:
      case ConfigFieldTypes.NUMBER:
        hasError[key] =
          (schema.minimum !== undefined && val < schema.minimum) ||
          (schema.maximum !== undefined && val > schema.maximum);
        errorMsg[key] =
          schema.minimum !== undefined && val < schema.minimum
            ? `${key} can not be lower than ${schema.minimum}`
            : schema.maximum !== undefined && val > schema.maximum
              ? `${key} can not be higher than ${schema.maximum}`
              : "";
        break;
      case ConfigFieldTypes.STRING:
        hasError[key] =
          schema.enum && !schema.enum?.find((enumVal) => enumVal === val);
        errorMsg[key] = `${key} must be one of [${schema.enum?.join(", ")}]`;
      default:
        break;
    }
  });

  let errorList = [];
  Object.entries(hasError).forEach(([key, val]) => {
    if (val) {
      errorList.push({
        field: key,
        message: errorMsg[key],
      });
    }
  });

  return errorList;
};

export const checkAndConvertPropertiesByFieldTypes = (items, algorithm) => {
  let tempItems = {};
  Object.entries(items).map(([key, val]) => {
    if (
      algorithm.schema.properties[key].type === ConfigFieldTypes.ARRAY &&
      typeof val === ConfigFieldItemTypes.STRING
    ) {
      tempItems[key] = val
        .split(",")
        .filter(Boolean)
        .map((item) =>
          algorithm.schema.properties[key].items.type ===
            ConfigFieldItemTypes.NUMBER ||
          algorithm.schema.properties[key].items.type ===
            ConfigFieldItemTypes.INTEGER
            ? Number(item)
            : item,
        );
    } else {
      tempItems[key] = val;
    }
  });

  return tempItems;
};
