import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { DialogMode, SortDirection } from "src/utils/types";
import { StyledTableCell, StyledTableRow } from "../../common/TableItems";
import { Trash } from "phosphor-react";

const SelectedPopulationsTable = ({
  mode,
  selecteds,
  sortField,
  sortDirection,
  removeFromSelecteds,
  onChangeSortField,
}) => {
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left" key={"index"}>
              #
            </StyledTableCell>
            <StyledTableCell align="left" key={"session"}>
              Session
            </StyledTableCell>
            <StyledTableCell align="left" key={"expression"}>
              Expression
            </StyledTableCell>
            <StyledTableCell
              align="left"
              key={"fitness"}
              sortDirection={sortField === "fitness" ? sortDirection : false}
            >
              <TableSortLabel
                active={sortField === "fitness"}
                direction={
                  sortField === "fitness" ? sortDirection : SortDirection.ASC
                }
                onClick={() => onChangeSortField("fitness")}
              >
                Fitness
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              align="left"
              key={"size"}
              sortDirection={sortField === "size" ? sortDirection : false}
            >
              <TableSortLabel
                active={sortField === "size"}
                direction={
                  sortField === "size" ? sortDirection : SortDirection.ASC
                }
                onClick={() => onChangeSortField("size")}
              >
                Size
              </TableSortLabel>
            </StyledTableCell>
            {mode !== DialogMode.VIEW && (
              <StyledTableCell align="right" key={"action"}></StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {selecteds.map((item, index) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell align="left" key={"index"}>
                {index + 1}
              </StyledTableCell>
              <StyledTableCell align="left" key={"session"}>
                <b> {item?.session?.name || "-"}</b>
              </StyledTableCell>
              <StyledTableCell align="left" key={"expression"}>
                {item?.model || "-"}
              </StyledTableCell>
              <StyledTableCell align="left" key={"fitness"}>
                {item?.fitness || "-"}
              </StyledTableCell>
              <StyledTableCell align="left" key={"size"}>
                {item?.size || "-"}
              </StyledTableCell>
              {mode !== DialogMode.VIEW && (
                <StyledTableCell align="right" key={"action"}>
                  <IconButton onClick={() => removeFromSelecteds(item.id)}>
                    <Trash fontSize={16} color={theme.palette.error.main} />
                  </IconButton>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectedPopulationsTable;
