import { Box, Grid, TextField, Typography } from "@mui/material";

const SelectOutputRange = ({ outputRange, onSetOutputRange }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
          marginBottom: ".3rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Output Range
        </Typography>
        <Typography variant="caption">
          (suggested min and max value are %10 below and above the data)
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            type="number"
            value={outputRange.start}
            onChange={(e) =>
              onSetOutputRange({ ...outputRange, start: e.target.value })
            }
            placeholder="Starting point of output range"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ width: "100%" }}
            variant="outlined"
            type="number"
            value={outputRange.finish}
            onChange={(e) =>
              onSetOutputRange({ ...outputRange, finish: e.target.value })
            }
            placeholder="Finishing point of output range"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectOutputRange;
