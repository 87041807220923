import { useState } from "react";
import { UploadSimple } from "phosphor-react";
import Dropzone from "react-dropzone";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { GET_ALGORITHMS_QUERY_KEY } from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";

const UploadDatasetDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const { notify } = useNotifier();
  const queryClient = useQueryClient();
  const { id: projectId } = useParams();
  const [datasetFile, setDatasetFile] = useState(null);

  const { mutate: uploadDatasetMutation, isLoading: uploadLoading } =
    useMutation({
      mutationFn: (formData) =>
        ApiClient.post(`/api/projects/${projectId}/datasets`, formData),
      onSuccess: () => {
        queryClient.invalidateQueries(["datasets"]);
        notify(NotificationType.SUCCESS, "Dataset uploaded!");
        onClose();
      },
      onError: (err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      },
    });

  const onDropDatasetFile = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      fileRejections.forEach((item) => {
        item.errors.forEach((err) => {
          notify(NotificationType.ERROR, `${err.message}`);
        });
      });
      return;
    }

    setDatasetFile(acceptedFiles[0]);
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("dataset", datasetFile, datasetFile.name);
    formData.append("name", datasetFile.name);
    formData.append("delimiter", ",");

    uploadDatasetMutation(formData);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        {`Upload Dataset`}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            mt: "1rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: ".25rem",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontSize: ".875rem", fontWeight: "600" }}
            >
              Dataset File
            </Typography>
            <Dropzone onDrop={onDropDatasetFile}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps({
                      className: datasetFile
                        ? "dropzone with-file"
                        : "dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    {datasetFile ? (
                      <p id="file-name">{datasetFile.name}</p>
                    ) : (
                      <p>
                        Drag and drop <b>dataset</b> here, or click to select
                        files
                      </p>
                    )}
                    <UploadSimple
                      style={{ marginTop: ".5rem" }}
                      size={18}
                      color="#00e088"
                      weight="bold"
                    />
                  </div>
                </section>
              )}
            </Dropzone>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Close
        </Button>
        <Button
          autoFocus
          variant="contained"
          disabled={!datasetFile}
          startIcon={
            uploadLoading ? (
              <CircularProgress
                size={16}
                sx={{ color: theme.palette.primary.contrastText }}
              />
            ) : undefined
          }
          onClick={uploadLoading ? undefined : () => handleSave()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDatasetDialog;
