import { useMutation, useQuery, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import {
  GENERAL_ERROR_MESSAGE,
  GET_ALGORITHMS_QUERY_KEY,
} from "src/utils/types";

export const useGetAlgorithms = () =>
  useQuery([GET_ALGORITHMS_QUERY_KEY], () => {
    return ApiClient.get("/api/algorithms");
  });

export const useGetAlgorithm = (algorithmId) =>
  useQuery([GET_ALGORITHMS_QUERY_KEY, algorithmId], () => {
    return ApiClient.get(`/api/algorithms/${algorithmId}`);
  });

export const useDeleteAlgorithm = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ algorithmId, successCallback, errorCallback }) =>
      ApiClient.delete(`/api/algorithms/${algorithmId}`),
    {
      onSuccess: (data, params) => {
        queryClient.invalidateQueries([GET_ALGORITHMS_QUERY_KEY]);
        params.successCallback();
      },
      onError: (err, params) => {
        console.log(err);
        params.errorCallback(
          err?.response?.data?.message || GENERAL_ERROR_MESSAGE,
        );
      },
    },
  );
};
