import { useQuery, useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import {
  multiTreeExampleGenerationResp,
  multiTreeExamplePopulationResp,
  multiTreeExampleTestResp,
} from "src/utils/data";

export const useGetSessions = (projectId) =>
  useQuery(["sessions", projectId], () => {
    return ApiClient.get(`/api/projects/${projectId}/sessions`);
  });

export const useGetSession = (sessionId) =>
  useQuery(["session", sessionId], () => {
    return ApiClient.get(`/api/sessions/${sessionId}`);
  });

export const useGetResults = ({ sessionId, intervalMs = false }) =>
  useQuery(
    ["results", sessionId, intervalMs],
    async () => {
      let res;
      try {
        res = await ApiClient.get(`/api/sessions/${sessionId}`);
        res = {
          status: res.data.status,
          data: res.data.results,
        };
      } catch {
        res = { status: "", data: null };
      }
      return res;
    },
    {
      refetchInterval: intervalMs,
    }
  );

export const useGetLogs = ({ sessionId, intervalMs = false }) =>
  useQuery(
    ["logs", sessionId, intervalMs],
    async () => {
      let res;
      try {
        res = await ApiClient.get(`/api/sessions/${sessionId}/logs/data`);
      } catch (error) {
        console.log(error);
        res = "";
      }
      return res;
    },
    {
      refetchInterval: intervalMs,
    }
  );

export const useGetGenerations = ({
  sessionId,
  intervalMs = false,
  enabled = true,
}) =>
  useQuery(
    ["generations", sessionId, intervalMs],
    async () => {
      const res = await ApiClient.get(`/api/sessions/${sessionId}/results`, {
        params: { type: "generations" },
      });
      return res.data.map((generation) => generation.results);
    },
    {
      refetchInterval: intervalMs,
      enabled: enabled,
    }
  );

export const useGetTestResults = ({
  sessionId,
  intervalMs = false,
  enabled = false,
}) => {
  return useQuery(
    ["test_result", sessionId, intervalMs],
    async () => {
      const res = await ApiClient.get(`/api/sessions/${sessionId}/results`, {
        params: { type: "test_result" },
      });
      return res.data.map((testResults) => testResults.results);
    },
    {
      refetchInterval: intervalMs,
      enabled: enabled,
    }
  );
};

export const useGetPopulation = ({
  sessionId,
  intervalMs = false,
  enabled = false,
}) =>
  useQuery(
    ["populations", sessionId],
    async () => {
      let res;
      res = await ApiClient.get(`/api/sessions/${sessionId}/results`, {
        params: { type: "population" },
      });

      let individuals = [];
      let uniqueProperties = new Set();
      res.data.forEach((result) => {
        for (let individual of result.results) {
          if (!uniqueProperties.has(individual.model)) {
            uniqueProperties.add(individual.model);
            individuals.push({
              ...individual,
              generation: result.generation,
              sessionId: sessionId,
              resultId: result.id,
            });
          }
        }
      });
      return res;
    },
    {
      refetchInterval: intervalMs,
      enabled: enabled,
    }
  );

export const useGetPopulationsofSession = ({ sessionId, intervalMs = false }) =>
  useQuery(
    ["populations_of_session", sessionId],
    async () => {
      let res;

      res = await ApiClient.get(`/api/sessions/${sessionId}/results`, {
        params: { type: "population" },
      });

      let individuals = [];
      let uniqueProperties = new Set();
      res.data.forEach((result) => {
        for (let individual of result.results) {
          if (!uniqueProperties.has(individual.model)) {
            uniqueProperties.add(individual.model);
            individuals.push({
              ...individual,
              generation: result.generation,
              sessionId: sessionId,
              resultId: result.id,
            });
          }
        }
      });
      return individuals;
    },
    {
      refetchInterval: intervalMs,
      enabled: Boolean(sessionId),
    }
  );

export const useGetAllPopulations = (sessions) =>
  useQuery(
    ["all_populations", sessions],
    async () => {
      let populations = {};
      for (let session of sessions) {
        let res;
        try {
          res = await ApiClient.get(`/api/sessions/${session.id}/results`, {
            params: { type: "population" },
          });

          let individuals = [];
          let uniqueProperties = new Set();
          res.data.forEach((result) => {
            for (let individual of result.results) {
              if (!uniqueProperties.has(individual.model)) {
                uniqueProperties.add(individual.model);
                individuals.push({
                  ...individual,
                  generation: result.generation,
                  sessionId: session.id,
                  resultId: result.id,
                });
              }
            }
          });
          populations[session.id] = individuals;
        } catch (error) {
          console.log(error);
        }
      }

      return populations;
    },
    {
      enabled: !!sessions,
    }
  );

export const useGetMetrics = (sessionId) =>
  useQuery(["metrics", sessionId], () => {
    return ApiClient.get(`/api/sessions/${sessionId}/results`, {
      params: { type: "metrics" },
    }).then((data) => {
      return data.results;
    });
  });

export const useCreateSession = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, dataset, algorithm, configuration, commandType, projectId }) =>
      ApiClient.post(`/api/projects/${projectId}/sessions`, {
        name,
        dataset,
        algorithm,
        configuration,
        commandType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sessions");
      },
    }
  );
};

export const useRunSession = () => {
  return useMutation(({ sessionId }) =>
    ApiClient.post(`/api/sessions/${sessionId}/run`)
  );
};

export const usePauseSession = () => {
  return useMutation(({ sessionId }) =>
    ApiClient.post(`/api/sessions/${sessionId}/stop`)
  );
};

export const useGetBoomarkedSolutions = (sessionId) =>
  useQuery(["bookmarked", sessionId], async () => {
    const res = await ApiClient.get(`/api/sessions/${sessionId}/bookmarks`);
    if (res.data && res.data.length > 0) {
      res.data = {
        bookmarkCollectionId: res.data[0].id,
        bookmarkIds: res.data[0].results.map((sesionResult) => sesionResult.id),
      };
    }
    return res;
  });

export const useGetSessionBookmarks = (sessionId) =>
  useQuery(
    ["session_bookmarked_with_details", sessionId],
    async () => {
      const res = await ApiClient.get(`/api/sessions/${sessionId}/bookmarks`);
      let bookmarkList = [];

      if (res.data && res.data.length > 0) {
        res.data.forEach((collection) => {
          collection.results.forEach((item) => {
            bookmarkList.push({
              ...item,
              algorithm: collection.algorithm,
              session: collection.session,
              resultId: collection.id,
            });
          });
        });
      }

      res.data = bookmarkList;
      return res;
    },
    {
      enabled: Boolean(sessionId),
    }
  );

export const useGetAlgorithmBookmarks = (projectId, algorithmId) =>
  useQuery(
    ["algorithm_bookmarked_with_details", algorithmId],
    async () => {
      const res = await ApiClient.get(
        `/api/projects/${projectId}/algorithms/${algorithmId}/bookmarks`
      );
      let bookmarkList = [];

      if (res.data && res.data.length > 0) {
        res.data.forEach((collection) => {
          collection.results.forEach((item) => {
            bookmarkList.push({
              ...item,
              algorithm: collection.algorithm,
              session: collection.session,
              resultId: collection.id,
            });
          });
        });
      }

      res.data = bookmarkList;
      return res;
    },
    {
      enabled: Boolean(algorithmId) && Boolean(projectId),
    }
  );

export const useGetAllBookmarkedModels = (project) =>
  useQuery(
    ["bookmarked", project],
    async () => {
      let bookmarkedSolutions = [];
      try {
        const response = await ApiClient.get(
          `/api/projects/${project}/results`,
          {
            params: { type: "saved_result" },
          }
        );
        bookmarkedSolutions = response.data;
      } catch (error) {
        console.log(error);
      }
      return bookmarkedSolutions;
    },
    {
      enabled: !!project,
    }
  );

export const useBookmarkSolution = (successCallback) => {
  return useMutation(
    ({ id, resultId }) =>
      ApiClient.post(`/api/results/${resultId}/models/${id}/bookmark`),
    {
      onSuccess: (res) => {
        const collection = res.data;
        let bookmarkList = [];
        collection.results.forEach((item) => {
          bookmarkList.push({
            ...item,
            algorithm: collection.algorithm,
            session: collection.session,
            resultId: collection.id,
          });
        });
        successCallback(bookmarkList);
      },
    }
  );
};

export const useCreateAlgorithmBookmark = () => {
  return useMutation(({ projectId, algorithmId, exprs }) =>
    ApiClient.post(
      `/api/projects/${projectId}/algorithms/${algorithmId}/bookmarks`,
      { exprs }
    )
  );
};

export const useRemoveBookmarkSolution = (successCallback) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ resultId, modelId }) =>
      ApiClient.delete(`/api/bookmarks/${resultId}/models/${modelId}`),
    {
      onSuccess: (_, variables) => {
        const { algorithm, sessionId } = variables;
        if (successCallback) {
          successCallback(variables.modelId);
        }

        if (Boolean(sessionId)) {
          queryClient.invalidateQueries([
            "session_bookmarked_with_details",
            sessionId,
          ]);
        }
        queryClient.invalidateQueries([
          "algorithm_bookmarked_with_details",
          algorithm,
        ]);
      },
    }
  );
};

// not used (kaan)

export const useRemoveBookmarkByResultId = (successCallback) => {
  return useMutation(
    ({ sessionId, resultId, modelId }) =>
      ApiClient.delete(`/api/sessions/${sessionId}/results/${resultId}/delete`),
    {
      onSuccess: (_, variables) => {
        successCallback(variables.resultId);
      },
    }
  );
};

export const useUpdateSession = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      name,
      dataset,
      headers,
      configuration,
      sessionId,
      expressions,
      customData,
    }) =>
      ApiClient.put(`/api/sessions/${sessionId}`, {
        name,
        dataset,
        headers,
        configuration,
        expressions,
        customData,
      }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["session", variables.sessionId]);
        queryClient.invalidateQueries("sessions");
      },
    }
  );
};

export const useDeleteSession = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, sessionId }) =>
      ApiClient.delete(`/api/projects/${projectId}/sessions/${sessionId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sessions");
      },
    }
  );
};

// MULTI-TREE SPECIFIC

export const useGetMultiTreeGenerations = ({
  sessionId,
  intervalMs = false,
  enabled = true,
}) =>
  useQuery(
    ["generations", sessionId, intervalMs],
    async () => {
      // const res = await ApiClient.get(`/api/sessions/${sessionId}/multi-tree/results`, {
      //   params: { type: "generations" },
      // });
      const res = multiTreeExampleGenerationResp;
      return res.map((generation) => generation.results);
    },
    {
      refetchInterval: intervalMs,
      enabled: enabled,
    }
  );

export const useGetMultiTreeTestResults = ({
  sessionId,
  intervalMs = false,
  enabled = false,
}) => {
  return useQuery(
    ["test_result", sessionId, intervalMs],
    async () => {
      // const res = await ApiClient.get(`/api/sessions/${sessionId}/multi-tree/results`, {
      //   params: { type: "test_result" },
      // });
      const res = multiTreeExampleTestResp;
      return res.map((testResults) => testResults.results);
    },
    {
      refetchInterval: intervalMs,
      enabled: enabled,
    }
  );
};

export const useGetMultiTreePopulation = ({
  sessionId,
  intervalMs = false,
  enabled = false,
}) =>
  useQuery(
    ["populations", sessionId],
    async () => {
      // const  res = await ApiClient.get(`/api/sessions/${sessionId}/multi-tree/results`, {
      //   params: { type: "population" },
      // });
      const res = multiTreeExamplePopulationResp;

      let individuals = [];
      res.forEach((result) => {
        for (let individual of result.results) {
          individual.models.forEach((_, index) => {
            individuals.push({
              generation: result.generation,
              sessionId: sessionId,
              resultId: result.id,
              model: individual.models[index],
              size: individual.sizes[index],
              fitness: individual.objectives[index],
            });
          })
        }
      });
      console.log('first res', individuals)
      return {data: res};
    },
    {
      refetchInterval: intervalMs,
      enabled: enabled,
    }
  );
