export const multiTreeExamplePopulationResp = [
  {
    projectSession: "663298ccf8f5651f2dc113d4",
    algorithm: "66119291b1246458f6737433",
    dataset: "66328eedae4533ef3b8fd50e",
    configuration: "66053d594bc54301d8af802e",
    type: "population",
    results: [
      {
        id: "1",
        individual: 1,
        fitness: 10,
        size: 12,
        models: [
          "ind_1-mul(AveRooms, add(add(AveBedrms, AveBedrms), add(AveBedrms, AveBedrms)))",
          "ind_1-sub(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), mul(add(HouseAge, Population), mul(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), add(add(AveBedrms, AveBedrms), add(AveBedrms, Latitude)))))",
          "ind_1-sub(div(mul(Population, HouseAge), mul(AveOccup, Latitude)), mul(add(HouseAge, Population), sub(Population, MedInc)))",
          "ind_1-mul(mul(AveRooms, add(sub(AveBedrms, Population), AveRooms)), Longitude)",
        ],
        sizes: [2, 5, 13, 27],
        other: {
          metric1: 16,
          metric2: 22,
        },
        objectives: [12.0, 14.0, 16.0, 140.0],
        others: {
          metric1: [10],
          metric2: [20],
        }
      },
      {
        id: "2",
        individual: 2,
        fitness: 15,
        size: 20,
        models: [
          "ind_2-mul(AveRooms, add(add(AveBedrms, AveBedrms), add(AveBedrms, AveBedrms)))",
          "ind_2-sub(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), mul(add(HouseAge, Population), mul(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), add(add(AveBedrms, AveBedrms), add(AveBedrms, Latitude)))))",
          "ind_2-sub(div(mul(Population, HouseAge), mul(AveOccup, Latitude)), mul(add(HouseAge, Population), sub(Population, MedInc)))",
          "ind_2-mul(mul(AveRooms, add(sub(AveBedrms, Population), AveRooms)), Longitude)",
        ],
        objectives: [8.0, 27.0, 120.0, 150.0],
        sizes: [4, 8, 20, 21],
        other: {
          metric1: 10,
          metric2: 20,
        },
      },
      {
        id: "3",
        individual: 3,
        fitness: 25,
        size: 30,
        models: [
          "ind_3-mul(AveRooms, add(add(AveBedrms, AveBedrms), add(AveBedrms, AveBedrms)))",
          "ind_3-sub(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), mul(add(HouseAge, Population), mul(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), add(add(AveBedrms, AveBedrms), add(AveBedrms, Latitude)))))",
          "ind_3-sub(div(mul(Population, HouseAge), mul(AveOccup, Latitude)), mul(add(HouseAge, Population), sub(Population, MedInc)))",
          "ind_3-mul(mul(AveRooms, add(sub(AveBedrms, Population), AveRooms)), Longitude)",
        ],
        objectives: [8.0, 30.0, 80.0, 90.0],
        sizes: [4, 4, 4, 5],
        other: {
          metric1: 11,
          metric2: 25,
        },
      },
      {
        id: "4",
        individual: 4,
        fitness: 30,
        size: 20,
        models: [
          "ind_4-mul(AveRooms, add(add(AveBedrms, AveBedrms), add(AveBedrms, AveBedrms)))",
          "ind_4-sub(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), mul(add(HouseAge, Population), mul(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), add(add(AveBedrms, AveBedrms), add(AveBedrms, Latitude)))))",
          "ind_4-sub(div(mul(Population, HouseAge), mul(AveOccup, Latitude)), mul(add(HouseAge, Population), sub(Population, MedInc)))",
          "ind_4-mul(mul(AveRooms, add(sub(AveBedrms, Population), AveRooms)), Longitude)",
        ],
        objectives: [10.0, 10.0, 80.0, 140.0],
        sizes: [30, 40, 50, 10],
        other: {
          metric1: 16,
          metric2: 22,
        },
      },
    ],
    generation: 0,
    date: "2024-03-28T09:50:37.868Z",
    version: 0,
    id: "66053d6d4bc54301d8af80a5",
  },
];

export const multiTreeExampleTestResp = [
  {
    projectSession: "663298ccf8f5651f2dc113d4",
    algorithm: "66119291b1246458f6737433",
    dataset: "66328eedae4533ef3b8fd50e",
    type: "test_result",
    results: [
      {
        id: "66329909f8f5651f2dc11524",
        fitness: 30,
        size: 20,
        other: {
          metric1: 10,
          metric2: 20,
        },
        models: [
          "div(div(add(div(Latitude, Population), AveBedrms), HouseAge), AveBedrms)",
          "div(div(Latitude, HouseAge), add(sub(Latitude, add(div(Latitude, Population), AveBedrms)), AveBedrms))",
        ],
        sizes: [9, 7],
        objectives: [2.0175992602290456, 2.0194761614082113],
      },
      {
        id: "66329909f8f5651f2dc11525",
        fitness: 50,
        size: 10,
        other: {
          metric1: 30,
          metric2: 10,
        },
        models: [
          "div(div(Latitude, HouseAge), add(sub(Latitude, add(div(Latitude, Population), AveBedrms)), AveBedrms))",
          "div(div(Latitude, HouseAge), add(sub(Latitude, add(div(Latitude, Population), AveBedrms)), AveBedrms))",
        ],
        objectives: [2.0194761614082113, 2.1194761614082113],
        sizes: [13, 15],
      },
    ],
    offset: 4,
    date: "2024-05-01T19:33:29.126Z",
    version: 0,
    id: "66329909f8f5651f2dc1152b",
  },
];

export const multiTreeExampleGenerationResp = [
  {
    projectSession: "663298ccf8f5651f2dc113d4",
    algorithm: "66119291b1246458f6737433",
    dataset: "66328eedae4533ef3b8fd50e",
    configuration: "66053d594bc54301d8af802e",
    type: "generation",
    results: [
      {
        id: "1",
        generation: 1,
        fitness: 10,
        size: 12,
        models: [
          "ind_1-mul(AveRooms, add(add(AveBedrms, AveBedrms), add(AveBedrms, AveBedrms)))",
          "ind_1-sub(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), mul(add(HouseAge, Population), mul(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), add(add(AveBedrms, AveBedrms), add(AveBedrms, Latitude)))))",
          "ind_1-sub(div(mul(Population, HouseAge), mul(AveOccup, Latitude)), mul(add(HouseAge, Population), sub(Population, MedInc)))",
          "ind_1-mul(mul(AveRooms, add(sub(AveBedrms, Population), AveRooms)), Longitude)",
        ],
        objectives: [12.0, 14.0, 16.0, 140.0],
        sizes: [2, 5, 13, 27],
        other: {
          metric1: 10,
          metric2: 20,
        },
      },
      {
        id: "2",
        generation: 2,
        fitness: 12,
        size: 10,
        models: [
          "ind_2-mul(AveRooms, add(add(AveBedrms, AveBedrms), add(AveBedrms, AveBedrms)))",
          "ind_2-sub(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), mul(add(HouseAge, Population), mul(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), add(add(AveBedrms, AveBedrms), add(AveBedrms, Latitude)))))",
          "ind_2-sub(div(mul(Population, HouseAge), mul(AveOccup, Latitude)), mul(add(HouseAge, Population), sub(Population, MedInc)))",
          "ind_2-mul(mul(AveRooms, add(sub(AveBedrms, Population), AveRooms)), Longitude)",
        ],
        objectives: [8.0, 27.0, 120.0, 150.0],
        sizes: [4, 8, 20, 21],
        other: {
          metric1: 16,
          metric2: 22,
        },
      },
      {
        id: "3",
        generation: 3,
        fitness: 20,
        size: 22,
        models: [
          "ind_3-mul(AveRooms, add(add(AveBedrms, AveBedrms), add(AveBedrms, AveBedrms)))",
          "ind_3-sub(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), mul(add(HouseAge, Population), mul(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), add(add(AveBedrms, AveBedrms), add(AveBedrms, Latitude)))))",
          "ind_3-sub(div(mul(Population, HouseAge), mul(AveOccup, Latitude)), mul(add(HouseAge, Population), sub(Population, MedInc)))",
          "ind_3-mul(mul(AveRooms, add(sub(AveBedrms, Population), AveRooms)), Longitude)",
        ],
        objectives: [8.0, 30.0, 80.0, 90.0],
        sizes: [4, 4, 4, 5],
        other: {
          metric1: 24,
          metric2: 30,
        },
      },
      {
        id: "4",
        generation: 4,
        fitness: 25,
        size: 18,
        models: [
          "ind_4-mul(AveRooms, add(add(AveBedrms, AveBedrms), add(AveBedrms, AveBedrms)))",
          "ind_4-sub(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), mul(add(HouseAge, Population), mul(sub(sub(AveRooms, HouseAge), add(Latitude, MedInc)), add(add(AveBedrms, AveBedrms), add(AveBedrms, Latitude)))))",
          "ind_4-sub(div(mul(Population, HouseAge), mul(AveOccup, Latitude)), mul(add(HouseAge, Population), sub(Population, MedInc)))",
          "ind_4-mul(mul(AveRooms, add(sub(AveBedrms, Population), AveRooms)), Longitude)",
        ],
        objectives: [10.0, 10.0, 80.0, 140.0],
        sizes: [30, 40, 50, 10],
        other: {
          metric1: 10,
          metric2: 20,
        },
      },
    ],
    date: "2024-03-28T09:50:37.868Z",
    version: 0,
    id: "66053d6d4bc54301d8af80a5",
  },
];
