export class ExprParser {
  fnSet = ["+", "-", "*", "/", "%"];

  prefix2infix(expr) {
    function reformat(stack) {
      if (stack.length == 1) {
        return stack[0];
      } else if (stack.length == 3) {
        const operator = stack[0];
        const leftArg = stack[1];
        const rightArg = stack[2];
        if (["+", "-", "*", "/", "%"].includes(operator)) {
          return `( ${leftArg} ${operator} ${rightArg} )`; // ( 2 + 3 )
        } else {
          return `${operator}( ${leftArg}, ${rightArg} )`; // add( 2, 3 )
        }
      } else {
        const fn = stack[0];
        const arg = stack[1];

        return `${fn}( ${arg} )`;
      }
    }

    function parseList(elements, depth = 0) {
      depth += 1;
      const localStack = [];
      while (true) {
        // console.log("===========")
        // console.log("depth: ", depth)
        // console.log("cursor: ", cursor)
        // console.log("localStack: ", localStack)

        if (cursor >= totalCount) break;

        if (elements[cursor] == "(") {
          cursor += 1;
          const localExpr = reformat(parseList(elements, depth));
          localStack.push(localExpr);
        } else if (elements[cursor] == ")") {
          cursor += 1;
          return localStack;
        } else {
          localStack.push(elements[cursor]);
          cursor += 1;
        }
      }

      return localStack;
    }

    let globalElements = this.listify(expr);
    let totalCount = globalElements.length;
    let cursor = 0;

    // console.log("total count: ", totalCount)
    // console.log("elements: ", globalElements)

    const result = parseList(globalElements);
    return result.length == 1 ? result[0] : reformat(result);
  }

  replaceNamesWithIndices(expr, features = []) {
    const allFeaturesRegex = new RegExp(features.join("|"), "g");
    const replacer = (feature) => `x${features.indexOf(feature)}`;
    return expr.replace(allFeaturesRegex, replacer);
  }

  // todo: gomea specific, remove if possible
  sanitizeGPGomeaModel(model) {
    const gomeaMapping = new Map([
      ["aq0.1", "aq0_1"],
      ["alog0.1", "alog0_1"],
      ["p/", "p_divide"],
    ]);

    const functionsRegex = new RegExp("alog0.1|p/|aq0.1", "g");
    const replacer = (fn) => gomeaMapping.get(fn) || fn;
    return model.replace(functionsRegex, replacer);
  }

  /////////////////////

  listify(expr) {
    const trimmed = expr.trim();
    const outerParanRemoved =
      trimmed.startsWith("(") && trimmed.endsWith(")")
        ? trimmed.slice(1, trimmed.length - 1)
        : trimmed;

    // isolate paranthesis
    return outerParanRemoved
      .replace(/\)/g, " ) ")
      .replace(/\(/g, " ( ")
      .split(" ")
      .filter((elem) => elem.length !== 0);
  }
}
