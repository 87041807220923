import React, { useState, useRef, useMemo } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import Dropdown from "../../Dropdown";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";

const colors = [
  "#0101ff",
  "#2ee09a",
  "#ff684d",
  "#E8E288",
  "#7DCE82",
  "#3CDBD3",
  "#3CDBD3",
  "#E3D8F1",
  "#726DA8",
  "#A0D2DB",
];

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  zoomPlugin
);

function ComparisonScatter2() {
  const { selectedSession, filteredSolutions } = useMultiTreeSession();
  const chartRef = useRef(null);

  const [scatterplotY, setscatterplotY] = useState({
    value: "modelFitness",
    label: "fitness (model)",
  });

  const [scatterplotX, setscatterplotX] = useState({
    value: "modelSize",
    label: "size (model)",
  });

  const otherMetrics = useMemo(() => {
    if ((filteredSolutions || []).length === 0) {
      return [];
    }

    let metrics = new Set();
    filteredSolutions.forEach((item) => {
      if (item.modelOther) {
        Object.keys(item.modelOther).forEach((key) => {
          metrics.add(key);
        });
      }
    });

    return Array.from(metrics);
  }, [filteredSolutions]);

  const convertedData = useMemo(() => {
    let models = [];
    let modelIds = new Set();
    let trees = new Map()
    let treeIndexes = new Set()

    filteredSolutions.forEach(item => {
        if(!modelIds.has(item.id)){
            modelIds.add(item.id);
            models.push({
                id: item.id,
                modelFitness: item.modelFitness,
                modelSize: item.modelSize,
                ...item.modelOther
            })

        }
        if(!treeIndexes.has(item.treeIndex)){
            treeIndexes.add((item.treeIndex))
            trees.set(item.treeIndex, [item])
        }else{
            trees.set(item.treeIndex, [...trees.get(item.treeIndex), item])
        }
    })

    return {
        models,
        trees
    }
  }, [filteredSolutions])

  console.log('convertedData', convertedData)

  const axisData = useMemo(() => {
    let values = [];
    (filteredSolutions || []).forEach((item) => {
      values.push({
        x: item[scatterplotX.value]
          ? item[scatterplotX.value]
          : item.modelOther
            ? item.modelOther[scatterplotX.value] || 0
            : 0,
        y: item[scatterplotY.value]
          ? item[scatterplotY.value]
          : item.modelOther
            ? item.modelOther[scatterplotY.value] || 0
            : 0,
        identifier: `G${item.generation}:${item.individual}`,
      });
    });
    return values;
  }, [scatterplotX.value, scatterplotY.value]);

  if (!selectedSession || !filteredSolutions || filteredSolutions?.length === 0)
    return <div className="tile-no-solutions">No solutions found</div>;

  const labels = [
    {
      label: "size",
      value: "size",
    },
    {
      label: "fitness",
      value: "fitness",
    },
    {
      label: "size (model)",
      value: "modelSize",
    },
    {
      label: "fitness (model)",
      value: "modelFitness",
    },
    ...(otherMetrics.length > 0 &&
      otherMetrics.map((item) => ({ label: `${item} (model)`, value: item }))),
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.raw.identifier}: (${context.raw.x}, ${context.raw.y})`;
          },
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
        pan: {
          enabled: true,
          mode: "xy",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: scatterplotY.label,
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: scatterplotX.label,
        },
      },
    },
  };

  const data = {
    datasets: [
      {
        label: selectedSession.label,
        data: axisData.map((item, index) => {
          return {
            x: item.x ?? 0,
            y: item.y ?? 0,
            id: index,
            identifier: item.identifier,
          };
        }),
        backgroundColor: colors[0],
      },
    ],
  };

  return (
    <div id="scatter-container">
      <Scatter options={options} data={data} ref={chartRef} />
      <span id="scatter-axis-picker">
        {scatterplotY && (
          <Dropdown
            value={{ value: scatterplotY.value, label: scatterplotY.label }}
            onChange={setscatterplotY}
            options={labels.map((item) => {
              return { value: item.value, label: item.label };
            })}
            isSearchable={false}
          />
        )}
        {scatterplotX && (
          <Dropdown
            value={{ value: scatterplotX.value, label: scatterplotX.label }}
            onChange={setscatterplotX}
            options={labels.map((item) => {
              return { value: item.value, label: item.label };
            })}
            isSearchable={false}
          />
        )}
      </span>
    </div>
  );
}

export default ComparisonScatter2;
