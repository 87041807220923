import { CopyToClipboard } from "react-copy-to-clipboard";
import { TableVirtuoso } from "react-virtuoso";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "../../common/TableItems";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { Fragment, useMemo } from "react";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";

function GenerationsTable({ type }) {
  const { notify } = useNotifier();
  const { trainGenerationsData, testGenerationsData } = useMultiTreeSession();
  const solutions =
    type === "train" ? trainGenerationsData : testGenerationsData;

  const finalIterationData = useMemo(() => {
    if (!solutions || solutions.length === 0) {
      return [];
    }

    const finalSolution = solutions[solutions.length - 1];
    let otherFields = new Set();
    let convertedArr = [];
    let newItem = {}; // take each model, fitness and size element then create a object for filter and sort operations
    if (finalSolution.length > 0) {
      const modelCount = finalSolution[0].models.length;
      finalSolution.forEach((generation) => {
        new Array(modelCount).fill("").map((_, index) => {
          newItem = {
            id: generation.id,
            generation: generation.generation,
            expr: generation.models[index],
            fitness: generation.objectives[index],
            size: generation.sizes[index],
            treeIndex: index,
            model: {
              fitness: generation.fitness,
              size: generation.size,
              other: { ...generation.other },
            },
          };
          convertedArr.push(newItem);
          Object.keys(generation.other || {}).forEach((key) => {
            otherFields.add(key);
          });
        });
      });
    }

    return { convertedArr, otherFields: Array.from(otherFields) };
  }, [solutions]);

  return (
    <TableVirtuoso
      style={{ height: "100%" }}
      data={finalIterationData.convertedArr}
      components={{
        ...VirtualizedTableComponents,
        TableRow: (props) => <StyledTableRow {...props} />,
      }}
      fixedHeaderContent={() => (
        <StyledTableRow>
          {type === "train" && (
            <StyledTableCell align="left">MODEL</StyledTableCell>
          )}
          <StyledTableCell align="left">EXPRESSION</StyledTableCell>
          <StyledTableCell align="left">SIZE</StyledTableCell>
          <StyledTableCell align="left">FITNESS</StyledTableCell>
          <StyledTableCell align="left">SIZE(model)</StyledTableCell>
          <StyledTableCell align="left">FITNESS(model)</StyledTableCell>
          {finalIterationData.otherFields.map((key) => (
            <StyledTableCell align="left">{key}(model)</StyledTableCell>
          ))}
        </StyledTableRow>
      )}
      itemContent={(index, item) => {
        const rowBackgroundColor = item.id % 2 === 0 ? "#f1f5f9" : "";
        return (
          <>
            {type === "train" && (
              <StyledTableCell
                sx={{ backgroundColor: rowBackgroundColor }}
                align="left"
              >
                {item.generation}.model {item.treeIndex + 1}.tree
              </StyledTableCell>
            )}
            <CopyToClipboard
              text={item.expr}
              onCopy={() =>
                notify(NotificationType.SUCCESS, "Expression copied!")
              }
            >
              <StyledTableCell
                align="left"
                title={item.expr}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "400px",
                  cursor: "copy",
                  backgroundColor: rowBackgroundColor,
                }}
              >
                {item.expr}
              </StyledTableCell>
            </CopyToClipboard>
            <StyledTableCell
              sx={{ backgroundColor: rowBackgroundColor }}
              align="left"
            >
              {item.size}
            </StyledTableCell>
            <StyledTableCell
              sx={{ backgroundColor: rowBackgroundColor }}
              align="left"
              title={parseFloat(item.fitness)}
            >
              {parseFloat(item.fitness).toFixed(2)}
            </StyledTableCell>
            <StyledTableCell
              sx={{ backgroundColor: rowBackgroundColor }}
              align="left"
            >
              {item.model?.size}
            </StyledTableCell>
            <StyledTableCell
              sx={{ backgroundColor: rowBackgroundColor }}
              align="left"
              title={parseFloat(item.model?.fitness)}
            >
              {parseFloat(item.model?.fitness).toFixed(2)}
            </StyledTableCell>
            {finalIterationData.otherFields.map((key) => {
              const value = item.model?.other[key];
              return (
                <StyledTableCell
                  sx={{ backgroundColor: rowBackgroundColor }}
                  align="left"
                >
                  {value && typeof value === "number"
                    ? parseFloat(item.model.other[key]).toFixed(2)
                    : "-"}
                </StyledTableCell>
              );
            })}
          </>
        );
      }}
    />
  );
}

export default GenerationsTable;
