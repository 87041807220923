import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import ApiClient from "src/axios";
import { getBlobTypeErrMsg, getErrorMsg } from "src/utils/Utils";
import PrettyCode from "../common/PrettyCode";
import { DownloadSimple, Trash } from "phosphor-react";
import { useState } from "react";
import { GET_CUSTOM_FUNCTIONS_QUERY_KEY } from "src/utils/types";
import DeleteCustomFuncDialog from "./DeleteCustomFuncDialog";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { PrismLanguage } from "./utils";

const CustomFuncShowcase = ({ algorithm }) => {
  const theme = useTheme();
  const { notify } = useNotifier();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const {
    data: customFuncData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [GET_CUSTOM_FUNCTIONS_QUERY_KEY, algorithm?.id],
    queryFn: () => ApiClient.get(`/api/algorithms/${algorithm?.id}/functions`),
    retry: false,
    refetchInterval: false,
  });

  const { mutate: downloadMutation, isLoading: downloadLoading } = useMutation({
    mutationFn: () =>
      ApiClient.get(`/api/algorithms/${algorithm?.id}/download`, {
        responseType: "blob",
        params: {
          type: "functions",
        },
      }),
    onSuccess: (res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "text/x-python" }),
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = `${algorithm.name}-custom-functions.py`;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    onError: async (err) => {
      notify(NotificationType.ERROR, await getBlobTypeErrMsg(err));
    },
  });

  return (
    <Box sx={{ width: "100%" }}>
      {isLoading && (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && isError && (
        <Box>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {getErrorMsg(error)}
          </Alert>
        </Box>
      )}
      {!isLoading && !isError && customFuncData && (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: ".5rem",
              marginBottom: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="success"
              size="small"
              sx={{ height: "34px" }}
              startIcon={
                downloadLoading ? (
                  <CircularProgress
                    size={16}
                    sx={{ color: theme.palette.success.contrastText }}
                  />
                ) : (
                  <DownloadSimple
                    style={{ color: theme.palette.success.contrastText }}
                  />
                )
              }
              onClick={downloadLoading ? undefined : downloadMutation}
            >
              Download
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ height: "34px" }}
              startIcon={
                <Trash style={{ color: theme.palette.error.contrastText }} />
              }
              onClick={() => setDeleteDialogOpen(true)}
            >
              Delete
            </Button>
          </Box>
          <PrettyCode
            code={customFuncData.data}
            language={PrismLanguage.PYTHON}
          />
        </>
      )}
      {deleteDialogOpen && (
        <DeleteCustomFuncDialog
          open={true}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}
    </Box>
  );
};

export default CustomFuncShowcase;
