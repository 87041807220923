import { Fragment, useEffect, useState } from "react";
import { HelpOutline } from "@mui/icons-material";
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
} from "@mui/material";
import { ConfigFieldTypes } from "src/utils/types";
import { TERMINAL_SET_KEY, isSelectType } from "./utils";
import { Plus } from "phosphor-react";
import CreateTerminalDialog from "./CreateTerminalDialog";

const ConfigurationForm = ({ configContent, algorithm, onChange }) => {
  const { properties } = configContent;
  const [values, setValues] = useState({});
  const [createTerminalDialogOpen, setCreateTerminalDialogOpen] =
    useState(false);

  // it is for handling custom created terminals
  // if dont saved it dissappears when checked unchecked events
  const initialValues = { ...properties };

  useEffect(() => {
    let tempVals = {};

    Object.entries(algorithm.schema.properties).map(([key, val]) => {
      if (val.type === ConfigFieldTypes.ARRAY) {
        if (isSelectType(val.items)) {
          tempVals[key] = properties[key] ? properties[key] : val.default;
        } else {
          tempVals[key] = properties[key]
            ? properties[key].join(",")
            : val.default.join(",");
        }
      } else {
        tempVals[key] = key in properties ? properties[key] : val.default;
      }
    });
    setValues(tempVals);
  }, [configContent?.name]);

  const handleChange = (key, newValue) => {
    const newValues = {
      ...values,
      [key]: newValue,
    };

    setValues(newValues);
    onChange(newValues);
  };

  const onCreateCustomTerminal = (newTerminal) => {
    const existingTerminals = values[TERMINAL_SET_KEY];
    handleChange(TERMINAL_SET_KEY, [...existingTerminals, newTerminal]);
  };

  return (
    <Fragment>
      <Grid container direction="column" spacing={2} pb={5}>
        {Object.entries(algorithm.schema.properties).map(([key, item]) => {
          return (
            <Grid item key={key}>
              <label htmlFor={key} className="form-item-label">
                {key}
              </label>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                  {(item.type === ConfigFieldTypes.INTEGER ||
                    item.type === ConfigFieldTypes.NUMBER) && (
                    <TextField
                      id={key}
                      fullWidth
                      variant="outlined"
                      type="number"
                      value={values[key]}
                      onChange={(e) =>
                        handleChange(key, Number(e.target.value))
                      }
                      error={
                        (item.minimum !== undefined &&
                          values[key] < item.minimum) ||
                        (item.maximum !== undefined &&
                          values[key] > item.maximum)
                      }
                      helperText={`${
                        item.minimum !== undefined && values[key] < item.minimum
                          ? `can not be lower than ${item.minimum}`
                          : item.maximum !== undefined &&
                              values[key] > item.maximum
                            ? `can not be higher than ${item.maximum}`
                            : ""
                      }`}
                    />
                  )}
                  {item.type === ConfigFieldTypes.STRING && (
                    <TextField
                      id={key}
                      fullWidth
                      variant="outlined"
                      value={values[key]}
                      onChange={(e) => handleChange(key, e.target.value)}
                      error={item.enum && !item.enum.includes(values[key])}
                      helperText={`${
                        (item.enum &&
                          !item.enum.find(
                            (enumVal) => enumVal === values[key],
                          ) &&
                          `must be one of [${item.enum.join(", ")}]`) ||
                        ""
                      }`}
                    />
                  )}
                  {item.type === ConfigFieldTypes.BOOLEAN && (
                    <FormControlLabel
                      control={
                        <Switch
                          id={key}
                          checked={Boolean(values[key])}
                          onChange={(e) =>
                            handleChange(key, Boolean(e.target.checked))
                          }
                        />
                      }
                      label={String(values[key])}
                    />
                  )}
                  {item.type === ConfigFieldTypes.ARRAY ? (
                    isSelectType(item.items) ? (
                      <Select
                        id={key.trim()}
                        fullWidth
                        sx={{
                          whiteSpace: "pre-wrap",
                          "& > div": { whiteSpace: "pre-wrap !important" },
                        }}
                        multiple
                        value={values[key] ? values[key] : []}
                        onChange={(e) => handleChange(key, e.target.value)}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {Array.from(
                          new Set([
                            ...item.items.enum,
                            ...(initialValues[key] || []),
                          ]),
                        ).map((item) => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              checked={values[key]?.find((k) => k === item)}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <TextField
                        id={key}
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={values[key]}
                        onChange={(e) => handleChange(key, e.target.value)}
                        error={
                          (item.minItems !== undefined &&
                            values[key]?.split(",").filter(Boolean).length <
                              item.minItems) ||
                          (item.maxItems !== undefined &&
                            values[key]?.split(",").filter(Boolean).length >
                              item.maxItems)
                        }
                        helperText={`${
                          item.minItems !== undefined &&
                          values[key]?.split(",").filter(Boolean).length <
                            item.minItems
                            ? `at least ${item.minItems} items required`
                            : item.maxItems !== undefined &&
                                values[key]?.split(",").filter(Boolean).length >
                                  item.maxItems
                              ? `up to ${item.maxItems} items can be added`
                              : ""
                        }`}
                      />
                    )
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={2} justifyContent="flex-start">
                  {(item.description || item.enum) && (
                    <Tooltip
                      title={`${item.description || ""} ${
                        item.enum ? " --> enums: " + item.enum.join(", ") : ""
                      }`}
                      placement="right"
                    >
                      <HelpOutline fontSize="small" />
                    </Tooltip>
                  )}
                  {key === TERMINAL_SET_KEY && (
                    <Button
                      size="small"
                      startIcon={<Plus />}
                      onClick={() => setCreateTerminalDialogOpen(true)}
                    >
                      NEW
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {createTerminalDialogOpen && (
        <CreateTerminalDialog
          open={true}
          algorithm={algorithm}
          onCreate={onCreateCustomTerminal}
          onClose={() => setCreateTerminalDialogOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default ConfigurationForm;
