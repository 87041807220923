import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  Tab,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  DialogMode,
  GET_ALGORITHM_BASED_POPULATIONS_QUERY_KEY,
  SortDirection,
} from "src/utils/types";
import { genericSort, getErrorMsg } from "src/utils/Utils";
import ApiClient from "src/axios";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AlgorithmBasedExpressionTabs } from "../utils";
import AllPopulationsTable from "./AllPopulationsTable";
import SelectedPopulationsTable from "./SelectedPopulationsTable";
import { useGetAlgorithmBookmarks } from "src/hooks/sessions";
import { useParams } from "react-router-dom";

const AlgorithmBasedExpressionsDialog = ({
  open,
  mode,
  selectableSessions = [],
  selectedPopulations = [],
  defaultTab = AlgorithmBasedExpressionTabs.ALL_POPULATIONS,
  algorithm,
  onSave,
  onClose,
}) => {
  const params = useParams();
  const [selecteds, setSelecteds] = useState(selectedPopulations);
  const [allPopulations, setAllPopulations] = useState([]);
  const [tab, setTab] = useState(defaultTab);
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);
  const [sortField, setSortField] = useState("fitness");

  const { data: algorithmBookmarks, isLoading: algorithmBookmarksLoading } =
    useGetAlgorithmBookmarks(params.id, algorithm);

  const { isFetching, isError, error } = useQuery({
    queryKey: [GET_ALGORITHM_BASED_POPULATIONS_QUERY_KEY],
    queryFn: () =>
      ApiClient.post(`/api/sessions/populations`, {
        sessionIds: selectableSessions.map((item) => item.id),
      }),
    onSettled: (populationsData) => {
      let items = [];
      let itemIdSet = new Set();

      (populationsData?.data || []).forEach((item) => {
        item.results.forEach((c) => {
          if (!itemIdSet.has(c.id)) {
            items.push({
              ...c,
              session: { ...item.projectSession },
            });
          }
        });
      });

      const bookmarkedWithoutSession = (algorithmBookmarks?.data || []).filter(
        (item) => Boolean(!item.session),
      );
      const combinedWithAlgorithmBookmarks = [
        ...items,
        ...bookmarkedWithoutSession,
      ];
      const sortedItems = genericSort(
        combinedWithAlgorithmBookmarks,
        "fitness",
        SortDirection.DESC,
      );
      setAllPopulations(sortedItems);
    },
    enabled: Boolean(algorithmBookmarks),
  });

  useEffect(() => {
    const existingPopulationList = [...allPopulations];
    const sortedList = genericSort(
      existingPopulationList,
      sortField,
      sortDirection,
    );

    const existingSelectedList = [...selecteds];
    const sortedListForSelected = genericSort(
      existingSelectedList,
      sortField,
      sortDirection,
    );

    setSelecteds(sortedListForSelected);
    setAllPopulations(sortedList);
  }, [sortDirection, sortField]);

  const addToSelecteds = (item) => {
    if (selecteds.find((selected) => selected.id === item.id)) {
      return;
    }
    setSelecteds([...selecteds, item]);
  };

  const removeFromSelecteds = (id) => {
    setSelecteds(selecteds.filter((selected) => selected.id !== id));
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeSortField = (field) => {
    const previousField = sortField;

    if (previousField === field) {
      setSortDirection(
        sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC,
      );
    } else {
      setSortField(field);
    }
  };

  const handleSubmit = () => {
    onSave(selecteds);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        Select And List Expressions
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ width: "100%", padding: "1.5rem 0" }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                {mode !== DialogMode.VIEW && (
                  <Tab
                    label="All"
                    value={AlgorithmBasedExpressionTabs.ALL_POPULATIONS}
                  />
                )}
                <Tab
                  label="Selecteds"
                  value={AlgorithmBasedExpressionTabs.ONLY_SELECTEDS}
                />
              </TabList>
            </Box>
            <TabPanel
              value={AlgorithmBasedExpressionTabs.ALL_POPULATIONS}
              sx={{ padding: "1rem 0" }}
            >
              {isFetching ? (
                <Stack gap={".5rem"} mt={"2rem"}>
                  <Skeleton
                    variant="rectangular"
                    width={120}
                    animation="wave"
                  />
                  {new Array(6).fill().map(() => (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      animation="wave"
                    />
                  ))}
                </Stack>
              ) : isError ? (
                <Alert severity="error">{getErrorMsg(error)}</Alert>
              ) : allPopulations.length === 0 ? (
                <Alert severity="info">There is no expression.</Alert>
              ) : (
                <AllPopulationsTable
                  allPopulations={allPopulations}
                  selecteds={selecteds}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  removeFromSelecteds={removeFromSelecteds}
                  addToSelecteds={addToSelecteds}
                  onChangeSortField={handleChangeSortField}
                />
              )}
            </TabPanel>
            <TabPanel
              value={AlgorithmBasedExpressionTabs.ONLY_SELECTEDS}
              sx={{ padding: "1rem 0" }}
            >
              {selecteds.length === 0 ? (
                <Alert severity="info">There is no selected expression.</Alert>
              ) : (
                <SelectedPopulationsTable
                  mode={DialogMode.VIEW}
                  selecteds={selecteds}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  removeFromSelecteds={removeFromSelecteds}
                  onChangeSortField={handleChangeSortField}
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color={"error"}
          onClick={onClose}
          sx={{ marginRight: ".25rem" }}
        >
          Close
        </Button>
        <Button
          disabled={selecteds.length === 0 || mode === DialogMode.VIEW}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlgorithmBasedExpressionsDialog;
