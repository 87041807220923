import { Box } from "@mui/material";
import { toast } from "sonner";
import { getErrorMsgWithStatusCode } from "src/utils/Utils";

export const NotificationType = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  DEFAULT: "default",
  CUSTOM: "custom",
  LOADING: "loading",
};

const useNotifier = () => {
  const notify = (type = "default", content, toastProps) => {
    const options = {
      ...(Boolean(toastProps) && toastProps),
    };

    switch (type) {
      case "success": {
        return toast.success(content, options);
      }
      case "info": {
        return toast.info(content, options);
      }
      case "warning": {
        return toast.warning(content, options);
      }
      case "error": {
        if (typeof content === "string") {
          return toast.error(content, options);
        }
        return toast.error(getErrorMsgWithStatusCode(content), options);
      }
      case "custom": {
        return toast(
          <Box sx={{ margin: "-1rem", width: "100%" }}>{content}</Box>,
        );
      }
      case "loading": {
        return toast.loading(content);
      }
      case "default": {
        return toast(content, options);
      }
    }
  };

  const closeNotification = (toastId) => {
    toast.dismiss(toastId);
  };

  return { notify, closeNotification };
};

export default useNotifier;
