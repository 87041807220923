import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Checkbox, Grid, Typography, Skeleton } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "../components/common/TableItems";
import Dropdown from "../components/Dropdown";
import WhatifAnalyseDialog from "../components/whatif/WhatifAnalyseDialog";
import Alert from "../components/Alert";
import { useGetPopulationsofSession, useGetSessions } from "../hooks/sessions";
import { useGetDatasetData } from "../hooks/datasets";
import { SessionTypes } from "src/utils/types";

const Whatif = () => {
  let params = useParams();
  const [allPopulations, setAllPopulations] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedExpression, setSelectedExpression] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState([]);
  const [selectedInstanceIndex, setSelectedInstanceIndex] = useState(null);
  const [whatifDialogOpen, setWhatifDialogOpen] = useState(false);

  const { data: sessions, isLoading: sessionLoading } = useGetSessions(
    params.id
  );
  const { data: allPopulationsIndividuals, isFetching: populationsFetching } =
    useGetPopulationsofSession({ sessionId: selectedSession?.value });

  const { data: datasetData, isFetching: dataFetching } = useGetDatasetData({
    projectId: params.id,
    datasetId: selectedSession?.dataset?.id,
    delimiter: null,
    fileName: selectedSession?.dataset?.name,
  });

  const targetLabel = selectedSession
    ? sessions.data.find((item) => item.id === selectedSession.value).target
    : null;

  useEffect(() => {
    if (!allPopulationsIndividuals) return;
    let solutionsList = [];
    for (const population in allPopulationsIndividuals) {
      solutionsList = solutionsList.concat(
        allPopulationsIndividuals[population]
      );
    }
    setAllPopulations(solutionsList);
  }, [allPopulationsIndividuals]);

  const handleSelectExpression = (populationItem) => {
    if (selectedExpression?.id === populationItem.id) {
      setSelectedExpression(null);
      return;
    }

    setSelectedExpression({
      id: populationItem.id,
      model: populationItem.model,
    });
  };

  const handleSelectInstance = (rowData, index) => {
    if (selectedInstanceIndex === index) {
      setSelectedInstanceIndex(null);
      return;
    }

    setSelectedInstanceIndex(index);
    setSelectedInstance(rowData);
  };

  return (
    <div className="project-tab" id="whatif-section">
      {sessionLoading ? (
        <div className="table-loader" />
      ) : (
        <>
          {!sessionLoading && sessions?.data.length === 0 && (
            <Grid>
              <Alert
                type="info"
                message="No session found. Please create a session first."
              />
            </Grid>
          )}
          {!sessionLoading && sessions?.data.length > 0 && !selectedSession && (
            <Grid>
              <Alert type="info" message="Select a session to see details." />
            </Grid>
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="form-item-label">
                Select session
              </Typography>
              <Dropdown
                value={selectedSession}
                onChange={setSelectedSession}
                isDisabled={sessionLoading}
                options={(sessions
                  ? sessions.data.length > 0
                    ? sessions.data
                    : []
                  : []
                )
                  .filter(
                    (item) => item.properties.commandType === SessionTypes.TRAIN
                  )
                  .map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                      dataset: item.dataset,
                    };
                  })}
              />
              {selectedInstanceIndex !== null && selectedExpression && (
                <button
                  type="button"
                  className="submit-button"
                  onClick={() => setWhatifDialogOpen(true)}
                  style={{ width: "100%", marginBottom: "24px" }}
                >
                  Submit
                </button>
              )}
            </Grid>
          </Grid>
          {selectedSession && (
            <>
              <Box
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
              >
                <Typography className="form-item-label">
                  Select an instance
                </Typography>
                {dataFetching ? (
                  <>
                    {new Array(5).fill().map(() => (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={30}
                        style={{ marginBottom: ".25rem" }}
                      />
                    ))}
                  </>
                ) : datasetData ? (
                  <TableVirtuoso
                    style={{ height: "400px" }}
                    data={datasetData.data.rows}
                    components={VirtualizedTableComponents}
                    fixedHeaderContent={() => (
                      <StyledTableRow>
                        <StyledTableCell
                          padding="checkbox"
                          key={"empty-checkbox-area"}
                        ></StyledTableCell>
                        {datasetData.data.header.map((item, index) => (
                          <StyledTableCell align="left" key={index}>
                            {item}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    )}
                    itemContent={(index, row) => (
                      <>
                        <StyledTableCell
                          padding="checkbox"
                          key={index + "checkbox"}
                        >
                          <Checkbox
                            color="primary"
                            checked={selectedInstanceIndex === index}
                            onChange={() => handleSelectInstance(row, index)}
                          />
                        </StyledTableCell>
                        {row.map((item, dataIndex) => (
                          <StyledTableCell align="left" key={dataIndex}>
                            {item}
                          </StyledTableCell>
                        ))}
                      </>
                    )}
                  />
                ) : (
                  <Grid>
                    <Alert type="info" message="Could not find dataset." />
                  </Grid>
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  mt: "2rem",
                }}
              >
                <Typography className="form-item-label">
                  Select expression
                </Typography>
                {populationsFetching ? (
                  <>
                    {new Array(5).fill().map(() => (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={30}
                        style={{ marginBottom: ".25rem" }}
                      />
                    ))}
                  </>
                ) : allPopulationsIndividuals.length === 0 ? (
                  <Grid>
                    <Alert
                      type="info"
                      message="No expression found. Please create or run a session."
                    />
                  </Grid>
                ) : (
                  <TableVirtuoso
                    style={{ height: "400px" }}
                    data={allPopulations}
                    components={VirtualizedTableComponents}
                    fixedHeaderContent={() => (
                      <StyledTableRow>
                        <StyledTableCell align="left">#</StyledTableCell>
                        <StyledTableCell align="left">
                          Expression
                        </StyledTableCell>
                        <StyledTableCell align="left">Fitness</StyledTableCell>
                      </StyledTableRow>
                    )}
                    itemContent={(index, solution) => (
                      <>
                        <StyledTableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={
                              selectedExpression
                                ? solution.id === selectedExpression.id
                                  ? true
                                  : false
                                : false
                            }
                            onChange={() => handleSelectExpression(solution)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {solution.model}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {solution.fitness}
                        </StyledTableCell>
                      </>
                    )}
                  />
                )}
              </Box>
            </>
          )}
        </>
      )}

      {whatifDialogOpen && selectedSession && (
        <WhatifAnalyseDialog
          open={whatifDialogOpen}
          sessionId={selectedSession?.value}
          instance={selectedInstance}
          dataHeaders={datasetData.data.header}
          expression={selectedExpression.model}
          expressions={new Set(allPopulations.map((e) => e.model))}
          targetLabel={targetLabel}
          allPopulations={allPopulations}
          onClose={() => setWhatifDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default Whatif;
