import { useState, useEffect } from "react";
import { CaretDoubleLeft } from "phosphor-react";
import ComparisonFilters from "./ComparisonFilters";
import ComparisonTable from "./ComparisonTable";
import ComparisonScatter from "./ComparisonScatter";
import ComparisonHistogram from "./ComparisonHistogram";
import MosaicPanel from "../../common/MosaicPanel";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import ComparisonScatter2 from "./Scatter2";

function Comparison() {
  const { filteredSolutions, solutions } = useMultiTreeSession();

  const [expanded, setExpanded] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const [tilesLayout, setTilesLayout] = useState({
    currentNode: {
      direction: "column",
      first: "table",
      second: {
        direction: "row",
        first: "scatter",
        second: "bars",
      },
      splitPercentage: 50,
    },
  });

  useEffect(() => {
    if (filteredSolutions?.length > 0) setTableDataLoading(false);
  }, [filteredSolutions]);

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  const ELEMENT_MAP = {
    table: tableDataLoading ? (
      <div className="tile-loader-container">
        <div className="tile-loader"></div>
      </div>
    ) : (
      <ComparisonTable />
    ),
    scatter: tableDataLoading ? (
      <div className="tile-loader-container">
        <div className="tile-loader"></div>
      </div>
    ) : (
      <ComparisonScatter />
      // <ComparisonScatter2 />
    ),
    bars:
      tableDataLoading && !filteredSolutions ? (
        <div className="tile-loader-container">
          <div className="tile-loader"></div>
        </div>
      ) : (
        <ComparisonHistogram />
      ),
  };

  return (
    <div className="project-tab" id="comparison-section">
      {solutions && (
        <div id="expression-filters" className={expanded ? "minimized" : ""}>
          <ComparisonFilters setTableDataLoading={setTableDataLoading} />
        </div>
      )}
      <div id="comparison-right">
        <MosaicPanel
          mosaicId="comparison"
          elementMap={ELEMENT_MAP}
          tilesLayout={tilesLayout}
          onChangeLayout={handleChangeMosaicLayout}
        />
        <div id="expand-button-container">
          <button
            id="expand-button"
            onClick={() => setExpanded(!expanded)}
            className={expanded ? "expanded" : ""}
          >
            <CaretDoubleLeft size={16} weight="bold" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Comparison;
