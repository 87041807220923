import React, { useState } from "react";
import { DefaultToolbarButton } from "react-mosaic-component";

function ReplaceButton({ options, replaceTile, tileId, path }) {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <span id="swap-button-container">
      <DefaultToolbarButton
        title="swap Window"
        className="swap-button"
        onClick={() => setShowOptions(!showOptions)}
      />
      {showOptions && (
        <div id="options-container">
          {[...options].map((value, index) => {
            return (
              <div
                className="option"
                key={index}
                onClick={() => replaceTile(path, tileId, value)}
              >
                {value}
              </div>
            );
          })}
        </div>
      )}
    </span>
  );
}

export default ReplaceButton;
