import { useState, useMemo, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BookmarkSimple } from "phosphor-react";
import { TableVirtuoso } from "react-virtuoso";
import {
  useBookmarkSolution,
  useRemoveBookmarkSolution,
} from "../../../hooks/sessions";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "../../common/TableItems";
import { IconButton, TableSortLabel, Typography } from "@mui/material";
import { SessionStatus, SortDirection } from "src/utils/types";
import MosaicPanelEmptyMsg from "src/components/common/empty-message/MosaicPanelEmptyMsg";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";

function ComparisonTable() {
  const {
    filteredSolutions,
    sessionStatus,
    sessionId,
    bookmarkedItems,
    setBookmarkedItems,
  } = useMultiTreeSession();

  const bookmarkSolution = useBookmarkSolution((solutions) =>
    setBookmarkedItems(solutions)
  );

  const removeBookmarkSolution = useRemoveBookmarkSolution((id) => {
    setBookmarkedItems((prev) => prev.filter((item) => item.id !== id));
  });

  const [sorting, setSorting] = useState({
    value: null,
    descending: true,
  });

  const sortedSolutions = useMemo(() => {
    return !filteredSolutions || !(filteredSolutions.length > 0)
      ? []
      : sorting.value === null
        ? filteredSolutions
        : filteredSolutions.sort((a, b) => {
            if (a.hasOwnProperty(sorting.value)) {
              if (sorting.descending)
                return a[sorting.value] - b[sorting.value];
              return b[sorting.value] - a[sorting.value];
            } else if (
              a.modelOther?.hasOwnProperty(sorting.value) &&
              b.modelOther?.hasOwnProperty(sorting.value)
            ) {
              if (sorting.descending)
                return (
                  a.modelOther[sorting.value] - b.modelOther[sorting.value]
                );
              return b.modelOther[sorting.value] - a.modelOther[sorting.value];
            }
            return -1;
          });
  }, [filteredSolutions, sorting.value, sorting.descending]);

  const otherMetrics = useMemo(() => {
    if ((filteredSolutions || []).length === 0) {
      return [];
    }

    let metrics = new Set();
    filteredSolutions.forEach((item) => {
      if (item.modelOther) {
        Object.keys(item.modelOther).forEach((key) => {
          metrics.add(key);
        });
      }
    });

    return Array.from(metrics);
  }, [filteredSolutions]);

  const isFailedOrFinished =
    sessionStatus === SessionStatus.FAILED ||
    sessionStatus === SessionStatus.FINISHED;

  if (!filteredSolutions || !(filteredSolutions.length > 0)) {
    return isFailedOrFinished ? (
      <MosaicPanelEmptyMsg
        title={"No populations found"}
        desc={"make sure your algorithm outputs populations.csv"}
      />
    ) : (
      <div className="tile-no-solutions">No solutions found</div>
    );
  }

  async function bookmark(solution) {
    try {
      await bookmarkSolution.mutateAsync({
        sessionId: sessionId,
        ...solution,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function removeBookmark(solution) {
    try {
      const bookmarked = bookmarkedItems.find(
        (item) => item.id === solution.id
      );
      await removeBookmarkSolution.mutateAsync({
        sessionId: solution.session,
        resultId: bookmarked.resultId,
        modelId: solution.id,
        algorithm: solution.algorithm,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function clickOnSort(value) {
    if (sorting.value === value) {
      setSorting({ value: value, descending: !sorting.descending });
    } else {
      setSorting({ value: value, descending: true });
    }
  }

  return (
    <div id="comparison-table">
      <TableVirtuoso
        style={{ height: "100%" }}
        data={sortedSolutions}
        components={{
          ...VirtualizedTableComponents,
          TableRow: (props) => <StyledTableRow {...props} />,
        }}
        fixedHeaderContent={() => (
          <StyledTableRow>
            <StyledTableCell align="left">ID</StyledTableCell>
            <StyledTableCell align="left">EXPRESSION</StyledTableCell>
            <StyledTableCell
              align="left"
              sortDirection={
                sorting.descending ? SortDirection.DESC : SortDirection.ASC
              }
            >
              <TableSortLabel
                active={sorting.value === "size"}
                direction={
                  sorting.descending ? SortDirection.DESC : SortDirection.ASC
                }
                onClick={() => clickOnSort("size")}
              >
                SIZE
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              align="left"
              sortDirection={
                sorting.descending ? SortDirection.DESC : SortDirection.ASC
              }
            >
              <TableSortLabel
                active={sorting.value === "fitness"}
                direction={
                  sorting.descending ? SortDirection.DESC : SortDirection.ASC
                }
                onClick={() => clickOnSort("fitness")}
              >
                FITNESS
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              align="left"
              sortDirection={
                sorting.descending ? SortDirection.DESC : SortDirection.ASC
              }
            >
              <TableSortLabel
                active={sorting.value === "modelSize"}
                direction={
                  sorting.descending ? SortDirection.DESC : SortDirection.ASC
                }
                onClick={() => clickOnSort("modelSize")}
              >
                SIZE (model)
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell
              align="left"
              sortDirection={
                sorting.descending ? SortDirection.DESC : SortDirection.ASC
              }
            >
              <TableSortLabel
                active={sorting.value === "modelFitness"}
                direction={
                  sorting.descending ? SortDirection.DESC : SortDirection.ASC
                }
                onClick={() => clickOnSort("modelFitness")}
              >
                FITNESS (model)
              </TableSortLabel>
            </StyledTableCell>
            {otherMetrics.map((key, index) => (
              <StyledTableCell
                key={key}
                align="left"
                sortDirection={
                  sorting.descending ? SortDirection.DESC : SortDirection.ASC
                }
              >
                <TableSortLabel
                  active={sorting.value === key}
                  direction={
                    sorting.descending ? SortDirection.DESC : SortDirection.ASC
                  }
                  onClick={() => clickOnSort(key)}
                >
                  {key} (model)
                </TableSortLabel>
              </StyledTableCell>
            ))}
            <StyledTableCell align="center"></StyledTableCell>
          </StyledTableRow>
        )}
        itemContent={(index, solution) => {
          const isBookmarkedSolution = Boolean(
            (bookmarkedItems || []).find((item) => item.id === solution.id)
          );
          const rowBackgroundColor = solution.id % 2 === 0 ? "#f1f5f9" : "";

          return (
            <Fragment
              key={`${solution.generation}-${solution.individual}-${solution.id}`}
            >
              <StyledTableCell
                key={`${solution.generation}-${solution.individual}`}
                align="left"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: rowBackgroundColor,
                }}
              >
                <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                  modelID: {solution.individual}
                </Typography>
                <Typography variant="caption">
                  tree: {solution.treeIndex + 1}
                </Typography>
              </StyledTableCell>
              <CopyToClipboard text={solution.model}>
                <StyledTableCell
                  align="left"
                  title={solution.model}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "400px",
                    maxWidth: "400px",
                    cursor: "copy",
                    backgroundColor: rowBackgroundColor,
                  }}
                >
                  {solution.model}
                </StyledTableCell>
              </CopyToClipboard>
              <StyledTableCell
                sx={{ backgroundColor: rowBackgroundColor }}
                align="left"
              >
                {solution.size}
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: rowBackgroundColor }}
                align="left"
              >
                {solution.fitness}
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: rowBackgroundColor }}
                align="left"
              >
                {solution.modelSize}
              </StyledTableCell>
              <StyledTableCell
                sx={{ backgroundColor: rowBackgroundColor }}
                align="left"
              >
                {solution.modelFitness}
              </StyledTableCell>
              {otherMetrics.map((key, index) => {
                return (
                  <StyledTableCell
                    sx={{ backgroundColor: rowBackgroundColor }}
                    align="left"
                  >
                    {solution.modelOther
                      ? solution.modelOther[key] || "-"
                      : "-"}
                  </StyledTableCell>
                );
              })}
              <StyledTableCell
                sx={{ backgroundColor: rowBackgroundColor }}
                align="center"
              >
                <IconButton
                  sx={{ width: "32px", height: "32px" }}
                  onClick={() => {
                    isBookmarkedSolution
                      ? removeBookmark(solution)
                      : bookmark(solution);
                  }}
                >
                  <BookmarkSimple
                    weight="fill"
                    size={17}
                    color={`${isBookmarkedSolution ? "#0101ff" : "#b9c3cb"}`}
                  />
                </IconButton>
              </StyledTableCell>
            </Fragment>
          );
        }}
      />
    </div>
  );
}

export default ComparisonTable;
