import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  SessionStatus,
  SessionTypes,
  SESSION_REQ_INTERVAL,
} from "src/utils/types";
import { useGetProject } from "./projects";
import {
  useGetBoomarkedSolutions,
  useGetGenerations,
  useGetLogs,
  useGetPopulation,
  useGetResults,
  useGetSession,
  useGetSessionBookmarks,
  useGetSessions,
  useGetTestResults,
} from "./sessions";
import { useGetConfigs } from "./configurations";

const sessionContext = createContext();

export const SessionPageProvider = ({ children }) => {
  const params = useParams();
  const sessionId = params.sessionId;
  const projectId = params.id;

  const [allPopulations, setAllPopulations] = useState([]);
  const [editing, setEditing] = useState(false);
  const [solutions, setSolutions] = useState(null);
  const [filteredSolutions, setFilteredSolutions] = useState(null);
  const [filters, setFilters] = useState(null);
  const [bookmarkedItems, setBookmarkedItems] = useState(new Set([]));

  const { data: session } = useGetSession(sessionId);
  const { data: project } = useGetProject(projectId);
  const { data: sessions } = useGetSessions(projectId);
  const { data: configurations } = useGetConfigs(projectId);
  const { data: bookmarkeds } = useGetSessionBookmarks(sessionId);

  const isTestSession = Boolean(
    session?.data?.properties?.commandType === SessionTypes.TEST,
  );

  const isTrainSession = Boolean(
    session?.data?.properties?.commandType === SessionTypes.TRAIN,
  );

  const [sessionStatus, setSessionStatus] = useState(session?.data.status);
  const [running, setRunning] = useState(
    sessionStatus === SessionStatus.RUNNING,
  );

  const [intervalMs, setIntervalMs] = useState(
    (sessionStatus !== SessionStatus.FINISHED &&
      sessionStatus !== SessionStatus.KILLED) ||
      running
      ? SESSION_REQ_INTERVAL
      : false,
  );

  const [populationIntervalMs, setPopulationIntervalMs] = useState(
    (sessionStatus !== SessionStatus.FINISHED &&
      sessionStatus !== SessionStatus.KILLED) ||
      running
      ? SESSION_REQ_INTERVAL
      : false,
  );

  const { data: results } = useGetResults({
    sessionId: sessionId,
    intervalMs: intervalMs,
  });
  
  const { data: testResultsData } = useGetTestResults({
    sessionId: sessionId,
    intervalMs: intervalMs,
    enabled: Boolean(isTestSession),
  });

  const { data: trainResultsData } = useGetGenerations({
    sessionId: sessionId,
    intervalMs: intervalMs,
    enabled: Boolean(isTrainSession),
  });


  const { data: logs } = useGetLogs({
    sessionId: sessionId,
    intervalMs: intervalMs,
  });

  const population = useGetPopulation({
    sessionId: sessionId,
    intervalMs: populationIntervalMs,
    enabled: isTrainSession && Boolean(sessionId),
  });

  useEffect(() => {
    if (sessionStatus !== results?.status) {
      setSessionStatus(results?.status);
    }

    if (
      results?.status === SessionStatus.FINISHED ||
      results?.status === SessionStatus.KILLED ||
      results?.status === SessionStatus.FAILED
    ) {
      setRunning(false);
    } else if (results?.status === SessionStatus.RUNNING) {
      setRunning(true);
    }
  }, [results?.status]);

  useEffect(() => {
    const sessionPopulation = population?.data?.data || [];

    if (sessionPopulation.length > 0) {
      setPopulationIntervalMs(false);
      let newAllPopulations = {};

      if (allPopulations) newAllPopulations = { ...allPopulations };
      newAllPopulations[sessionId] = [];

      sessionPopulation.forEach((item) => {
        newAllPopulations[sessionId] = [
          ...(newAllPopulations[sessionId] || []),
          ...(item?.results?.map((r) => ({
            ...r,
            generation: item?.generation,
            resultId: item?.id,
          })) || []),
        ];
      });
      setAllPopulations(newAllPopulations);
    }
  }, [population?.data, sessionId]);

  useEffect(() => {
    running ? setIntervalMs(SESSION_REQ_INTERVAL) : setIntervalMs(false);
  }, [running]);

  useEffect(() => {
    if (bookmarkeds?.data) {
      setBookmarkedItems(bookmarkeds.data);
    }
  }, [bookmarkeds]);

  const updateSolutions = useCallback(
    (populations) => {
      if (!populations || !session) return;

      let newSolutions = [];
      if (populations[session.data.id]) {
        newSolutions = populations[session.data.id];
      }
      if (newSolutions.length > 0) {
        setFilteredSolutions(newSolutions);
        setSolutions(newSolutions);
      }
    },
    [session?.data.id],
  );

  useEffect(() => {
    if (allPopulations) updateSolutions(allPopulations);
  }, [session?.data?.id, allPopulations, updateSolutions]);

  const data = {
    sessionId,
    selectedSession: session?.data?.id
      ? { value: session.data.id, label: session.data.name }
      : null,
    sessionStatus,
    setSessionStatus,
    running,
    setRunning,
    intervalMs,
    setIntervalMs,
    editing,
    setEditing,
    allPopulations,
    setAllPopulations,
    isTestSession,
    isTrainSession,
    trainGenerationsData: trainResultsData,
    testGenerationsData: testResultsData,
    logs: logs?.data,
    session: session?.data,
    project: project?.data,
    allSessions: sessions?.data,
    configurations: configurations?.data || [],
    solutions,
    setSolutions,
    filteredSolutions,
    setFilteredSolutions,
    filters,
    setFilters,
    bookmarkedItems,
    setBookmarkedItems,
  };

  return (
    <sessionContext.Provider value={data}>{children}</sessionContext.Provider>
  );
};

export const useSession = () => useContext(sessionContext);
