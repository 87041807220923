import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  SINGLE_SHAPLEY_EXPLANATION_KEY,
  useRunChildShapley,
} from "src/hooks/feature-importance";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { getErrorMsg } from "src/utils/Utils";
import { useGetDatasetData } from "src/hooks/datasets";
import { useParams } from "react-router-dom";
import SelectDatasetInstance from "./SelectDatasetInstance";
import { X } from "phosphor-react";
import { useQueryClient } from "react-query";
import { SessionStatus } from "src/utils/types";

const RunChildShapleyDialog = ({
  open,
  parentExplanation,
  session,
  existedExplanations,
  onChangeExplanationStatus,
  onClose,
}) => {
  const { notify } = useNotifier();
  const params = useParams();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [selectedInstanceIndexes, setSelectedInstanceIndexes] = useState([]);

  useEffect(() => {
    setSelectedInstanceIndexes([]);
  }, [open]);

  const { mutateAsync: runChildShapley, isLoading: childShapleyLoading } =
    useRunChildShapley();

  const {
    data: datasetData,
    isLoading: dataSetLoading,
    error: datasetErr,
  } = useGetDatasetData({
    projectId: params.id,
    datasetId: session?.dataset.id,
    delimiter: null,
    fileName: session?.dataset.name,
  });

  const handleRunChildShapley = () => {
    if (parentExplanation.state === SessionStatus.RUNNING) {
      notify(
        NotificationType.WARNING,
        "Please wait until parent explanation finish."
      );
      return;
    }

    const body = {
      instanceIdxs: selectedInstanceIndexes,
      type: "waterfall",
    };

    runChildShapley({ expId: parentExplanation.id, body })
      .then(() => {
        queryClient.invalidateQueries([
          SINGLE_SHAPLEY_EXPLANATION_KEY,
          parentExplanation.id,
        ]);
        onChangeExplanationStatus();
        notify(
          NotificationType.INFO,
          "Analyse started with selected dataset instances."
        );
        onClose();
      })
      .catch((err) => {
        notify(NotificationType.ERROR, getErrorMsg(err));
      });
  };

  const handleChangeSelectedIndexes = (index) => {
    let temp = new Set(selectedInstanceIndexes);
    if (temp.has(index)) {
      temp.delete(index);
    } else {
      temp.add(index);
    }
    setSelectedInstanceIndexes(Array.from(temp));
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle variant="h6" fontWeight={600}>
        Run Child Feature Importance Analyse
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: ".5rem", right: "1rem" }}
      >
        <X />
      </IconButton>
      <DialogContent>
        <SelectDatasetInstance
          loading={dataSetLoading}
          datasetData={datasetData}
          error={datasetErr}
          selectedInstanceIndexes={selectedInstanceIndexes}
          existedExplanations={existedExplanations}
          onSelectInstance={handleChangeSelectedIndexes}
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ height: "34px" }}
          size="small"
          color="error"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          disabled={selectedInstanceIndexes.length === 0 || !parentExplanation}
          variant="contained"
          startIcon={
            childShapleyLoading ? (
              <CircularProgress
                size={16}
                sx={{ color: theme.palette.primary.contrastText }}
              />
            ) : undefined
          }
          onClick={
            childShapleyLoading ? undefined : () => handleRunChildShapley()
          }
        >
          {childShapleyLoading ? "Evaluating..." : "Evaluate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RunChildShapleyDialog;
