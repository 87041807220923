import React from "react";
import { useParams } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import { TableVirtuoso } from "react-virtuoso";
import { useGetDatasetData } from "../../hooks/datasets";
import Alert from "../Alert";
import {
  StyledTableCell,
  VirtualizedTableComponents,
} from "../common/TableItems";

function Data({ datasetId, delimiter, fileName }) {
  let params = useParams();
  const isCsv = fileName.split(".").pop() === "csv";

  const { data: datasetData, isLoading } = useGetDatasetData({
    projectId: params.id,
    datasetId,
    delimiter,
    fileName,
  });

  return (
    <div id="dataset-table-section">
      {isLoading ? (
        <div className="tile-loader-container">
          <div className="tile-loader"></div>
        </div>
      ) : datasetData?.data ? (
        isCsv ? (
          <TableVirtuoso
            style={{ height: "100%", minHeight: "calc(100dvh - 275px)" }}
            data={datasetData.data.rows}
            components={VirtualizedTableComponents}
            fixedHeaderContent={() => (
              <TableRow>
                {datasetData.data.header.map((item, index) => (
                  <StyledTableCell align="left" key={index}>
                    {item}
                  </StyledTableCell>
                ))}
              </TableRow>
            )}
            itemContent={(index, row) => (
              <>
                {row.map((value, index) => (
                  <StyledTableCell align="left" key={index}>
                    {value}
                  </StyledTableCell>
                ))}
              </>
            )}
          />
        ) : (
          <p id="raw-data">{JSON.stringify(datasetData.data, null, 2)}</p>
        )
      ) : (
        <Alert
          type="error"
          message="Dataset could not read. Please check content!"
        />
      )}
    </div>
  );
}

export default Data;
