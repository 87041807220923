import {
  Alert,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  useTheme,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../common/TableItems";
import { Trash } from "phosphor-react";

const ExpressionsTable = ({ selectedPopulations, onDelete }) => {
  const theme = useTheme();

  if (selectedPopulations.length === 0) {
    return (
      <Box sx={{ width: "100%" }}>
        <Alert severity="info">No expression selected.</Alert>
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left" key={"index"}>
              #
            </StyledTableCell>
            <StyledTableCell align="left" key={"session"}>
              Session
            </StyledTableCell>
            <StyledTableCell align="left" key={"expression"}>
              Expression
            </StyledTableCell>
            <StyledTableCell align="left" key={"fitness"}>
              Fitness
            </StyledTableCell>
            <StyledTableCell align="left" key={"size"}>
              Size
            </StyledTableCell>
            <StyledTableCell align="right" key={"action"}></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {selectedPopulations.map((item, index) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell align="left" key={"index"}>
                {index + 1}
              </StyledTableCell>
              <StyledTableCell align="left" key={"session"}>
                {item?.session?.name || "-"}
              </StyledTableCell>
              <StyledTableCell align="left" key={"expression"}>
                {item?.model || "-"}
              </StyledTableCell>
              <StyledTableCell align="left" key={"fitness"}>
                {item?.fitness || "-"}
              </StyledTableCell>
              <StyledTableCell align="left" key={"size"}>
                {item?.size || "-"}
              </StyledTableCell>
              <StyledTableCell align="right" key={"action"}>
                <IconButton onClick={() => onDelete(item.id)}>
                  <Trash fontSize={16} color={theme.palette.error.main} />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExpressionsTable;
