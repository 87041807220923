import { CopyToClipboard } from "react-copy-to-clipboard";
import { Trash } from "phosphor-react";
import { IconButton, TableSortLabel, useTheme } from "@mui/material";
import { TableVirtuoso } from "react-virtuoso";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "src/components/common/TableItems";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { useRemoveBookmarkSolution } from "src/hooks/sessions";
import { getErrorMsg } from "src/utils/Utils";
import { SortDirection } from "src/utils/types";
import { useMemo } from "react";

const BookmarkListTable = ({
  existingBookmarks,
  sortField,
  sortDirection,
  onChangeSort,
}) => {
  const { notify } = useNotifier();
  const theme = useTheme();
  const removeBookmarkSolution = useRemoveBookmarkSolution();

  const otherFields = useMemo(() => {
    let fields = [];
    const bookmarkHasOther = existingBookmarks.find((item) =>
      Boolean(item.other),
    );
    if (bookmarkHasOther) {
      Object.keys(bookmarkHasOther.other).forEach((key) => {
        fields.push(key);
      });
    }

    return fields;
  }, [existingBookmarks]);

  const removeBookmark = async (solution) => {
    try {
      await removeBookmarkSolution.mutateAsync({
        resultId: solution.resultId,
        modelId: solution.id,
        sessionId: solution.session,
        algorithm: solution.algorithm,
      });
    } catch (error) {
      notify(NotificationType.ERROR, getErrorMsg(error));
    }
  };

  return (
    <TableVirtuoso
      style={{ height: "100%", minHeight: "calc(100dvh - 135px)" }}
      data={existingBookmarks}
      components={VirtualizedTableComponents}
      fixedHeaderContent={() => (
        <StyledTableRow>
          <StyledTableCell align="left" key={"EXPRESSION"}>
            EXPRESSION
          </StyledTableCell>
          <StyledTableCell
            align="left"
            key={"size"}
            sortDirection={sortField === "size" ? sortDirection : false}
          >
            <TableSortLabel
              active={sortField === "size"}
              direction={
                sortField === "size" ? sortDirection : SortDirection.ASC
              }
              onClick={() => onChangeSort("size")}
            >
              SIZE
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell
            align="left"
            key={"fitness"}
            sortDirection={sortField === "fitness" ? sortDirection : false}
          >
            <TableSortLabel
              active={sortField === "fitness"}
              direction={
                sortField === "fitness" ? sortDirection : SortDirection.ASC
              }
              onClick={() => onChangeSort("fitness")}
            >
              FITNESS
            </TableSortLabel>
          </StyledTableCell>
          {otherFields.map((field) => (
            <StyledTableCell align="left" key={field}>
              {field}
            </StyledTableCell>
          ))}
          <StyledTableCell align="right" key={"actions"} />
        </StyledTableRow>
      )}
      itemContent={(index, solution) => (
        <>
          <CopyToClipboard
            text={solution.model}
            onCopy={() => notify(NotificationType.SUCCESS, "Copied!")}
          >
            <StyledTableCell align="left" sx={{ cursor: "copy" }}>
              {solution.model}
            </StyledTableCell>
          </CopyToClipboard>
          <StyledTableCell align="left">{solution.size || "-"}</StyledTableCell>
          <StyledTableCell align="left">
            {solution.fitness || "-"}
          </StyledTableCell>
          {otherFields.map((field) => (
            <StyledTableCell align="left" key={field}>
              {solution.other?.[field] || "-"}
            </StyledTableCell>
          ))}
          <StyledTableCell align="right">
            <IconButton
              sx={{ height: "auto" }}
              onClick={() => {
                removeBookmark(solution);
              }}
            >
              <Trash size={20} color={theme.palette.error.main} />
            </IconButton>
          </StyledTableCell>
        </>
      )}
    />
  );
};

export default BookmarkListTable;
