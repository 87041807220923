import { useQuery, useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";

export const useGetDatasets = (projectId) =>
  useQuery(["datasets", projectId], () => {
    return ApiClient.get(`/api/projects/${projectId}/datasets`);
  });

export const useGetDatasetData = ({
  projectId,
  datasetId,
  delimiter,
  fileName,
}) =>
  useQuery(
    ["datasets", projectId, datasetId, fileName],
    async () => {
      let res = await ApiClient.get(
        `/api/projects/${projectId}/datasets/${datasetId}/data`,
      );

      const isCsv = fileName.split(".").pop() === "csv";
      if (!isCsv) return res;

      let lines = res.data.split("\n");
      res.data = {
        header: lines[0].split(delimiter ? delimiter : ","),
        rows: lines
          .slice(1)
          .map((line) => line.split(delimiter ? delimiter : ",")),
      };

      return res;
    },
    {
      enabled: Boolean(datasetId) && Boolean(fileName),
      retry: false,
    },
  );

export const useUploadDataset = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, formData }) =>
      ApiClient.post(`/api/projects/${projectId}/datasets`, formData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("datasets");
      },
    },
  );
};

export const useDeleteDataset = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectId, datasetId }) =>
      ApiClient.delete(`/api/projects/${projectId}/datasets/${datasetId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("datasets");
      },
    },
  );
};
