import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CircularProgress } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { GET_EXPLANATIONS_QUERY_KEY } from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";
import { SHAPLEY_EXPLANATIONS_KEY } from "src/hooks/feature-importance";

export const DeleteExplanationFrom = {
  SHAPLEY: "SHAPLEY",
  COUNTERFACTUAL: "COUNTERFACTUAL",
};

const DeleteExplanationDialog = ({
  open,
  explanation,
  sessionId,
  from = DeleteExplanationFrom.COUNTERFACTUAL,
  onClose,
}) => {
  const { notify } = useNotifier();
  const queryClient = useQueryClient();

  const { mutate: deleteExplanationMutation, isLoading } = useMutation({
    mutationFn: () => ApiClient.delete(`/api/explanations/${explanation.id}`),
    onSuccess: () => {
      if (from === DeleteExplanationFrom.SHAPLEY) {
        queryClient.invalidateQueries([SHAPLEY_EXPLANATIONS_KEY, sessionId]);
      } else if (from === DeleteExplanationFrom.COUNTERFACTUAL) {
        queryClient.invalidateQueries([GET_EXPLANATIONS_QUERY_KEY, sessionId]);
      }

      notify(NotificationType.SUCCESS, "Explanation deleted!");
      onClose();
    },
    onError: (err) => {
      notify(NotificationType.ERROR, getErrorMsg(err));
    },
  });

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        Delete Explanation
      </DialogTitle>
      <DialogContent>
        Are you sure? <b>{explanation.id}</b> will be deleted.
      </DialogContent>
      <DialogActions>
        <Button sx={{ height: "34px" }} size="small" onClick={onClose}>
          Close
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="error"
          size="small"
          sx={{ height: "34px" }}
          startIcon={
            isLoading ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : undefined
          }
          onClick={isLoading ? undefined : () => deleteExplanationMutation()}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteExplanationDialog;
