import React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

export const StyledTableCell = styled(TableCell)(
  ({ theme, fontSize, height }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f5f5f5",
      color: "#002169",
      fontFamily: "Titillium Web",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: fontSize ?? 14,
      fontFamily: "Titillium Web",
      color: "#555555",
      fontWeight: 500,
      height: `${height ? height + "px" : "auto"}`,
    },
  }),
);

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

export const VirtualizedTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: (props) => <StyledTableRow {...props} hover />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
