import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Trash, Plus, Copy } from "phosphor-react";
import { Box } from "@mui/material";
import { useGetAlgorithms } from "../hooks/algorithms";
import { useGetConfigs } from "../hooks/configurations";
import { useGetAllPopulations, useGetSessions } from "../hooks/sessions";
import CloneConfigDialog from "src/components/config/CloneConfigDialog";
import ConfigDetails from "src/components/config/ConfigDetails";
import CreateConfigDialog from "src/components/config/CreateConfigDialog";
import Alert from "src/components/Alert";
import DeleteConfigDialog from "src/components/config/DeleteConfigDialog";

export default function ConfigPage() {
  const params = useParams();
  const { data: configsData, isLoading } = useGetConfigs(params.id);
  const { data: sessions } = useGetSessions(params.id);
  const { data: allPopulationsIndividuals } = useGetAllPopulations(
    sessions?.data,
  );
  const { data: algorithmsData } = useGetAlgorithms();
  const [algorithmList, setAlgorithms] = useState([]);
  const [allPopulations, setAllPopulations] = useState(
    allPopulationsIndividuals,
  );
  const [selectedConfig, setSelectedConfig] = useState();
  const [configs, setConfigs] = useState([]);
  const [search, setSearch] = useState("");

  const [newConfigDialogVisible, setNewConfigDialogVisible] = useState(false);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const selectedAlgorithm = useMemo(() => {
    if (selectedConfig && algorithmsData?.data) {
      return algorithmsData?.data
        .find((item) => item.id === selectedConfig?.algorithm?.id)
        ?.commands.find(
          (item) => item.commandType === selectedConfig?.commandType,
        );
    }
    return null;
  }, [selectedConfig]);

  useEffect(() => {
    if (configsData?.data) {
      setConfigs(configsData.data);

      if (!selectedConfig && configs.length > 0) {
        setSelectedConfig(configsData.data[0]);
      }
    }
  }, [configsData, selectedConfig]);

  useEffect(() => {
    if (!allPopulationsIndividuals) return;
    let solutionsList = [];
    for (const population in allPopulationsIndividuals) {
      solutionsList = solutionsList.concat(
        allPopulationsIndividuals[population],
      );
    }
    setAllPopulations(solutionsList);
  }, [allPopulationsIndividuals]);

  useEffect(() => {
    if (algorithmsData?.data) {
      const projectAlgorithms = algorithmsData.data.map((algorithm) => {
        return {
          id: algorithm.id,
          label: algorithm.name,
          type: algorithm.type,
          commands: algorithm.commands.map((item) => {
            return {
              id: item.commandType,
              label: item.commandType,
            };
          }),
        };
      });

      setAlgorithms(projectAlgorithms);
    }
  }, [algorithmsData]);

  const handleAddNewConfig = () => {
    setNewConfigDialogVisible(true);
  };

  const handleCopyConfigConfirmation = (config) => {
    setSelectedConfig(config);
    setCloneDialogOpen(true);
  };

  const handleDeleteDialogOpen = (config) => {
    setSelectedConfig(config);
    setDeleteDialogOpen(true);
  };

  const handleChangeConfig = (config) => {
    setSelectedConfig(config);
  };

  const updateSearch = (newSearch) => {
    setSearch(newSearch);
    if (configsData?.data) {
      setConfigs(
        configsData.data.filter((dataset) =>
          dataset.name.toLowerCase().includes(newSearch.toLowerCase()),
        ),
      );
    }
  };

  return (
    <div className="project-tab">
      {configs.length === 0 && !isLoading && (
        <Box sx={{ width: "100%", mb: "1rem" }}>
          <Alert type="info" message="No config found." />
        </Box>
      )}
      <section id="configs-section">
        <div id="configs-left">
          <div id="config-upload-container">
            <input
              type="text"
              id="config-search"
              placeholder="Search a config"
              value={search}
              onChange={(e) => updateSearch(e.target.value)}
            ></input>
            <button
              className="icon-button submit-button"
              type="button"
              onClick={handleAddNewConfig}
            >
              <Plus size={18} color="#ffffff" weight="bold" />
              <span>Config</span>
            </button>
          </div>
          {configs.length > 0 && (
            <div id="configs-table">
              <div className="table-top">
                <span className="name">NAME</span>
                <span className="download"></span>
                <span className="trash"></span>
              </div>
              <div className="table-body">
                {configs.map((config, index) => {
                  const isSelected =
                    selectedConfig && config.id === selectedConfig.id;
                  return (
                    <li
                      key={index}
                      className={`table-entry${isSelected ? " selected" : ""}`}
                    >
                      <div
                        className="info"
                        onClick={() => handleChangeConfig(config)}
                      >
                        <span>{config.name}</span>
                        <span className="sub-info">
                          {`${config.algorithm?.name} ${
                            config.commandType ? " - " + config.commandType : ""
                          }`}
                        </span>
                        <span className="config-desc">
                          {config.description}
                        </span>
                      </div>
                      {isSelected && (
                        <>
                          <span
                            className="download"
                            onClick={() => handleCopyConfigConfirmation(config)}
                          >
                            <Copy size={20} color="#ad1b00" />
                          </span>
                          <span
                            className="trash"
                            onClick={() => handleDeleteDialogOpen(config)}
                          >
                            <Trash size={20} color="#ad1b00" />
                          </span>
                        </>
                      )}
                    </li>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div id="configs-right">
          {selectedConfig && selectedAlgorithm && (
            <ConfigDetails
              config={selectedConfig}
              algorithm={selectedAlgorithm}
              allPopulations={allPopulations}
              allPopulationsIndividuals={allPopulationsIndividuals}
            />
          )}
        </div>
      </section>

      {newConfigDialogVisible && (
        <CreateConfigDialog
          open={true}
          algorithms={algorithmList}
          onClose={() => setNewConfigDialogVisible(false)}
        />
      )}

      {cloneDialogOpen && selectedConfig && (
        <CloneConfigDialog
          open={true}
          conf={selectedConfig}
          onClose={() => setCloneDialogOpen(false)}
        />
      )}

      {deleteDialogOpen && selectedConfig && (
        <DeleteConfigDialog
          open={true}
          conf={selectedConfig}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}
    </div>
  );
}
