export const resultsCountsValues = ["3", "5", "10"];
export const DEFAULT_RESULT_COUNTS = "5";
export const EXPLANATION_STATUS_CHECK_INTERVAL = 3000;
export const EXPLANATION_STATUS_RETRY_COUNT = 2;

export const ExplanationState = {
  CREATED: "CREATED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  PENDING: "PENDING",
  UNKNOWN: "UNKOWN",
  RUNNING: "RUNNING",
};
