import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";
import { TRUST_USER_LOCAL_KEY } from "src/utils/types";

const Initial = () => {
  const user = JSON.parse(localStorage.getItem(TRUST_USER_LOCAL_KEY));
  if (!user) {
    return <Navigate to="/login?tokenTimeout=true" />;
  }

  const decodedUser = jwtDecode(user.jwt);
  const expireTime = decodedUser.exp;

  if (Date.now() > expireTime) {
    return <Navigate to="/login?tokenTimeout=true" />;
  }

  return <Navigate to={"/projects"} />;
};

export default Initial;
