import { Box, useTheme } from "@mui/material";
import { ArrowUp } from "phosphor-react";

const ScrollToTop = (props) => {
  const theme = useTheme();

  const scroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box
      id="scroll-to-btn"
      onClick={scroll}
      sx={{
        position: "fixed",
        bottom: "1rem",
        right: ".75rem",
        width: "2.875rem",
        height: "2.875rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        cursor: "pointer",
        background: theme.palette.primary.contrastText,
        border: `1px solid var(--gray-border)`,
        filter: `var(--drop-shadow)`,
      }}
      {...props}
    >
      <ArrowUp size={16} color={theme.palette.primary.main} />
    </Box>
  );
};

export default ScrollToTop;
