import { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Pencil, Trash } from "phosphor-react";
import { getCSSVar } from "src/utils/Utils";
import DeleteAlgorithmDialog from "./DeleteAlgorithmDialog";
import CreateAlgoritmDialog from "./CreateAlgoritmDialog";
import { DialogMode } from "src/utils/types";

const AlgorithmListTable = ({
  selectedAlgorithm,
  algorithmsData,
  setSelectedAlgorithm,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  return (
    <div id="overview-table">
      <div className="table-top">
        <span className="name">Name</span>
      </div>
      <div className="table-body">
        {selectedAlgorithm &&
          algorithmsData?.data.map((algorithm, index) => {
            const isSelected = algorithm.id === selectedAlgorithm.id;
            return (
              <li
                key={algorithm.id}
                className={`table-entry${isSelected ? " selected" : ""}`}
                onClick={() => {
                  setSelectedAlgorithm(algorithm);
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    padding: "0 1rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      fontFamily: "Titillium Web",
                    }}
                  >
                    {algorithm.name}
                  </Typography>
                  {isSelected && (
                    <Box
                      id="actions"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                      }}
                    >
                      <IconButton
                        sx={{ width: "2.5rem", height: "2.5rem" }}
                        onClick={() => setUpdateDialogOpen(true)}
                      >
                        <Pencil color={getCSSVar("primary-color")} size={18} />
                      </IconButton>
                      <IconButton
                        sx={{ width: "2.5rem", height: "2.5rem" }}
                        onClick={() => setDeleteDialogOpen(true)}
                      >
                        <Trash color={getCSSVar("danger-color")} size={18} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </li>
            );
          })}
      </div>
      {deleteDialogOpen && selectedAlgorithm && (
        <DeleteAlgorithmDialog
          open={deleteDialogOpen}
          algorithm={selectedAlgorithm}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}
      {updateDialogOpen && selectedAlgorithm && (
        <CreateAlgoritmDialog
          open={updateDialogOpen}
          algorithm={selectedAlgorithm}
          mode={DialogMode.UPDATE}
          onClose={() => setUpdateDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default AlgorithmListTable;
