import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";

const PleaseWait = ({ message, iconColor, thickness }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "2rem 0",
        padding: "0 1rem",
      }}
    >
      <CircularProgress
        thickness={thickness || 3.6}
        sx={{ color: iconColor || theme.palette.primary }}
      />
      <Typography mt={2} align="center">
        {message || "Getting details. Please wait..."}
      </Typography>
    </Box>
  );
};

export default PleaseWait;
