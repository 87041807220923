import { Stack, Typography } from "@mui/material";

const MosaicPanelEmptyMsg = ({ title, desc }) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      mt={2}
      padding={"1.5rem"}
      gap={".5rem"}
    >
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body1" align="center">
        {desc || ""}
      </Typography>
    </Stack>
  );
};

export default MosaicPanelEmptyMsg;
