import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import { CircularProgress, Typography } from "@mui/material";
import { GET_SESSIONS_QUERY_KEY } from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";

const DeleteSessionDialog = (props) => {
  const { notify } = useNotifier();
  const queryClient = useQueryClient();

  const { mutate: deleteSessionMutation, isLoading } = useMutation({
    mutationFn: () => ApiClient.delete(`/api/sessions/${props.sessionId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(GET_SESSIONS_QUERY_KEY);

      notify(NotificationType.SUCCESS, "Session deleted!");
      props.onClose();
    },
    onError: (err) => {
      notify(NotificationType.ERROR, getErrorMsg(err));
    },
  });

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        Delete Session
      </DialogTitle>
      <DialogContent dividers>
        Are you sure? <b>{props.sessionName}</b> will be deleted.
      </DialogContent>
      <DialogActions>
        <Button sx={{ height: "34px" }} size="small" onClick={props.onClose}>
          Close
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="error"
          size="small"
          sx={{ height: "34px" }}
          startIcon={
            isLoading ? (
              <CircularProgress size={16} sx={{ color: "white" }} />
            ) : undefined
          }
          onClick={isLoading ? undefined : () => deleteSessionMutation()}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSessionDialog;
