import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../TableItems";

const SelectedExpressionTable = ({ selectedPopulation, onChange }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left" key={"index"}>
              #
            </StyledTableCell>
            <StyledTableCell align="left" key={"expression"}>
              Selected Expression
            </StyledTableCell>
            <StyledTableCell align="left" key={"fitness"}>
              Fitness
            </StyledTableCell>
            <StyledTableCell align="left" key={"size"}>
              Size
            </StyledTableCell>
            <StyledTableCell align="right" key={"action"}></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell align="left" key={"index"}>
              {selectedPopulation?.index + 1 || "-"}
            </StyledTableCell>
            <StyledTableCell align="left" key={"expression"}>
              {selectedPopulation?.model}
            </StyledTableCell>
            <StyledTableCell align="left" key={"fitness"}>
              {selectedPopulation?.fitness}
            </StyledTableCell>
            <StyledTableCell align="left" key={"size"}>
              {selectedPopulation?.size}
            </StyledTableCell>
            <StyledTableCell align="right" key={"action"}>
              <Button
                variant="outlined"
                sx={{ height: "35px" }}
                onClick={onChange}
              >
                Change
              </Button>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SelectedExpressionTable;
