import { useState } from "react";
import { useParams } from "react-router-dom";
import ApiClient from "src/axios";
import { Box, Typography, Grid } from "@mui/material";
import { useQuery } from "react-query";
import { Plus, Trash } from "phosphor-react";
import { useGetPopulationsofSession, useGetSessions } from "../hooks/sessions";
import {
  DialogMode,
  GET_EXPLANATIONS_QUERY_KEY,
  SESSION_REQ_INTERVAL,
} from "src/utils/types";
import CounterFactualDialog from "src/components/counterfactual/CounterfactualDialog";
import Dropdown from "src/components/Dropdown";
import Alert from "src/components/Alert";
import DeleteExplanationDialog, { DeleteExplanationFrom } from "src/components/counterfactual/DeleteExplanation";
import NewCounterfactualDialog from "src/components/counterfactual/NewCounterfactualDialog";
import ExplanationCard from "src/components/counterfactual/ExplanationCard";

const CounterFactual = () => {
  let params = useParams();

  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedExplanation, setSelectedExplanation] = useState(null);
  const [deleteExpDialogOpen, setDeleteExpDialogOpen] = useState(false);
  const [newCounterfactualOpen, setNewCounterfactualOpen] = useState(false);
  const [counterfactualDialogOpen, setCounterfactualDialogOpen] =
    useState(false);

  const { data: sessions, isLoading: sessionLoading } = useGetSessions(
    params.id
  );

  const { data: expressionsData, isLoading: expressionsLoading } =
    useGetPopulationsofSession({ sessionId: selectedSession?.value });

  const targetLabel = selectedSession
    ? sessions.data.find((item) => item.id === selectedSession?.value).target
    : "";

  const {
    data: explanationsData,
    isLoading: explanationLoading,
    isError: explanationErr,
  } = useQuery({
    queryKey: [GET_EXPLANATIONS_QUERY_KEY, selectedSession?.value],
    queryFn: () =>
      ApiClient.get(
        `/api/${selectedSession.value}/explanations?type=counterfactuals`
      ),
    enabled: Boolean(selectedSession),
    retry: false,
    refetchInterval: SESSION_REQ_INTERVAL,
  });

  const handleOpenCounterfactualAnalyse = (explanation) => {
    setSelectedExplanation(explanation);
    setCounterfactualDialogOpen(true);
  };

  const handleDeleteExplanation = (e, explanation) => {
    e.stopPropagation();
    setSelectedExplanation(explanation);
    setDeleteExpDialogOpen(true);
  };

  return (
    <div className="project-tab" id="counterfactual-section">
      {sessionLoading ? (
        <div className="table-loader" />
      ) : (
        <>
          {!sessionLoading && sessions?.data.length === 0 && (
            <Grid>
              <Alert
                type="info"
                message="No session found. Please create a session first."
              />
            </Grid>
          )}
          {!sessionLoading && sessions?.data.length > 0 && !selectedSession && (
            <Grid>
              <Alert
                type="info"
                message="Select a session to see previous explanations."
              />
            </Grid>
          )}
          {explanationErr && selectedSession && !explanationLoading && (
            <Grid>
              <Alert
                type="error"
                message="Could not get explanations. Try later!"
              />
            </Grid>
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="form-item-label">
                Select session
              </Typography>
              <Dropdown
                value={selectedSession}
                onChange={setSelectedSession}
                isDisabled={sessionLoading}
                options={(sessions
                  ? sessions.data.length > 0
                    ? sessions.data
                    : []
                  : []
                ).map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                    dataset: item.dataset,
                  };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  id="session-create-button"
                  className="icon-button"
                  type="button"
                  onClick={() => setNewCounterfactualOpen(true)}
                >
                  <Plus size={18} color="#ffffff" weight="bold" />
                  <span>New counterfactual</span>
                </button>
              </Box>
            </Grid>
          </Grid>
          {explanationsData?.data && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3" className="section-title">
                  Previous Explanations
                </Typography>
                <div className="underline" />
              </Grid>
              {selectedSession && explanationsData?.data.length === 0 ? (
                <Grid item xs={12}>
                  <Alert
                    type="info"
                    message="No explanation found. Please run a counterfactual analyse."
                  />
                </Grid>
              ) : (
                explanationsData?.data.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <ExplanationCard
                      item={item}
                      onOpenCounterfactualAnalyse={
                        handleOpenCounterfactualAnalyse
                      }
                      onDeleteExplanation={handleDeleteExplanation}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </>
      )}

      {counterfactualDialogOpen &&
        explanationsData &&
        !explanationLoading &&
        selectedExplanation && (
          <CounterFactualDialog
            open={counterfactualDialogOpen}
            mode={DialogMode.UPDATE}
            sessionID={selectedSession.value}
            explanationID={selectedExplanation.id}
            selectedExpression={selectedExplanation.model}
            instance={selectedExplanation.configuration?.originalInstance || []}
            selectedInstanceIndex={
              selectedExplanation.configuration?.queryInstanceIdx || 0
            }
            allPopulations={expressionsData || []}
            existingFeatureVars={
              selectedExplanation.configuration?.featuresToVary || []
            }
            featureSet={selectedExplanation.configuration?.allFeatures || []}
            desiredOutcomeRange={
              selectedExplanation.configuration?.desiredOutcomeRange || []
            }
            targetLabel={targetLabel}
            onClose={() => setCounterfactualDialogOpen(false)}
          />
        )}

      {deleteExpDialogOpen && selectedExplanation && (
        <DeleteExplanationDialog
          open={deleteExpDialogOpen}
          explanation={selectedExplanation}
          sessionId={selectedSession.value}
          from={DeleteExplanationFrom.COUNTERFACTUAL}
          onClose={() => setDeleteExpDialogOpen(false)}
        />
      )}

      {newCounterfactualOpen && (
        <NewCounterfactualDialog
          open={newCounterfactualOpen}
          onClose={() => setNewCounterfactualOpen(false)}
        />
      )}
    </div>
  );
};

export default CounterFactual;
