import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Trash } from "phosphor-react";
import dateFormat from "dateformat";
import Status from "../common/Status";

const ExplanationCard = ({
  item,
  onOpenCounterfactualAnalyse,
  onDeleteExplanation,
}) => {
  const theme = useTheme();

  return (
    <Box
      id="explanation-card"
      onClick={() => onOpenCounterfactualAnalyse(item)}
    >
      <Box className="card-title-section">
        <Typography variant="h6" id="title">
          {item.id}
        </Typography>
        <IconButton onClick={(e) => onDeleteExplanation(e, item)}>
          <Trash color={theme.palette.error.main} />
        </IconButton>
      </Box>
      <Box className="info-container">
        <p className="info-item-title">Config: </p>
        <p>{item.sessionConfiguration?.name}</p>
      </Box>
      <Box className="info-container">
        <p className="info-item-title">Dataset: </p>
        <p>{item.dataset.name}</p>
      </Box>
      <Box className="info-container">
        <p className="info-item-title">Algorithm: </p>
        <p>{item.algorithm.name}</p>
      </Box>
      <Box className="info-container">
        <p className="info-item-title">Session: </p>
        <p>{item.projectSession.name}</p>
      </Box>
      <Box className="info-container">
        <p className="info-item-title">Created At: </p>
        <p style={{ fontSize: '.875rem' }}>{dateFormat(item.projectSession.created, "dd/mm/yyyy, hh:MM TT")}</p>
      </Box>
      <Box className="info-container">
        <p className="info-item-title">Updated At: </p>
        <p style={{ fontSize: '.875rem' }}>{dateFormat(item.projectSession.updated, "dd/mm/yyyy, hh:MM TT")}</p>
      </Box>
      <Box className="info-container">
        <p className="info-item-title">Status: </p>
        <Status status={item.state} />
      </Box>
    </Box>
  );
};

export default ExplanationCard;
