import { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import {
  useGetChildShapelyExplanation,
  useGetShapelyExplanationById,
  useGetShapelyExplanationLogs,
  useGetShapelyResult,
} from "src/hooks/feature-importance";
import { SessionStatus, ShapleyStatus } from "src/utils/types";
import { X } from "phosphor-react";
import MainAnalyseResults from "./MainAnalyseResults";
import PleaseWait from "src/components/common/PleaseWait";
import Status from "src/components/common/Status";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getErrorMsg } from "src/utils/Utils";
import ShapleyLogs from "./ShapleyLogs";
import { useGetSessions } from "src/hooks/sessions";
import { useParams } from "react-router-dom";
import ChildShapleyDetails from "./ChildShapleyDetails";

export const ShapleyDialogTabs = {
  RESULTS: "1",
  LOGS: "2",
};

const ShapleyAnalyseDialog = ({ id, sessionId, onClose }) => {
  const params = useParams();
  const [status, setStatus] = useState(null);
  const [childExpStatus, setChildExpStatus] = useState(null);
  const [tab, setTab] = useState(ShapleyDialogTabs.RESULTS);

  const { data: sessions, isLoading: sessionLoading } = useGetSessions(
    params.id
  );

  const session = useMemo(() => {
    return sessions?.data.find((item) => item.id == sessionId);
  }, [sessions]);

  const {
    data: explanation,
    isLoading: explanationLoading,
    error: explanationErr,
  } = useGetShapelyExplanationById(id, {
    enabled:
      Boolean(id) &&
      status !== ShapleyStatus.COMPLETED &&
      status !== ShapleyStatus.FAILED,
  });

  const {
    data: childExplanation,
    isLoading: childExplanationLoading,
    error: childExplanationErr,
    isFetched: childExplanationFetched,
  } = useGetChildShapelyExplanation(explanation?.childExplanation, {
    enabled:
      Boolean(explanation) &&
      Boolean(explanation.childExplanation) &&
      childExpStatus !== ShapleyStatus.COMPLETED,
  });

  const {
    data: shapleyResult,
    isLoading: shapleyResultLoading,
    error: shapleyResultErr,
  } = useGetShapelyResult(id, {
    enabled: status === ShapleyStatus.COMPLETED,
  });

  const {
    data: logs,
    isLoading: logsLoading,
    error: logsErr,
  } = useGetShapelyExplanationLogs(id);

  useEffect(() => {
    if (explanation) {
      setStatus(explanation.state);
    }
  }, [explanation]);

  useEffect(() => {
    if (childExplanation) {
      setChildExpStatus(childExplanation.state);
    }
  }, [childExplanation]);

  const handleFreezeStatus = () => {
    setStatus(null);
    setChildExpStatus(null);
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="customized-dialog-title"
      fullScreen
      disableEscapeKeyDown
      onClose={onClose}
    >
      <DialogTitle
        sx={{ display: "flex", alignItems: "center", gap: "1.5rem" }}
      >
        <Typography variant="h6" fontWeight={600}>
          Feature Importance Results
        </Typography>
        {!explanationLoading && <Status status={explanation?.state} />}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: ".5rem", right: "1rem" }}
      >
        <X />
      </IconButton>
      <DialogContent sx={{ paddingTop: "0rem" }}>
        <TabContext value={tab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: "1rem",
              position: "sticky",
              top: "0",
              background: "#ffff",
              zIndex: "99",
            }}
          >
            <TabList
              onChange={(_, val) => setTab(val)}
              aria-label="lab API tabs example"
            >
              <Tab label="Results" value={ShapleyDialogTabs.RESULTS} />
              <Tab label="Logs" value={ShapleyDialogTabs.LOGS} />
            </TabList>
          </Box>
          <TabPanel
            value={ShapleyDialogTabs.RESULTS}
            sx={{ padding: "1rem 0" }}
          >
            {explanationLoading ? (
              <PleaseWait />
            ) : explanationErr ? (
              <Alert severity="error">{getErrorMsg(explanationErr)}</Alert>
            ) : (
              <Stack gap={"2.5rem"}>
                <MainAnalyseResults
                  loading={shapleyResultLoading}
                  error={shapleyResultErr}
                  shapleyResults={shapleyResult || []}
                />
                {(explanation?.state === SessionStatus.COMPLETED ||
                  explanation?.state === SessionStatus.RUNNING) && (
                  <ChildShapleyDetails
                    session={session}
                    parentExplanation={explanation}
                    childExplanation={childExplanation}
                    childExplanationFetched={childExplanationFetched}
                    childExplanationLoading={childExplanationLoading}
                    childExplanationErr={childExplanationErr}
                    onChangeExplanationStatus={handleFreezeStatus}
                  />
                )}
              </Stack>
            )}
          </TabPanel>
          <TabPanel value={ShapleyDialogTabs.LOGS} sx={{ padding: "1rem 0" }}>
            {logsLoading ? (
              <PleaseWait />
            ) : logsErr ? (
              <Alert severity="error">{getErrorMsg(logsErr)}</Alert>
            ) : (
              <ShapleyLogs content={logs} />
            )}
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ height: "34px" }}
          size="small"
          color="error"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShapleyAnalyseDialog;
