import { Box, Grid, Typography } from "@mui/material";
import { Plus } from "phosphor-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Alert from "src/components/Alert";
import DeleteExplanationDialog, { DeleteExplanationFrom } from "src/components/counterfactual/DeleteExplanation";
import ExplanationCard from "src/components/counterfactual/ExplanationCard";
import Dropdown from "src/components/Dropdown";
import CreateNewShapleyDialog from "src/components/feature-importance/CreateNewShapleyDialog";
import ShapleyAnalyseDialog from "src/components/feature-importance/shapley-analyse-dialog/ShapleyAnalyseDialog";
import { useGetShapelyExplanations } from "src/hooks/feature-importance";
import { useGetSessions } from "src/hooks/sessions";

const FeatureImportance = () => {
  let params = useParams();
  const [selectedSession, setSelectedSession] = useState(null);
  const [newShapelyOpen, setNewShapelyOpen] = useState(false);
  const [selectedExplanation, setSelectedExplanation] = useState(null);
  const [deleteExpDialogOpen, setDeleteExpDialogOpen] = useState(false);
  const [shapelyDialogOpen, setShapelyDialogOpen] = useState(false);

  const { data: sessions, isLoading: sessionLoading } = useGetSessions(
    params.id
  );

  const {
    data: explanations,
    isLoading: explanationsLoading,
    error: explanationErr,
    isFetched: explanationFetched,
  } = useGetShapelyExplanations(selectedSession?.value);

  const handleOpenCounterfactualAnalyse = (explanation) => {
    setSelectedExplanation(explanation);
    setShapelyDialogOpen(true);
  };

  const handleDeleteExplanation = (e, explanation) => {
    e.stopPropagation();
    setSelectedExplanation(explanation);
    setDeleteExpDialogOpen(true);
  };

  return (
    <div className="project-tab" id="feature-importance-section">
      {sessionLoading ? (
        <div className="table-loader" />
      ) : (
        <>
          {!sessionLoading && sessions?.data.length === 0 && (
            <Grid>
              <Alert
                type="info"
                message="No session found. Please create a session first."
              />
            </Grid>
          )}
          {!sessionLoading && sessions?.data.length > 0 && !selectedSession && (
            <Grid>
              <Alert
                type="info"
                message="Select a session to see previous explanations."
              />
            </Grid>
          )}
          {explanationErr && selectedSession && !explanationsLoading && (
            <Grid>
              <Alert
                type="error"
                message="Could not get explanations. Try later!"
              />
            </Grid>
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="form-item-label">
                Select session
              </Typography>
              <Dropdown
                value={selectedSession}
                onChange={setSelectedSession}
                isDisabled={sessionLoading}
                options={(sessions
                  ? sessions.data.length > 0
                    ? sessions.data
                    : []
                  : []
                ).map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                    dataset: item.dataset,
                  };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  id="session-create-button"
                  className="icon-button"
                  type="button"
                  onClick={() => setNewShapelyOpen(true)}
                >
                  <Plus size={18} color="#ffffff" weight="bold" />
                  <span>New feature importance</span>
                </button>
              </Box>
            </Grid>
          </Grid>
          {explanationFetched && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3" className="section-title">
                  Previous Explanations
                </Typography>
                <div className="underline" />
              </Grid>
              {selectedSession && (explanations || []).length === 0 ? (
                <Grid item xs={12}>
                  <Alert
                    type="info"
                    message="No explanation found. Please run a feature importance analyse."
                  />
                </Grid>
              ) : (
                explanations.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <ExplanationCard
                      item={item}
                      onOpenCounterfactualAnalyse={
                        handleOpenCounterfactualAnalyse
                      }
                      onDeleteExplanation={handleDeleteExplanation}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </>
      )}

      {shapelyDialogOpen && selectedExplanation && (
        <ShapleyAnalyseDialog
          id={selectedExplanation.id}
          sessionId={selectedSession.value}
          onClose={() => setShapelyDialogOpen(false)}
        />
      )}

      {deleteExpDialogOpen && selectedExplanation && (
        <DeleteExplanationDialog
          open={deleteExpDialogOpen}
          explanation={selectedExplanation}
          sessionId={selectedSession.value}
          from={DeleteExplanationFrom.SHAPLEY}
          onClose={() => setDeleteExpDialogOpen(false)}
        />
      )}

      {newShapelyOpen && (
        <CreateNewShapleyDialog
          open={true}
          onClose={() => setNewShapelyOpen(false)}
        />
      )}
    </div>
  );
};

export default FeatureImportance;
