import { useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Box,
  Typography,
  LinearProgress,
  Checkbox,
  Select,
  MenuItem,
  Alert,
  Skeleton,
  TableSortLabel,
} from "@mui/material";
import { useParams } from "react-router";
import { TableVirtuoso } from "react-virtuoso";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "../common/TableItems";
import CounterFactualDialog from "./CounterfactualDialog";
import { useGetPopulationsofSession, useGetSessions } from "src/hooks/sessions";
import { useGetDatasetData } from "src/hooks/datasets";
import { DialogMode, AlgorithmTypes, SortDirection } from "src/utils/types";
import { genericSort } from "src/utils/Utils";

const NewCounterfactualDialog = ({ open, onClose }) => {
  const params = useParams();
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedInstanceIndex, setSelectedInstanceIndex] = useState(null);
  const [selectedExpression, setSelectedExpression] = useState(null);
  const [disabledFeatures, setDisabledFeatures] = useState([]);
  const [analyseDialogOpen, setAnalyseDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("fitness");
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);

  const { data: sessions, isLoading: sessionLoading } = useGetSessions(
    params.id
  );

  const selectedSessionInfo = sessions?.data.find(
    (item) => item.id === selectedSession
  );

  const { data: allPopulationsIndividuals, isFetching: populationsFetching } =
    useGetPopulationsofSession({ sessionId: selectedSession });

  const { data: datasetData, isFetching: dataFetching } = useGetDatasetData({
    projectId: params.id,
    datasetId: selectedSessionInfo?.dataset.id,
    delimiter: null,
    fileName: selectedSessionInfo?.dataset.name,
  });

  const selectedInstanceInfo =
    datasetData && selectedInstanceIndex !== null
      ? datasetData.data.rows[selectedInstanceIndex]
      : [];

  const targetLabel = selectedSession
    ? sessions.data.find((item) => item.id === selectedSession).target
    : "";

  const allPopulations = useMemo(() => {
    if ((allPopulationsIndividuals || []).length === 0) {
      return [];
    } else {
      let solutionsList = [];
      for (const population in allPopulationsIndividuals) {
        solutionsList = solutionsList.concat(
          allPopulationsIndividuals[population]
        );
      }

      const sortedList = genericSort(solutionsList, sortField, sortDirection);
      return sortedList;
    }
  }, [allPopulationsIndividuals, sortDirection, sortField]);

  const existingInstanceVars = useMemo(() => {
    if (!datasetData) {
      return [];
    } else {
      const disabledFeaturesSet = new Set([...disabledFeatures]);
      return datasetData.data.header.filter(
        (item, index) => !disabledFeaturesSet.has(index)
      );
    }
  }, [disabledFeatures, datasetData]);

  const handleSelectExpression = (populationItem) => {
    if (selectedExpression?.id === populationItem.id) {
      setSelectedExpression(null);
      return;
    }

    setSelectedExpression({
      id: populationItem.id,
      model: populationItem.model,
    });
  };

  const handleSelectInstance = (index) => {
    setSelectedInstanceIndex(index);
  };

  const handleToogleDisabledFeature = (headerItem) => {
    if (disabledFeatures.includes(headerItem)) {
      const features = disabledFeatures.filter((item) => item !== headerItem);
      setDisabledFeatures(features);
      return;
    }

    setDisabledFeatures([...disabledFeatures, headerItem]);
  };

  const handleChangeSort = (field) => {
    const previousField = sortField;

    if (previousField === field) {
      setSortDirection(
        sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC
      );
    } else {
      setSortField(field);
    }
  };

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={onClose} fullScreen>
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        Create Counterfactual
      </DialogTitle>
      {sessionLoading && <LinearProgress />}
      <DialogContent dividers>
        <Grid container justifyContent="center">
          <Grid item xs={11} md={10}>
            <Grid container>
              {!sessionLoading && sessions?.data.length === 0 && (
                <Grid item xs={12} mb={2}>
                  <Alert severity="info">
                    No session found. Please create a session first.
                  </Alert>
                </Grid>
              )}
              {!sessionLoading &&
                sessions?.data.length > 0 &&
                sessions?.data.filter(
                  (item) => item.algorithm.type === AlgorithmTypes.PREDICTIVE
                ).length === 0 && (
                  <Grid item xs={12} mb={2}>
                    <Alert severity="warning">
                      Counterfactual analyses only work with predictive
                      algorithms. You need to create and run session with
                      predictive type algorithms.
                    </Alert>
                  </Grid>
                )}
              {!sessionLoading &&
                sessions?.data.length > 0 &&
                sessions?.data.filter(
                  (item) => item.algorithm.type === AlgorithmTypes.PREDICTIVE
                ).length > 0 &&
                !selectedSession && (
                  <Grid item xs={12} mb={2}>
                    <Alert severity="info">
                      Select a session to see previous explanations.
                    </Alert>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container mt={2} justifyContent="center">
          <Grid item xs={11} md={10}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: ".5rem",
                  }}
                >
                  Select session
                </Typography>
                <Select
                  fullWidth
                  value={selectedSession}
                  onChange={(e) => setSelectedSession(e.target.value)}
                  disabled={sessionLoading}
                >
                  {(sessions ? sessions.data : [])
                    .filter(
                      (item) =>
                        item.algorithm.type === AlgorithmTypes.PREDICTIVE
                    )
                    .map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={8}>
                {targetLabel && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        marginBottom: ".5rem",
                        fontFamily: "Titillium Web",
                      }}
                    >
                      Target Label:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        marginBottom: ".5rem",
                        fontFamily: "Titillium Web",
                      }}
                    >
                      {targetLabel}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={11} md={10} sx={{ mt: "2rem" }}>
            {selectedSession && (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: ".5rem",
                      fontFamily: "Titillium Web",
                    }}
                  >
                    Select an instance and uncheck disabled features
                  </Typography>
                  {dataFetching ? (
                    <>
                      {new Array(5).fill().map(() => (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={30}
                          style={{ marginBottom: ".25rem" }}
                        />
                      ))}
                    </>
                  ) : datasetData ? (
                    <TableVirtuoso
                      style={{ height: "400px" }}
                      data={datasetData.data.rows}
                      components={VirtualizedTableComponents}
                      fixedHeaderContent={() => (
                        <StyledTableRow>
                          <StyledTableCell
                            padding="checkbox"
                            key={"empty-checkbox-area"}
                          ></StyledTableCell>
                          {datasetData.data.header.map((item, index) => (
                            <StyledTableCell align="left" key={index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                {item !== targetLabel && (
                                  <Checkbox
                                    color="primary"
                                    checked={!disabledFeatures.includes(index)}
                                    onChange={() =>
                                      handleToogleDisabledFeature(index)
                                    }
                                    sx={{
                                      width: "24px",
                                      height: "24px",
                                      // color: "black",
                                    }}
                                  />
                                )}
                                {item}
                              </Box>
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      )}
                      itemContent={(index, row) => (
                        <>
                          <StyledTableCell
                            padding="checkbox"
                            key={index + "checkbox"}
                          >
                            <Checkbox
                              color="primary"
                              checked={selectedInstanceIndex === index}
                              onChange={() => handleSelectInstance(index)}
                              sx={{ width: "24px", height: "24px" }}
                            />
                          </StyledTableCell>
                          {row.map((item, dataIndex) => (
                            <StyledTableCell align="left" key={dataIndex}>
                              {item}
                            </StyledTableCell>
                          ))}
                        </>
                      )}
                    />
                  ) : (
                    <Grid>
                      <Alert severity="info">Could not find dataset.</Alert>
                    </Grid>
                  )}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    mt: "2rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: ".5rem",
                      fontFamily: "Titillium Web",
                    }}
                  >
                    Select expression
                  </Typography>
                  {populationsFetching ? (
                    <>
                      {new Array(5).fill().map(() => (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={30}
                          style={{ marginBottom: ".25rem" }}
                        />
                      ))}
                    </>
                  ) : (allPopulationsIndividuals || []).length === 0 ? (
                    <Grid>
                      <Alert severity="info">
                        No expression found. Please create or run a session.
                      </Alert>
                    </Grid>
                  ) : (
                    <TableVirtuoso
                      style={{ height: "400px" }}
                      data={allPopulations}
                      components={VirtualizedTableComponents}
                      fixedHeaderContent={() => (
                        <StyledTableRow>
                          <StyledTableCell align="left">#</StyledTableCell>
                          <StyledTableCell align="left">
                            Expression
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            key={"fitness"}
                            sortDirection={
                              sortField === "fitness" ? sortDirection : false
                            }
                          >
                            <TableSortLabel
                              active={sortField === "fitness"}
                              direction={
                                sortField === "fitness"
                                  ? sortDirection
                                  : SortDirection.ASC
                              }
                              onClick={() => handleChangeSort("fitness")}
                            >
                              Fitness
                            </TableSortLabel>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            key={"size"}
                            sortDirection={
                              sortField === "size" ? sortDirection : false
                            }
                          >
                            <TableSortLabel
                              active={sortField === "size"}
                              direction={
                                sortField === "size"
                                  ? sortDirection
                                  : SortDirection.ASC
                              }
                              onClick={() => handleChangeSort("size")}
                            >
                              Size
                            </TableSortLabel>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                      itemContent={(index, solution) => (
                        <>
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={
                                selectedExpression
                                  ? solution.id === selectedExpression.id
                                    ? true
                                    : false
                                  : false
                              }
                              onChange={() => handleSelectExpression(solution)}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {solution.model}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {solution.fitness}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {solution.size}
                          </StyledTableCell>
                        </>
                      )}
                    />
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        {analyseDialogOpen &&
          selectedInstanceIndex !== null &&
          selectedExpression && (
            <CounterFactualDialog
              open={analyseDialogOpen}
              mode={DialogMode.CREATE}
              sessionID={selectedSession}
              selectedExpression={selectedExpression.model}
              instance={selectedInstanceInfo}
              selectedInstanceIndex={selectedInstanceIndex}
              allPopulations={allPopulations || []}
              existingFeatureVars={existingInstanceVars}
              featureSet={datasetData.data.header}
              targetLabel={targetLabel}
              onClose={() => setAnalyseDialogOpen(false)}
            />
          )}
      </DialogContent>
      <DialogActions sx={{ paddingX: "1rem" }}>
        <Button
          sx={{ height: "34px" }}
          color="error"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          size="small"
          disabled={!selectedExpression || selectedInstanceIndex === null}
          sx={{ height: "34px" }}
          onClick={() => setAnalyseDialogOpen(true)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewCounterfactualDialog;
