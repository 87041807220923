import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useGetAlgorithms } from "../hooks/algorithms";
import AlgorithmListTable from "src/components/overview/AlgorithmListTable";
import CreateAlgoritmDialog from "src/components/overview/CreateAlgoritmDialog";
import { DialogMode } from "src/utils/types";
import { UploadSimple } from "phosphor-react";
import AlgorithmDetails from "src/components/overview/AlgorithmDetails";
import ScrollToTop from "src/components/common/ScrollToTop";

function Overview() {
  const { data: algorithmsData } = useGetAlgorithms();
  const [selectedAlgorithm, setSelectedAlgorithm] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const hasAlgorithmData =
    algorithmsData?.data && algorithmsData?.data.length > 0;

  useEffect(() => {
    if (hasAlgorithmData) {
      if (selectedAlgorithm) {
        const updated = algorithmsData.data.find(
          (item) => item.id === selectedAlgorithm.id,
        );
        setSelectedAlgorithm(updated);
        return;
      }
      setSelectedAlgorithm(algorithmsData.data[0]);
    }
  }, [algorithmsData]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "1rem",
        }}
      >
        <Box
          id="overview-left-panel"
          sx={{
            width: "20rem",
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            gap: ".75rem",
          }}
        >
          <button
            id="upload-algorithm-button"
            className="icon-button submit-button"
            type="button"
            onClick={() => setUploadDialogOpen(true)}
          >
            <UploadSimple size={18} color="#ffffff" weight="bold" />
            <span>Upload new</span>
          </button>
          <AlgorithmListTable
            selectedAlgorithm={selectedAlgorithm}
            algorithmsData={algorithmsData}
            setSelectedAlgorithm={setSelectedAlgorithm}
          />
        </Box>
        <Box
          id="overview-right-panel"
          sx={{
            flex: "1",
            overflow: "hidden",
          }}
        >
          {selectedAlgorithm && (
            <AlgorithmDetails algorithm={selectedAlgorithm} />
          )}
        </Box>
      </Box>
      {uploadDialogOpen && selectedAlgorithm && (
        <CreateAlgoritmDialog
          open={uploadDialogOpen}
          mode={DialogMode.CREATE}
          onClose={() => setUploadDialogOpen(false)}
        />
      )}
      <ScrollToTop />
    </div>
  );
}

export default Overview;
