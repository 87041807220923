import axios from "axios";
import { TRUST_USER_LOCAL_KEY } from "./utils/types";

console.log("URL: ", process.env.REACT_APP_TRUST_URL);
const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_TRUST_URL,
  headers: {
    "Content-Type": "application/json",
    "X-XSS-Protection": "1",
    "X-Content-Type-Options": "nosniff",
    // "Host": "https://trustai.tazi.ai"
  },
});

ApiClient.interceptors.request.use(
  (config) => {
    const clientInfo = window.localStorage.getItem(TRUST_USER_LOCAL_KEY);
    if (clientInfo) {
      config.headers["Authorization"] = `Bearer ${JSON.parse(clientInfo).jwt}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);

export default ApiClient;
