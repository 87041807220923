import { useState, useEffect, useMemo, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetDatasetData, useGetDatasets } from "../../hooks/datasets";
import { useGetAlgorithms } from "../../hooks/algorithms";
import { useGetConfigs } from "../../hooks/configurations";
import useNotifier, { NotificationType } from "../../hooks/use-notify";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  Alert,
  TextField,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  useTheme,
  InputAdornment,
  Stack,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import {
  AlgorithmTypes,
  DialogMode,
  GET_SESSIONS_QUERY_KEY,
  SessionTypes,
} from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";
import ExpressionsTable from "./ExpressionsTable";
import AlgorithmBasedExpressionsDialog from "./select-algo-base-expression/AlgorithmBasedExpressionsDialog";
import { useUpdateSession } from "src/hooks/sessions";

function CreateSessionModal({
  open,
  mode = DialogMode.CREATE,
  existingSession,
  onClose,
}) {
  const theme = useTheme();
  let params = useParams();
  let navigate = useNavigate();
  const { notify } = useNotifier();

  const [error, setError] = useState("");
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [configurations, setConfigurations] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState(null);
  const [selectedConfig, setSelectedConfiguration] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [targetLabel, setTargetLabel] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [selectedPopulations, setSelectedPopulations] = useState([]);
  const [addExpressionDialogOpen, setAddExpressionDialogOpen] = useState(false);

  const { data: configurationData } = useGetConfigs(params.id);
  const { data: datasetsData, status: datasetsStatus } = useGetDatasets(
    params.id,
  );
  const { data: algorithmsData, status: algorithmsStatus } = useGetAlgorithms();

  const selectedDatasetInfo = useMemo(() => {
    return selectedDataset
      ? datasetsData?.data.find((item) => item.id === selectedDataset)
      : { id: "", name: "" };
  }, [selectedDataset]);

  const { data: datasetData, isLoading: datasetLoading } = useGetDatasetData({
    projectId: params.id,
    datasetId: selectedDatasetInfo?.id,
    delimiter: ",",
    fileName: selectedDatasetInfo?.name,
  });

  const selectedAlgorithmType = useMemo(() => {
    return selectedAlgorithm
      ? (algorithmsData?.data || []).find(
          (item) => item.id === selectedAlgorithm,
        ).type
      : "";
  }, [selectedAlgorithm]);

  useEffect(() => {
    if (mode === DialogMode.UPDATE && existingSession) {
      setName(existingSession.name);
      setSelectedDataset(existingSession.dataset?.id);
      setSelectedAlgorithm(existingSession.algorithm.id);
      setSelectedType(existingSession.properties.commandType);
      setTargetLabel(existingSession.target);
      setSelectedPopulations(existingSession.customData || []);
      setSelectedConfiguration(existingSession.configuration?.id);
    }
  }, []);

  useEffect(() => {
    if (configurationData?.data && selectedAlgorithm && selectedType) {
      let projectConfigurations = configurationData.data.filter(
        (item) =>
          item.algorithm.id === selectedAlgorithm &&
          item.commandType === selectedType,
      );
      setConfigurations(projectConfigurations);
    }
  }, [configurationData, selectedAlgorithm, selectedType]);

  const { isLoading: sessionLoading } = useQuery({
    queryKey: [GET_SESSIONS_QUERY_KEY, selectedAlgorithm],
    queryFn: () => ApiClient.get(`/api/projects/${params.id}/sessions`),
    enabled: selectedType === SessionTypes.TEST && Boolean(selectedAlgorithm),
    onSuccess: (res) => {
      setSessions(
        res.data.filter(
          (item) =>
            item.algorithm?.id === selectedAlgorithm &&
            item.properties?.commandType === SessionTypes.TRAIN,
        ),
      );
    },
    onError: (err) => {
      setError(getErrorMsg(err));
    },
  });

  const { mutateAsync: updateSessionMutation } = useUpdateSession();

  const { mutate: createSessionMutation } = useMutation({
    mutationFn: (createSessionBody) =>
      ApiClient.post(`/api/projects/${params.id}/sessions`, createSessionBody),
    onSuccess: (res) => {
      setError("");
      setFormError("");
      notify(NotificationType.SUCCESS, "Session created.");
      navigate(`/project/${params.id}/sessions/${res.data.id}`);
    },
    onError: (err) => {
      setError(getErrorMsg(err));
    },
    onSettled: () => {
      setLoading(false);
      setFormError("");
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) {
      setFormError("Enter a session name");
      return;
    }

    if (!selectedConfig && selectedType == SessionTypes.TRAIN) {
      setFormError("Select a configuration");
      return;
    }

    if (!targetLabel && selectedAlgorithmType === AlgorithmTypes.PREDICTIVE) {
      setFormError("Select a target label");
      return;
    }

    if (!selectedAlgorithm) {
      setFormError("Select an algorithm");
      return;
    }

    if (!selectedType) {
      setFormError("Select a session type");
      return;
    }

    if (
      selectedType === SessionTypes.TEST &&
      selectedPopulations.length === 0
    ) {
      setFormError("Select expressions to train session");
      return;
    }

    if (
      !selectedConfig &&
      selectedAlgorithmType === AlgorithmTypes.PREDICTIVE &&
      selectedType !== SessionTypes.TEST
    ) {
      setFormError("Select configuration");
      return;
    }

    setLoading(true);

    let createSessionBody = {
      name,
      algorithm: selectedAlgorithm,
      commandType: selectedType,
      target: targetLabel,
    };

    if (selectedAlgorithmType === AlgorithmTypes.PREDICTIVE) {
      createSessionBody.dataset = selectedDataset;
      createSessionBody.headers = datasetData?.data.header || [];
    }

    if (selectedType === SessionTypes.TEST) {
      createSessionBody.expressions = selectedPopulations.map(
        (item) => item.model,
      );
      createSessionBody.customData = selectedPopulations;
    } else {
      createSessionBody.configuration = selectedConfig;
    }

    if (mode === DialogMode.UPDATE) {
      updateSessionMutation({
        name: createSessionBody.name,
        dataset: createSessionBody.dataset,
        headers: createSessionBody.headers,
        configuration: createSessionBody.configuration,
        sessionId: existingSession.id,
        expressions: createSessionBody.expressions,
        customData: createSessionBody.customData,
      })
        .then(() => {
          notify(NotificationType.SUCCESS, "Session updated.");
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          notify(NotificationType.ERROR, getErrorMsg(err));
        });
    } else {
      createSessionMutation(createSessionBody);
    }
  };

  const handleDeleteFromSelectedPopulations = (id) => {
    setSelectedPopulations(
      [...selectedPopulations].filter((item) => item.id !== id),
    );
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
          {`${mode === DialogMode.UPDATE ? "Update" : "Create"} Session`}
        </DialogTitle>
        <DialogContent dividers>
          <>
            {error && (
              <Alert sx={{ mb: "8px" }} severity="error">
                {error}
              </Alert>
            )}
            {selectedType === SessionTypes.TEST &&
              sessions.length === 0 &&
              !sessionLoading && (
                <Alert sx={{ mb: "8px" }} severity="warning">
                  In order to create test session, first you need to create and
                  run a train session with the selected algorithm.
                </Alert>
              )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <label htmlFor="session-name" className="form-item-label">
                  Name
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Session Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label htmlFor="session-algorithm" className="form-item-label">
                  Algorithm
                </label>
                <Select
                  fullWidth
                  value={selectedAlgorithm}
                  onChange={(e) => {
                    setSelectedAlgorithm(e.target.value);
                  }}
                  disabled={
                    algorithmsStatus !== "success" ||
                    algorithmsData === undefined ||
                    mode === DialogMode.UPDATE
                  }
                >
                  {(algorithmsData?.data || []).map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label htmlFor="session-algorithm" className="form-item-label">
                  Type
                </label>
                <Select
                  fullWidth
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  disabled={mode === DialogMode.UPDATE}
                >
                  {[SessionTypes.TRAIN, SessionTypes.TEST].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {selectedType && selectedType !== SessionTypes.TEST && (
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="session-configuration"
                    className="form-item-label"
                  >
                    Configuration
                  </label>
                  <Select
                    fullWidth
                    value={selectedConfig}
                    onChange={(e) => setSelectedConfiguration(e.target.value)}
                    disabled={!selectedAlgorithm && !selectedType}
                  >
                    {configurations.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              {selectedAlgorithmType === AlgorithmTypes.PREDICTIVE && (
                <Grid item xs={12} sm={6}>
                  <label htmlFor="session-dataset" className="form-item-label">
                    Dataset
                  </label>
                  <Select
                    fullWidth
                    value={selectedDataset}
                    onChange={(e) => setSelectedDataset(e.target.value)}
                    disabled={
                      datasetsStatus !== "success" || datasetsData === undefined
                    }
                  >
                    {(datasetsData?.data || []).map((item, index) => (
                      <MenuItem key={`${item}-${index}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              {selectedAlgorithmType === AlgorithmTypes.PREDICTIVE && (
                <Grid item xs={12} sm={6}>
                  <label
                    htmlFor="session-target-label"
                    className="form-item-label"
                  >
                    Target label
                  </label>
                  <TextField
                    select
                    fullWidth
                    value={targetLabel}
                    onChange={(e) => setTargetLabel(e.target.value)}
                    disabled={!datasetData}
                    InputProps={{
                      endAdornment: datasetLoading ? (
                        <InputAdornment
                          position="end"
                          sx={{ marginRight: "1.5rem" }}
                        >
                          <CircularProgress size={16} thickness={5} />
                        </InputAdornment>
                      ) : undefined,
                    }}
                  >
                    {(datasetData?.data.header || []).map((item, index) => (
                      <MenuItem key={`${item}-${index}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              {selectedType === SessionTypes.TEST && (
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={"1.25rem"}
                    mb={".5rem"}
                  >
                    <label
                      htmlFor="select-expressions"
                      className="form-item-label"
                    >
                      Selected Expressions
                    </label>
                    <Button
                      variant="outlined"
                      sx={{ height: "33px" }}
                      onClick={() => setAddExpressionDialogOpen(true)}
                    >
                      Add Expressions
                    </Button>
                  </Stack>
                  <ExpressionsTable
                    selectedPopulations={selectedPopulations}
                    onDelete={handleDeleteFromSelectedPopulations}
                  />
                </Grid>
              )}
              {formError && (
                <Grid item xs={12} mb={2}>
                  <span className="form-error">{formError}</span>
                </Grid>
              )}
            </Grid>
          </>
        </DialogContent>
        <DialogActions sx={{ paddingX: "1.25rem", paddingY: ".75rem" }}>
          <Button
            color={"error"}
            disabled={loading}
            onClick={onClose}
            sx={{ marginRight: ".25rem" }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={handleSubmit}
            startIcon={
              loading ? (
                <CircularProgress
                  size={16}
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              ) : undefined
            }
          >
            {mode === DialogMode.UPDATE ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>

      {addExpressionDialogOpen && (
        <AlgorithmBasedExpressionsDialog
          open={true}
          selectableSessions={sessions}
          selectedPopulations={selectedPopulations}
          algorithm={selectedAlgorithm}
          onSave={setSelectedPopulations}
          onClose={() => setAddExpressionDialogOpen(false)}
        />
      )}
    </Fragment>
  );
}

export default CreateSessionModal;
