import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {
  CircularProgress,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useNotifier, { NotificationType } from "src/hooks/use-notify";
import ApiClient from "src/axios";
import { getErrorMsg } from "src/utils/Utils";

export default function DeleteDatasetDialog({
  dataset,
  open,
  onClose,
  onDelete,
}) {
  const theme = useTheme();
  const { notify } = useNotifier();
  const { id: projectId } = useParams();
  const queryClient = useQueryClient();

  const { mutate: deleteDatasetMutation, isLoading } = useMutation({
    mutationFn: () =>
      ApiClient.delete(`/api/projects/${projectId}/datasets/${dataset.id}`),
    onSuccess: () => {
      onDelete();
      queryClient.invalidateQueries(["datasets"]);
      notify(NotificationType.SUCCESS, "Dataset deleted");
      onClose();
    },
    onError: (err) => {
      notify(NotificationType.ERROR, getErrorMsg(err));
    },
  });

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
          {`Delete Dataset`}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete the dataset{" "}
          <b>
            <i>{dataset.name}</i>
          </b>
          {" ?"}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            startIcon={
              isLoading ? (
                <CircularProgress
                  size={16}
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              ) : undefined
            }
            onClick={deleteDatasetMutation}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
