import { useQuery, useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";

export const useGetProjects = () =>
  useQuery("projects", () => {
    return ApiClient.get("/api/projects");
  });

export const useGetProject = (projectId) =>
  useQuery(["projects", projectId], () => {
    return ApiClient.get(`/api/projects/${projectId}`);
  });

export const useCreateProject = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, type }) =>
      ApiClient.post("/api/projects", {
        name,
        type,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("projects");
      },
    },
  );
};
