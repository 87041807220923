import React from "react";
import { useNavigate } from "react-router-dom";
import { SignOut } from "phosphor-react";
import { useAuth } from "../hooks/use-auth";
import logo from "../assets/TRUSTAI-logo.png";

function Navbar() {
  let navigate = useNavigate();
  let auth = useAuth();

  return (
    <div id="navbar">
      <img
        onClick={() => {
          navigate("/projects");
        }}
        id="navbar-logo"
        src={logo}
        alt="TRUSTAI Logo"
        style={{ cursor: "pointer" }}
      />
      <ul id="navbar-items">
        <li
          onClick={() => {
            navigate("/projects");
          }}
        >
          Projects
        </li>
        {auth?.user?.isAdmin && (
          <li
            onClick={() => {
              navigate("/users");
            }}
          >
            Users
          </li>
        )}
        <li id="signout-container">
          <SignOut
            onClick={() => {
              auth.signout();
              navigate("/login");
            }}
            size={20}
            weight="bold"
          />
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
