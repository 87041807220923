import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Tab } from "@mui/material";
import {
  useGetAlgorithmBookmarks,
  useGetSessionBookmarks,
  useGetSessions,
} from "../hooks/sessions";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BookmarkTabs } from "src/components/bookmark/utils";
import { useGetAlgorithms } from "src/hooks/algorithms";
import SessionBookmarksPanel from "src/components/bookmark/SessionBookmarksPanel";
import AlgorithmBookmarksPanel from "src/components/bookmark/AlgorithmBookmarksPanel";
import { SortDirection } from "src/utils/types";
import { genericSort } from "src/utils/Utils";

function Bookmarked() {
  let params = useParams();

  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState(null);
  const [existingBookmarks, setExistingBookmarks] = useState([]);
  const [tab, setTab] = useState(BookmarkTabs.SESSION);
  const [sortField, setSortField] = useState("fitness");
  const [sortDirection, setSortDirection] = useState(SortDirection.DESC);

  const { data: sessionBookmarks, isLoading: sessionBookmarksLoading } =
    useGetSessionBookmarks(selectedSession?.value);

  const { data: algorithmBookmarks, isLoading: algorithmBookmarksLoading } =
    useGetAlgorithmBookmarks(params.id, selectedAlgorithm?.value);

  const { data: sessions, isLoading: sessionLoading } = useGetSessions(
    params.id,
  );

  const { data: algorithms, isLoading: algorithmsloading } = useGetAlgorithms();

  useEffect(() => {
    if (tab === BookmarkTabs.SESSION) {
      const sorted = genericSort(
        sessionBookmarks?.data || [],
        sortField,
        sortDirection,
      );
      setExistingBookmarks(sorted);
    }
    if (tab === BookmarkTabs.ALGORITHM) {
      const sorted = genericSort(
        algorithmBookmarks?.data || [],
        sortField,
        sortDirection,
      );
      setExistingBookmarks(sorted);
    }
  }, [sessionBookmarks, algorithmBookmarks, tab, sortDirection, sortField]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeSort = (field) => {
    const previousField = sortField;

    if (previousField === field) {
      setSortDirection(
        sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC,
      );
    } else {
      setSortField(field);
    }
  };

  return (
    <div className="project-tab" id="bookmarkeds-section">
      <section id="select-session">
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChangeTab}>
              <Tab label="SESSION" value={BookmarkTabs.SESSION} />
              <Tab label="ALGORITHM" value={BookmarkTabs.ALGORITHM} />
            </TabList>
          </Box>

          <TabPanel value={BookmarkTabs.SESSION} sx={{ padding: "1rem 0" }}>
            <SessionBookmarksPanel
              sessions={sessions}
              sessionLoading={sessionLoading}
              selectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
              sessionBookmarksLoading={sessionBookmarksLoading}
              existingBookmarks={existingBookmarks}
              setExistingBookmarks={setExistingBookmarks}
              sortField={sortField}
              sortDirection={sortDirection}
              onChangeSort={handleChangeSort}
            />
          </TabPanel>

          <TabPanel value={BookmarkTabs.ALGORITHM} sx={{ padding: "1rem 0" }}>
            <AlgorithmBookmarksPanel
              existingBookmarks={existingBookmarks}
              setExistingBookmarks={setExistingBookmarks}
              algorithmsloading={algorithmsloading}
              algorithms={algorithms}
              selectedAlgorithm={selectedAlgorithm}
              setSelectedAlgorithm={setSelectedAlgorithm}
              algorithmBookmarksLoading={algorithmBookmarksLoading}
              sortField={sortField}
              sortDirection={sortDirection}
              onChangeSort={handleChangeSort}
            />
          </TabPanel>
        </TabContext>
      </section>
    </div>
  );
}

export default Bookmarked;
