import React, { useMemo, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import { Alert, Stack, Typography } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MAX_SOLUTION_SIZE_FOR_BAR_CHART = 35; // ask to luis why

function ComparisonHistogram() {
  const chartRef = useRef();
  const { filteredSolutions } = useMultiTreeSession();

  const partialSolutions = useMemo(() => {
    return filteredSolutions
      ? filteredSolutions.slice(
          0,
          Math.min(filteredSolutions.length, MAX_SOLUTION_SIZE_FOR_BAR_CHART)
        )
      : [];
  }, [filteredSolutions]);

  const otherFields = useMemo(() => {
    let fields = new Set();
    partialSolutions.forEach((item) => {
      if (item.modelOther) {
        Object.keys(item.modelOther).forEach((key) => {
          fields.add(key);
        });
      }
    });

    return Array.from(fields);
  }, [partialSolutions]);

  const labels =
    partialSolutions.length > 0 ? ["size", "fitness", ...otherFields] : [];

  const [selectedMetrics, setSelectedMetrics] = useState(
    new Set(["size", "fitness"])
  );

  const modelData = useMemo(() => {
    let data = [];
    let modelIds = new Set();
    partialSolutions.forEach((item) => {
      if (!modelIds.has(item.individual)) {
        modelIds.add(item.individual);
        data.push({
          generation: item.generation,
          id: item.individual,
          size: item.modelSize,
          fitness: item.modelFitness,
          ...(item.modelOther || {}),
        });
      }
    });
    return data;
  }, [partialSolutions]);

  if (!partialSolutions.length > 0)
    return <div className="tile-no-solutions">No solutions found</div>;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
      },
    },
  };

  const colors = [
    "#0101ff",
    "#2ee09a",
    "#ff684d",
    "#E8E288",
    "#7DCE82",
    "#3CDBD3",
    "#3CDBD3",
    "#E3D8F1",
    "#726DA8",
    "#A0D2DB",
  ];

  const data = {
    labels: [...selectedMetrics],
    datasets: modelData.map((model, index) => {
      return {
        label: `G${model.generation}:M${model.id}`,
        data: [...selectedMetrics].map((label) =>
          model.hasOwnProperty(label) ? model[label] : undefined
        ),
        backgroundColor: colors[index % colors.length],
      };
    }),
  };

  function toggleMetric(metric) {
    selectedMetrics.has(metric)
      ? setSelectedMetrics(
          (prev) => new Set([...prev].filter((label) => label !== metric))
        )
      : setSelectedMetrics((prev) => new Set(prev.add(metric)));
  }

  return (
    <div id="bar-chart-container">
      <div id="metrics-checkboxes">
        {labels.map((label, index) => (
          <div
            className="checkbox-container"
            key={index}
            onClick={() => toggleMetric(label)}
          >
            <span className="label">{label}</span>
            <span
              className={
                selectedMetrics.has(label) ? "selected checkbox" : "checkbox"
              }
            ></span>
          </div>
        ))}
      </div>
      <Bar id="bar-chart" ref={chartRef} options={options} data={data} />
      <Alert severity="info" sx={{ margin: "1rem" }}>
        <Stack direction={"row"} gap={"1rem"} >
          <Typography variant="caption">
            <b>G: </b>Generation
          </Typography>
          <Typography variant="caption">
            <b>M: </b>Model
          </Typography>
        </Stack>
        <Typography variant='body2'>Above barchart shows only models data.</Typography>
      </Alert>
    </div>
  );
}

export default ComparisonHistogram;
