import { useState, useRef, useMemo } from "react";
import TreeVisualizer from "./TreeVisualizer";
import ExpressisonVisualizer from "./ExpressisonVisualizer";
import MetricsVisualizer from "./MetricsVisualizer";
import MosaicPanel from "../../common/MosaicPanel";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";

const FIRST_MODEL_INDEX = 1;

function Evaluation() {
  const { filteredSolutions } = useMultiTreeSession();
  const fullscreenRef = useRef(null);

  const [parsedExpTree, setParsedExpTree] = useState(null);
  const [parsingError, setParsingError] = useState(null);
  const [generationIndex, setGenerationIndex] = useState(0);
  const [modelIndex, setModelIndex] = useState(FIRST_MODEL_INDEX);
  const [selectedTreeItem, setSelectedTreeItem] = useState(null);

  const [tilesLayout, setTilesLayout] = useState({
    currentNode: {
      direction: "row",
      first: {
        direction: "column",
        first: "expression",
        second: "metrics",
        splitPercentage: 55,
      },
      second: "tree",
      splitPercentage: 40,
    },
    windowsCount: 3,
    closedWindows: ["new", "bar chart"],
  });

  const treeItemsByModel = useMemo(() => {
    if (!filteredSolutions) {
      return;
    }
    const trees = filteredSolutions.filter(
      (item) =>
        String(item.generation) == String(generationIndex) &&
        String(item.individual) == String(modelIndex)
    );
    if (trees.length > 0) {
      setSelectedTreeItem(trees[0]);
    }
    return trees || [];
  }, [modelIndex, generationIndex, filteredSolutions]);

  const metrics = useMemo(() => {
    if (selectedTreeItem) {
      return {
        tree: {
          fitness: selectedTreeItem.fitness,
          size: selectedTreeItem.size,
        },
        model: {
          fitness: selectedTreeItem.modelFitness,
          size: selectedTreeItem.modelSize,
          ...selectedTreeItem.modelOther,
        },
      };
    }
    return null;
  }, [selectedTreeItem]);

  const metricsStats = useMemo(() => {
    if (!filteredSolutions || !selectedTreeItem) {
      return null;
    }

    // tree calc variables
    let fitnessMax = Number.MIN_SAFE_INTEGER;
    let fitnessMin = Number.MAX_SAFE_INTEGER;
    let sizeMax = Number.MIN_SAFE_INTEGER;
    let sizeMin = Number.MAX_SAFE_INTEGER;
    let fitnesTotal = 0;
    let sizeTotal = 0;
    let totalItemCount = 0;
    let treeCalculations = {
      averages: {},
      mins: {},
      maxes: {},
    };

    // model calc variables
    let modelFields = new Set(["fitness", "size"]);
    Object.keys(selectedTreeItem.modelOther || {}).forEach((key) =>
      modelFields.add(key)
    );
    let modelFieldValues = new Map();
    let modelIds = new Set();
    Array.from(modelFields).forEach((key) => {
      modelFieldValues.set(key, []);
    });
    let models = [];
    let modelCalculations = {
      averages: {},
      mins: {},
      maxes: {},
    };

    // iterate all tree items
    filteredSolutions.forEach((item) => {
      fitnesTotal += item.fitness;
      sizeTotal += item.size;
      totalItemCount += 1;
      if (item.fitness > fitnessMax) {
        fitnessMax = item.fitness;
      }
      if (item.fitness < fitnessMin) {
        fitnessMin = item.fitness;
      }
      if (item.size > sizeMax) {
        sizeMax = item.size;
      }
      if (item.size < sizeMin) {
        sizeMin = item.size;
      }

      // collect models data
      if (!modelIds.has(item.id)) {
        modelIds.add(item.id);
        let modelData = {
          fitness: item.modelFitness,
          size: item.modelSize,
        };
        Object.keys(selectedTreeItem.modelOther || {}).forEach((key) => {
          modelData[key] = item.modelOther[key];
        });
        models.push(modelData);
      }
    });

    const fitnessAverage = fitnesTotal / totalItemCount;
    const sizeAverage = sizeTotal / totalItemCount;

    treeCalculations.averages.fitness = fitnessAverage.toFixed(2);
    treeCalculations.averages.size = sizeAverage.toFixed(2);
    treeCalculations.maxes.fitness = fitnessMax.toFixed(2);
    treeCalculations.mins.fitness = fitnessMin.toFixed(2);
    treeCalculations.maxes.size = sizeMax.toFixed(2);
    treeCalculations.mins.size = sizeMin.toFixed(2);

    // fill model calculations map
    models.forEach((model) => {
      Object.keys(model).forEach((key) => {
        if (modelFieldValues.has(key)) {
          modelFieldValues.set(key, [...modelFieldValues.get(key), model[key]]);
        }
      });
    });

    // calc model fields
    Array.from(modelFieldValues).forEach(([key, val]) => {
      if (val.length > 0 && typeof val[0] === "number") {
        const metricAverage = val.reduce((acc, c) => acc + c, 0) / val.length;
        const metricMax = Math.max(...val);
        const metricMin = Math.min(...val);
        modelCalculations.averages[key] = metricAverage.toFixed(2);
        modelCalculations.maxes[key] = metricMax.toFixed(2);
        modelCalculations.mins[key] = metricMin.toFixed(2);
      }
    });

    return { tree: { ...treeCalculations }, model: { ...modelCalculations } };
  }, [filteredSolutions]);

  const incrementExpressionIndex = () => {
    if (!filteredSolutions || filteredSolutions.length === 0) return;

    const modelCount = Math.max(
      ...filteredSolutions.map((item) => item.individual)
    );
    if (modelIndex + 1 > modelCount) {
      setModelIndex(FIRST_MODEL_INDEX);
    } else {
      setModelIndex((curr) => curr + 1);
    }
  };

  const decrementExpressionIndex = () => {
    if (!filteredSolutions || filteredSolutions.length === 0) return;

    const modelCount = Math.max(
      ...filteredSolutions.map((item) => item.individual)
    );
    if (modelIndex === FIRST_MODEL_INDEX) {
      setModelIndex(modelCount);
    } else {
      setModelIndex((curr) => curr - 1);
    }
  };

  const handleChangeMosaicLayout = (newCurrentNode) => {
    setTilesLayout({
      ...tilesLayout,
      currentNode: newCurrentNode,
    });
  };

  const ELEMENT_MAP = {
    expression: selectedTreeItem && (
      <ExpressisonVisualizer
        treeItemsByModel={treeItemsByModel}
        individual={selectedTreeItem}
        parsingError={parsingError}
        setParsingError={setParsingError}
        setParsedExpTree={setParsedExpTree}
        onChangeSelectedTree={setSelectedTreeItem}
        incrementExpressionIndex={incrementExpressionIndex}
        decrementExpressionIndex={decrementExpressionIndex}
      />
    ),
    metrics: metrics && metricsStats && (
      <MetricsVisualizer metrics={metrics} metricsStats={metricsStats} />
    ),
    tree: parsedExpTree && !parsingError && (
      <TreeVisualizer
        parsedExpTree={parsedExpTree}
        selectedTreeItem={selectedTreeItem}
      />
    ),
    new: <h1>'New Window'</h1>,
  };

  return (
    <div
      className="project-tab"
      id="model-evaluation-section"
      ref={fullscreenRef}
    >
      <MosaicPanel
        tilesLayout={tilesLayout}
        onChangeLayout={handleChangeMosaicLayout}
        mosaicId="evaluation"
        elementMap={ELEMENT_MAP}
      />
    </div>
  );
}

export default Evaluation;
