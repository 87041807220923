import { useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  useTheme,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import ApiClient from "src/axios";
import { useParams } from "react-router";
import { GET_CONFIGS_QUERY_KEY } from "src/utils/types";
import { getErrorMsg } from "src/utils/Utils";
import useNotifier, { NotificationType } from "src/hooks/use-notify";

export default function CreateConfigDialog(props) {
  const theme = useTheme();
  const params = useParams();
  const { notify } = useNotifier();
  const queryClient = useQueryClient();

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [algorithm, setAlgorithm] = useState(null);
  const [type, setType] = useState(null);

  const commandTypes = useMemo(() => {
    return algorithm
      ? props.algorithms.find((item) => item.id === algorithm).commands
      : [];
  }, [algorithm]);

  const { mutate: createConfigMutation, isLoading } = useMutation({
    mutationFn: () =>
      ApiClient.post(`/api/projects/${params.id}/configurations`, {
        name,
        description: desc,
        algorithm,
        type,
      }),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries([GET_CONFIGS_QUERY_KEY]);
      notify(NotificationType.SUCCESS, "Config created");
      props.onClose();
    },
    onError: (err) => {
      notify(NotificationType.ERROR, getErrorMsg(err));
    },
  });

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h6" fontWeight={600}>
        Create Configuration
      </DialogTitle>
      <DialogContent>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label htmlFor="session-name" className="form-item-label">
                Name
              </label>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Configuration Name"
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="session-name" className="form-item-label">
                Description
              </label>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                placeholder="Configuration Description"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="session-name" className="form-item-label">
                Algorithm
              </label>
              <Select
                fullWidth
                value={algorithm}
                onChange={(e) => setAlgorithm(e.target.value)}
              >
                {(props.algorithms || []).map((item) => (
                  <MenuItem value={item.id}>{item.label}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="session-name" className="form-item-label">
                Type
              </label>
              <Select
                fullWidth
                value={type}
                onChange={(e) => setType(e.target.value)}
                disabled={!algorithm && commandTypes.length === 0}
              >
                {commandTypes.map((item) => (
                  <MenuItem value={item.id}>{item.label}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </>
      </DialogContent>
      <DialogActions sx={{ paddingX: "1.25rem", paddingY: ".75rem" }}>
        <Button
          color={"error"}
          disabled={isLoading}
          onClick={props.onClose}
          sx={{ marginRight: ".25rem" }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          disabled={!name || !algorithm || !type}
          startIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                sx={{ color: theme.palette.primary.contrastText }}
              />
            ) : undefined
          }
          onClick={isLoading ? undefined : () => createConfigMutation()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
