import { useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { SessionStatus, SessionTabs } from "src/utils/types";
import Comparison from "src/components/multitree/filter/Comparison";
import Training from "src/components/multitree/training/Training";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import Evaluation from "src/components/multitree/evaluation/Evaluation";

const MultiTreeSession = () => {
  const [selectedTab, setSelectedTab] = useState(SessionTabs.TRAINING);
  const {
    session,
    project,
    allSessions,
    sessionStatus,
    filteredSolutions,
    isTestSession,
    isTrainSession,
  } = useMultiTreeSession();

  return (
    <div id="model-page">
      <div id="session">
        {session && (
          <>
            <div className="title-tabs-container">
              {project && (
                <Breadcrumbs
                  ancestors={[
                    { name: "Projects", url: "/projects" },
                    {
                      name: project.name,
                      url: `/project/${project.id}`,
                    },
                    {
                      name: "Sessions",
                      url: `/project/${project.id}/sessions`,
                    },
                  ]}
                  current={session.name}
                />
              )}
              <ul className="tabs-container">
                <li
                  className={
                    selectedTab === SessionTabs.TRAINING ? "selected" : ""
                  }
                  onClick={() => setSelectedTab(SessionTabs.TRAINING)}
                >
                  {isTestSession ? "Test Results" : "Training"}
                </li>
                {isTrainSession && (
                  <li
                    className={
                      selectedTab === SessionTabs.FILTERING
                        ? "selected"
                        : "" +
                          " " +
                          (sessionStatus === SessionStatus.FINISHED ||
                          sessionStatus === SessionStatus.KILLED
                            ? "avaliable"
                            : "unavailable")
                    }
                    onClick={() => setSelectedTab(SessionTabs.FILTERING)}
                  >
                    Filter
                  </li>
                )}
                {filteredSolutions && (
                  <li
                    className={
                      selectedTab === SessionTabs.EVALUATION
                        ? "selected"
                        : "" +
                          " " +
                          (sessionStatus === SessionStatus.FINISHED ||
                          sessionStatus === SessionStatus.KILLED
                            ? "avaliable"
                            : "unavailable")
                    }
                    onClick={() => setSelectedTab(SessionTabs.EVALUATION)}
                  >
                    Evaluation
                  </li>
                )}
                {isTestSession && (
                  <li
                    className={
                      selectedTab === SessionTabs.DASHBOARD
                        ? "selected"
                        : "" +
                          " " +
                          (sessionStatus === SessionStatus.FINISHED ||
                          sessionStatus === SessionStatus.KILLED
                            ? "avaliable"
                            : "unavailable")
                    }
                    onClick={() => setSelectedTab(SessionTabs.DASHBOARD)}
                  >
                    Dashboard
                  </li>
                )}
              </ul>
            </div>
            {project && session && (
              <div className="model-page-content">
                {allSessions && (
                  <section
                    className={`mosaic-panel-section ${
                      selectedTab === SessionTabs.TRAINING
                        ? "active"
                        : "passive"
                    }`}
                  >
                    <Training />
                  </section>
                )}
                <section
                  className={`mosaic-panel-section ${
                    selectedTab === SessionTabs.FILTERING ? "active" : "passive"
                  }`}
                >
                  <Comparison />
                </section>
                {filteredSolutions && (
                  <section
                    className={`mosaic-panel-section ${
                      selectedTab === SessionTabs.EVALUATION
                        ? "active"
                        : "passive"
                    }`}
                  >
                    <Evaluation />
                  </section>
                )}
                {isTestSession && sessionStatus === SessionStatus.FINISHED && (
                  <section
                    className={`mosaic-panel-section ${
                      selectedTab === SessionTabs.DASHBOARD
                        ? "active"
                        : "passive"
                    }`}
                  >
                    <p>redash</p>
                  </section>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MultiTreeSession;
