import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Paper,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../common/TableItems";

const SelectedInstanceTable = ({ targetLabel, featureSet, instance }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
          marginBottom: ".3rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Selected Instance
        </Typography>
        {targetLabel && (
          <Typography variant="caption">
            (Target Label:{" "}
            <b>
              <i>"{targetLabel}"</i>
            </b>
            )
          </Typography>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              {featureSet.map((item) => (
                <StyledTableCell align="left">{item}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              {instance.map((item) => (
                <StyledTableCell align="left">{item}</StyledTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SelectedInstanceTable;
