import { Alert, Box, Checkbox, Skeleton, Stack } from "@mui/material";
import React, { Fragment } from "react";
import { TableVirtuoso } from "react-virtuoso";
import {
  StyledTableCell,
  StyledTableRow,
  VirtualizedTableComponents,
} from "src/components/common/TableItems";

const SelectDatasetInstance = ({
  loading,
  datasetData,
  error,
  selectedInstanceIndexes = [],
  existedExplanations,
  onSelectInstance,
}) => {
  const existedExplanatinIds = new Set(
    existedExplanations.map((item) => item.instanceIdx)
  );
  return (
    <Fragment>
      {loading ? (
        <>
          {new Array(5).fill().map(() => (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={30}
              style={{ marginBottom: ".075rem" }}
            />
          ))}
        </>
      ) : error ? (
        <Alert severity="error">Could not get dataset.</Alert>
      ) : datasetData ? (
        <Stack gap={"1rem"}>
          <Alert severity="info">
            If instance result already exist, it will be disabled.
          </Alert>
          <TableVirtuoso
            style={{ height: "400px" }}
            data={datasetData.data.rows}
            components={VirtualizedTableComponents}
            fixedHeaderContent={() => (
              <StyledTableRow>
                <StyledTableCell
                  padding="checkbox"
                  key={"empty-checkbox-area"}
                ></StyledTableCell>
                {datasetData.data.header.map((item, index) => (
                  <StyledTableCell align="left" key={index}>
                    {item}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            )}
            itemContent={(index, row) => (
              <>
                <StyledTableCell padding="checkbox" key={index + "checkbox"}>
                  <Checkbox
                    color="primary"
                    checked={
                      selectedInstanceIndexes.includes(index) ||
                      existedExplanatinIds.has(index)
                    }
                    disabled={existedExplanatinIds.has(index)}
                    onChange={() => onSelectInstance(index)}
                    sx={{ width: "24px", height: "24px" }}
                  />
                </StyledTableCell>
                {row.map((item, dataIndex) => (
                  <StyledTableCell align="left" key={dataIndex}>
                    {item}
                  </StyledTableCell>
                ))}
              </>
            )}
          />
        </Stack>
      ) : (
        <Alert severity="info">Could not find dataset.</Alert>
      )}
    </Fragment>
  );
};

export default SelectDatasetInstance;
