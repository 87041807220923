import { createRef, useCallback, useEffect, useState } from "react";
import "rc-slider/assets/index.css";
import { Info, Question, SlidersHorizontal } from "phosphor-react";
import TooltipSlider from "../../../utils/TooltipSlider";
import { useMultiTreeSession } from "src/hooks/use-multitree-session";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { MultiTreeFilterAndSortType } from "src/utils/types";

function ComparisonFilters({ setTableDataLoading }) {
  const { setFilteredSolutions, solutions, filters, setFilters } =
    useMultiTreeSession();
  const [modelSearch, setModelSearch] = useState();

  const [filterType, setFilterType] = useState(
    MultiTreeFilterAndSortType.INDIVIDUAL
  );

  useEffect(() => {
    let filtersArray = [
      "size",
      "fitness",
      ...Object.keys(solutions[0].other ?? {}).filter(
        (key) => typeof solutions[0].other[key] === "number"
      ),
    ];

    let filtersMap = new Map();

    filtersArray.forEach((value) =>
      filtersMap.set(value, {
        name: value,
        max: 0,
        min: Number.MAX_SAFE_INTEGER,
      })
    );

    filtersArray = filtersArray.filter(
      (value) => value !== "size" && value !== "fitness"
    );

    solutions.forEach((solution) => {
      filtersMap.set("fitness", {
        name: "fitness",
        min: parseFloat(
          Math.min(filtersMap.get("fitness").min, solution.fitness)
        ),
        max: parseFloat(
          Math.max(filtersMap.get("fitness").max, solution.fitness)
        ),
      });
      filtersMap.set("size", {
        name: "size",
        min: parseFloat(Math.min(filtersMap.get("size").min, solution.size)),
        max: parseFloat(Math.max(filtersMap.get("size").max, solution.size)),
      });

      filtersArray.forEach((filterName) => {
        filtersMap.set(filterName, {
          name: filterName,
          min: parseFloat(
            Math.min(
              filtersMap.get(filterName).min,
              solution.other
                ? solution.other[filterName]
                : Number.MAX_SAFE_INTEGER
            )
          ),
          max: parseFloat(
            Math.max(
              filtersMap.get(filterName).max,
              solution.other
                ? solution.other[filterName]
                : Number.MIN_SAFE_INTEGER
            )
          ),
        });
      });
    });

    filtersMap.forEach((filter, key) => {
      filtersMap.set(key, {
        ...filter,
        range: [filter.min, filter.max],
        step: (filter.max - filter.min) / 40.0,
      });
    });

    setFilters(filtersMap);
  }, [solutions]);

  const applyFilters = useCallback(() => {
    setTableDataLoading(true);
    const filteredSolutions = solutions
      .filter((item) => {
        if (!modelSearch) {
          return true;
        }
        return String(item.id) === String(modelSearch);
      })
      .filter((solution) => {
        for (let filter of filters.values()) {
          if (solution.hasOwnProperty(filter.name)) {
            if (
              solution[filter.name] < filter.range[0] ||
              solution[filter.name] > filter.range[1]
            ) {
              return false;
            }
          } else if (solution.other) {
            if (
              solution.other[filter.name] < filter.range[0] ||
              solution.other[filter.name] > filter.range[1]
            ) {
              return false;
            }
          }
        }
        return true;
      });

    setFilteredSolutions(filteredSolutions);
  }, [
    filters,
    setFilteredSolutions,
    setTableDataLoading,
    solutions,
    modelSearch,
  ]);

  useEffect(() => {
    if (filters) applyFilters();
  }, [solutions, filters, applyFilters]);

  return (
    <div>
      <h5>Filter Data</h5>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
          padding: ".25rem 0 1.5rem 0",
        }}
      >
        <Select
          fullWidth
          size="small"
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
        >
          {Object.entries(MultiTreeFilterAndSortType).map(([_, val]) => (
            <MenuItem value={val}>{val}</MenuItem>
          ))}
        </Select>
        <Tooltip title="By_tree option calculates tree average. Individual calculates each model.">
          <Question size={22} />
        </Tooltip>
      </Box> */}
      {filters &&
        Array.from(filters.values()).map((filter, index) => {
          return (
            <div
              className="slider-filter-container"
              style={{ paddingRight: "1rem" }}
              key={index}
            >
              <label>{filter.name}</label>
              <TooltipSlider
                className="slider-filter"
                range={filter.range}
                allowCross={false}
                min={filter.min}
                max={filter.max}
                step={filter.step}
                marks={{
                  [filter.min]: {
                    label: parseFloat(filter.min.toFixed(6)),
                  },
                  [filter.max]: {
                    label: parseFloat(filter.max.toFixed(6)),
                  },
                }}
                defaultValue={filter.range}
                onChange={(value) => {
                  const updatedFilter = { ...filter, range: value };
                  let updatedFilters = filters;
                  updatedFilters.set(filter.name, updatedFilter);
                  setFilters(updatedFilters);
                }}
              />
            </div>
          );
        })}
      <TextField
        fullWidth
        value={modelSearch}
        placeholder="Search by model id..."
        onChange={(e) => setModelSearch(e.target.value)}
        type="number"
        sx={{ margin: ".5rem 0 1.5rem 0" }}
      />
      <button
        id="apply-filters-button"
        className="icon-button"
        type="button"
        onClick={applyFilters}
      >
        <SlidersHorizontal size={18} color="#ffffff" weight="bold" />
        <span>Apply</span>
      </button>
    </div>
  );
}

export default ComparisonFilters;
