import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { WarningCircle, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { getBlobTypeErrMsg } from "src/utils/Utils";

const MainAnalyseResults = ({ loading, error, shapleyResults = [] }) => {
  const theme = useTheme();
  const [resultsDialogOpen, setResultsDialogOpen] = useState(false);
  const [errorMsg, setErrMsg] = useState("");

  useEffect(async () => {
    const message = await getBlobTypeErrMsg(error);
    setErrMsg(message);
  }, [error]);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        padding: ".25rem 1rem",
        borderRadius: ".5rem",
        border: (t) => `1px solid`,
        borderColor: "neutral.400",
        minHeight: "3.5rem",
      }}
    >
      {loading ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={"1rem"}
          width={"100%"}
        >
          <CircularProgress size={22} />
          <Typography>Getting explanation results...</Typography>
        </Stack>
      ) : error ? (
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          gap={".5rem"}
          width={"100%"}
        >
          <WarningCircle size={22} color={theme.palette.error.main} />
          <Typography sx={{ color: (t) => t.palette.error.main }}>
            {errorMsg || ""}
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={"1rem"}
          width={"100%"}
        >
          <Typography>Click details button to see feature plots.</Typography>
          <Button onClick={() => setResultsDialogOpen(true)}>Details</Button>
        </Stack>
      )}
      <ShapleyResultDialog
        open={resultsDialogOpen}
        results={shapleyResults}
        onClose={() => setResultsDialogOpen(false)}
      />
    </Stack>
  );
};

export default MainAnalyseResults;

const ShapleyResultDialog = ({ open, onClose, results = [] }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle variant="h6" fontWeight={600}>
        Feature Plots
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: ".5rem", right: "1rem" }}
      >
        <X />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2}>
          {results.length === 0 ? (
            <Grid item xs={12}>
              <Alert severity="info">No result found.</Alert>
            </Grid>
          ) : (
            results.map((image, index) => (
              <Grid item xs={12} md={6} key={image.url}>
                <h3 style={{ textAlign: "center", marginBottom: "0" }}>
                  {image.name}
                </h3>
                <img
                  src={image.url}
                  alt={image.name}
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    height: "auto",
                  }}
                />
              </Grid>
            ))
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ height: "34px" }}
          size="small"
          color="error"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
