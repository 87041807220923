import { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomFuncShowcase from "./CustomFuncShowcase";
import ConfigShowcase from "./ConfigShowcase";

const Tabs = {
  CONFIG: "1",
  FUNCTIONS: "2",
};

const AlgorithmDetails = ({ algorithm }) => {
  const [tab, setTab] = useState(Tabs.CONFIG);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
    >
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Configurations" value={Tabs.CONFIG} />
            <Tab label="Custom Functions" value={Tabs.FUNCTIONS} />
          </TabList>
        </Box>
        <TabPanel value={Tabs.CONFIG} sx={{ padding: "1rem 0" }}>
          <ConfigShowcase algorithm={algorithm} />
        </TabPanel>
        <TabPanel value={Tabs.FUNCTIONS} sx={{ padding: "1rem 0" }}>
          <CustomFuncShowcase algorithm={algorithm} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default AlgorithmDetails;
